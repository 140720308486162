/**
 * @file 预设项目中常用的业务表单控件
 */
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useFormContext, Controller } from 'react-hook-form';
import FormItem from '@w/Form/FormItem';
import { Row, Col } from '@common/components/Grids';
import { mapToItem, presetTheme } from './preset';
import useRootStore from '@/models';
import { mergeFn } from '@common/utils';
import { get } from 'lodash';

export function Error({ children }) {
  return <div className="c-form-item__error pt10 pb10 fs12 color-theme">{children}</div>;
}

const fromApiSpy = {
  formState: { errors: {} },
};

const events = ['onBlur', 'onFocus'];

const PresetForm = observer(function PresetForm({ noForm, theme, list, className, ...commonInputProps }) {
  const formApi = useFormContext();
  const { control, formState } = noForm ? fromApiSpy : formApi;
  const { errors } = formState;
  const matchMedia = useRootStore('ui.matchMedia');

  return (
    <Row className={classNames(className)} space={matchMedia.md ? '20px' : '10px'}>
      {mapToItem(list || (presetTheme[theme] && presetTheme[theme].field) || [])
        .filter(Boolean)
        .map((item) => {
          const { col, name, ignoreName, children, noController, ...inputProps } = item;
          const error = get(errors, item.name) ? <Error slot="top">{get(errors, item.name).message}</Error> : null;
          const baseProps = {
            ...inputProps,
            ...commonInputProps,
          };
          const isTopLabelStyle = !baseProps.labelStyle || baseProps.labelStyle === 'top';

          if (!name && !ignoreName) {
            const error = 'PresetForm的list子元素缺少name属性';
            console.error(error, item);
            throw new Error(error);
          }

          return (
            <Col className="form-row" key={name || item.key} {...col}>
              {noController ? (
                <FormItem {...baseProps} error={error}>
                  {children}
                  {isTopLabelStyle ? error : null}
                </FormItem>
              ) : (
                <Controller
                  name={name}
                  control={control}
                  render={({ field: { ref, ...props } }) => (
                    <FormItem
                      {...baseProps}
                      {...props}
                      {...events.reduce((acc, eventKey) => {
                        acc[eventKey] = mergeFn(baseProps[eventKey], props[eventKey]);
                        return acc;
                      }, {})}
                      error={error}
                      inputRef={ref}
                    >
                      {children}
                      {isTopLabelStyle ? error : null}
                    </FormItem>
                  )}
                />
              )}
              {!isTopLabelStyle ? error : null}
            </Col>
          );
        })}
    </Row>
  );
});

export default PresetForm;
