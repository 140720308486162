import { useEffect, useRef } from 'react';

/**
 * 返回一个ref，标识当前组件是否已经销毁。通常用于组件销毁后，阻止异步操作结束时进行setState。
 * @returns React.refObject
 */
export default function useDestroyRef() {
  const destroyRef = useRef(false);

  useEffect(() => {
    return () => {
      destroyRef.current = true;
    };
  }, []);

  return destroyRef;
}
