import mitt from 'mitt';

export default class Emiiter {
  constructor() {
    this.emitter = mitt();
  }

  on() {
    this.emitter.on.apply(this.emitter, arguments);
  }

  off() {
    this.emitter.off.apply(this.emitter, arguments);
  }

  emit() {
    this.emitter.emit.apply(this.emitter, arguments);
  }
}