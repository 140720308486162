import { useCallback, useState } from 'react';
import useDestroyRef from './useDestroyRef';

const initState = {
  loading: false,
  data: null,
  inited: false,
  error: null,
};

function assign(initState, data) {
  return {
    ...initState,
    data,
  };
}

export default function useStateDescribe(data, immutable) {
  const [describe, setDescribe] = useState(immutable ? () => assign(initState, data) : assign(initState, data));
  const destroyRef = useDestroyRef();

  const setData = useCallback(
    (data) => {
      if (destroyRef.current) return;
      setDescribe((state) => ({ ...state, data, loading: false, error: null, inited: true }));
    },
    [destroyRef]
  );

  const setLoading = useCallback(
    (loading) => {
      if (destroyRef.current) return;
      setDescribe((state) => ({ ...state, loading: loading || false }));
    },
    [destroyRef]
  );

  const setError = useCallback(
    (error) => {
      if (destroyRef.current) return;
      setDescribe((state) => ({ ...state, loading: false, error, inited: true }));
    },
    [destroyRef]
  );

  const reset = useCallback(() => {
    if (destroyRef.current) return;
    setDescribe({
      ...initState,
    });
  }, [destroyRef]);

  return [describe, setData, setLoading, setError, reset, setDescribe];
}
