import { observer } from 'mobx-react-lite';
import Button from '@/components/Button';
import useRootStore from '@/models';
import { useHistory } from 'react-router';

const LogoutButton = observer(function LogoutButton({ children = 'Log out', onSuccess, ...props }) {
  const { auth } = useRootStore();
  const history = useHistory();

  return (
    <Button
      border
      color="gray"
      size="medium"
      {...props}
      loading={auth.isHttpLoading}
      onClick={() =>
        auth.logout().then(() => {
          onSuccess && onSuccess();
          history.replace('/');
        })
      }
    >
      {children}
    </Button>
  );
});

export default LogoutButton;
