import { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import useRootStore from '@/models';
import useViewport from '@common/hooks/useViewport';
import Header from '@w/Header';
import Footer from '@w/Footer';
import Spinner from '@common/components/Spinner';
import { DynamicComponentArea } from '@common/controller/UIController';
import { IntercomProvider } from 'react-use-intercom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { config } from './services/config';
import { PinBoxProvider } from '@common/components/PinBox';
import GlobalAnnouncement from './widgets/Announcement/global';

const INTERCOM_APP_ID = 'nbjvgz3i';

const Demo = lazy(() => import('@/pages/Demo'));
const Home = lazy(() => import('@/pages/Home'));
const ProductDetail = lazy(() => import('@/pages/ProductDetails'));
const Products = lazy(() => import('@/pages/Products'));
const ProductPackage = lazy(() => import('@/pages/ProductPackage'));
const Inspection = lazy(() => import('@/pages/Inspection'));
const Financing = lazy(() => import('@/pages/Financing'));
const FinanceApplication = lazy(() => import('@/pages/FinanceApplication'));
const Trade = lazy(() => import('@/pages/Trade'));
const TradeCopy = lazy(() => import('@/pages/Trade/Copy'));
const Terms = lazy(() => import('@/pages/Terms'));
const Profile = lazy(() => import('@/pages/Profile'));
const Collection = lazy(() => import('@/pages/Collection'));
const AboutUs = lazy(() => import('@/pages/AboutUs'));
const Unsubscribe = lazy(() => import('@/pages/Unsubscribe'));
const TestDrive = lazy(() => import('@/pages/TestDrive'));
const TestDriveForm = lazy(() => import('@/pages/TestDriveForm'));
const ContactUs = lazy(() => import('@/pages/ContactUs'));
const Protection = lazy(() => import('@/pages/Protection/Protection'));
const Guarentee = lazy(() => import('@/pages/Protection/Guarentee'));
const Faq = lazy(() => import('@/pages/Protection/Faq'));
const ThankYou = lazy(() => import('@/pages/ThankYou'));
const SellYourEv = lazy(() => import('@/pages/SellYourEvForm'));
const SellYourEvFormSuccess = lazy(() => import('@/pages/SellYourEvFormSuccess'));
const Loading = <Spinner global />;

const AppInner = observer(function AppInner() {
  return (
    <Router>
      <div className="app fs16">
        {<GlobalAnnouncement />}
        {<Header />}

        {/* <div
          className="point-through pos-a full-size"
          style={{ position: 'fixed', background: 'rgba(0,0,0,.7)', zIndex: 999999 }}
        ></div> */}
        <div className="app-body">
          <Suspense fallback={Loading}>
            <Switch>
              <Route path="/demo" exact component={Demo} />
              <Route path="/" exact component={Home} />
              <Route path="/product-details/:id" exact component={ProductDetail} />
              <Route path="/products" exact component={Products} />
              <Route path="/products/:make" exact component={Products} />
              <Route path="/inspection/:id?" exact component={Inspection} />
              <Route path="/product-package/:id?" exact component={ProductPackage} />
              <Route path="/financing/:id?" exact component={Financing} />
              <Route path="/financeApplication/:id" exact component={FinanceApplication} />
              <Route path="/trade" exact component={Trade} />
              <Route path="/trade-in" exact component={TradeCopy} />
              <Route path="/terms" exact component={Terms} />
              <Route path="/profile" component={Profile} />
              <Route path="/collection" exact component={Collection} />
              <Route path="/testdrive" exact component={TestDrive} />
              <Route path="/sellyourev" exact component={SellYourEv} />
              <Route path="/sellyourev/:id" exact component={SellYourEvFormSuccess} />
              <Route path="/testdrive/form/:id" exact component={TestDriveForm} />
              <Route path="/ContactUs" exact component={ContactUs} />
              <Route path="/protection-plan" exact component={Protection} />
              <Route path="/guarantee" exact component={Guarentee} />
              <Route path="/faq" exact component={Faq} />
              <Route path="/thankyou" exact component={ThankYou} />
              <Route path="/aboutus" exact component={AboutUs} />
              <Route path="/unsubscribe" exact component={Unsubscribe} />
              <Route path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
          </Suspense>
        </div>
        <Footer />

        <DynamicComponentArea />
      </div>
    </Router>
  );
});

const App = observer(function App() {
  const viewportInfo = useViewport();
  const ui = useRootStore('ui');

  const [, setDetails] = useState('');
  const [updateUserInfo, setUpdateUserInfo] = useState(false);

  const getUserGeolocationDetails = () => {
    fetch('https://geolocation-db.com/json/0f761a30-fe14-11e9-b59f-e53803842572')
      .then((response) => response.json())
      .then((data) => {
        setDetails(data);
      })
      .catch();
  };

  useEffect(() => {
    ui.updateFromViewport(viewportInfo);

    if (updateUserInfo === false) {
      getUserGeolocationDetails();
      setUpdateUserInfo(true);
    }
  }, [ui, updateUserInfo, viewportInfo]);

  return ui.width !== 0 ? (
    <GoogleOAuthProvider clientId={config.clientId}>
      <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
        <PinBoxProvider>
          <AppInner />
        </PinBoxProvider>
      </IntercomProvider>
    </GoogleOAuthProvider>
  ) : null;
});

export default App;
