import classNames from 'classnames';
import PropTypes from 'prop-types';
import { cloneElement, useState, useEffect, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import Icon from '@/widgets/Icon';
import { safeRender, toVueSlots } from '../../utils/slots';
import useWatch from '../../hooks/useWatch';
import './style.less';

function createElement(style) {
  const div = document.createElement('div');
  Object.assign(div.style, style);
  document.body.appendChild(div);
  return div;
}

const options = {
  strategy: 'fixed',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: ({ popper }) => {
          return [0, -popper.height / 2];
        },
      },
    },
  ],
};

function Toast({ children, elmRef, toastRef, noDefaultClassName, show, hide, theme, closeBtn }) {
  const [popperElement, setPopperElement] = useState(null);
  const [virtualReference, setVirtualReference] = useState(null);
  const [elm, setElm] = useState(null);
  const { styles, attributes, update } = usePopper(virtualReference, popperElement, options);
  const $slots = toVueSlots(safeRender(children, { show, hide }));

  useEffect(() => {
    if (!virtualReference) setVirtualReference(createElement({ position: 'fixed', top: '50%', left: '50%' }));
    return () => {
      virtualReference && virtualReference.parentNode === document.body && document.body.removeChild(virtualReference);
    };
  }, [virtualReference]);

  useWatch(
    [update, children],
    function ([update]) {
      update && update();
    },
    { useLayout: true }
  );

  useLayoutEffect(() => {
    elm && elm.classList.add('c-toast--show');
  }, [elm]);

  return $slots.default
    ? createPortal(
        <div
          ref={(el) => {
            setPopperElement(el);
            elmRef && elmRef(el);
          }}
          className="c-toast-wrap"
          style={styles.popper}
          {...attributes.popper}
        >
          {!!closeBtn && <Icon className="toast__close" name="close" onClick={hide}></Icon>}

          {cloneElement($slots.default, {
            ref: (el) => {
              setElm(el);
              toastRef && toastRef(el);
            },
            className: classNames(
              !noDefaultClassName ? `c-toast ${theme ? 'c-toast--' + theme : ''}` : '',
              $slots.default.props.className
            ),
          })}
        </div>,
        document.body
      )
    : null;
}

Toast.propTypes = {
  closeBtn: PropTypes.bool, // 展示右上角关闭按钮
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  elmref: PropTypes.object, // react.refobject
  toastref: PropTypes.object, // react.refobject
  noDefaultClassName: PropTypes.bool, // 声明根元素不添加默认className
  show: PropTypes.func, // 透传prop。本组件为纯展示性质，不具备show/hide交互，这些交互应该交由父组件实现并按需透传到children。
  hide: PropTypes.func, // 透传，同上。
  theme: PropTypes.string, // 主题。目前只有fade和default。可以翻阅style.less了解。
};

export default Toast;
