import { types } from 'mobx-state-tree';
import { generateDescribeModule } from '@common/utils/describe-helper';
import { createAjaxDescribe } from '@common/utils/createAjaxDescribe';
import Announcement from './Announcement';
import AnnouncementListBase from '@common/models/Announcement/AnnouncementList';
import announcementServer from '@/services/announcement';

const AnnouncementListServer = generateDescribeModule('ajaxDescribe', [
  createAjaxDescribe,
  announcementServer.fetchAnnouncementList,
  function (data) {
    this.updateFromAjaxJson(data);
  },
]);

const AnnouncementList = types.compose(
  AnnouncementListServer,
  AnnouncementListBase,
  types.model({
    list: types.optional(types.array(Announcement), []),
  })
);

export default AnnouncementList;
