import PropTypes from 'prop-types';
import { useState, useRef, useCallback, useMemo } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { rootStore } from '@/models';
import { getErrorMessage } from '@common/libs/network';
import toast from '@/controller/ToastController';
import useDestroyRef from '@common/hooks/useDestroyRef';
import useExportApiRef from '@common/hooks/useExportApiRef';
import Button from '@/components/Button';
import Checkbox from '@/components/Checkbox';
import PresetForm, { Error } from '@/widgets/PresetForm';
import { presetTheme } from '@/widgets/PresetForm/preset';
import { defineFormValidate } from '@common/utils/helper/superstruct-helper';
import { Terms } from '@common/utils/verification';
import './RegisterPanel.less';
import useWatch from '@common/hooks/useWatch';

const RegisterPanel = observer(function RegisterPanel({ onSuccess, onFail, onInputChange, theme, apiRef }) {
  const [actionType, setActionType] = useState('');
  const destroyRef = useDestroyRef();
  const submitBtnRef = useRef();
  const submitPrRef = useRef();

  // react-hook-form表单验证配置
  const passwordRef = useRef('');
  const formOptions = useMemo(() => {
    const formTheme = presetTheme[theme];

    return {
      mode: 'onChange',
      defaultValues: formTheme.field.reduce(
        (acc, key) => {
          acc[typeof key === 'string' ? key : key.name] = '';
          return acc;
        },
        {
          termsRead: false,
        }
      ),
      resolver: formTheme.resolver({
        termsRead: Terms,
        confirmPassword: defineFormValidate(
          'confirm password',
          (value) => passwordRef.current === value,
          'Please input the same password'
        ),
      }),
    };
  }, [theme]);
  const formApi = useForm(formOptions);
  const { control, handleSubmit, watch, formState, getValues, reset } = formApi;
  const { errors } = formState;
  const submitHandler = handleSubmit((data) => {
    //console.log(data);
    rootStore.auth.registerThenLogin({ ...data, accesstoken: '' }).then(
      function () {
        if (!destroyRef.current) {
          setActionType('');
          onSuccess && onSuccess(getValues());
        }
        reset();
        submitPrRef.current && submitPrRef.current.resolve(getValues());
      },
      (err) => {
        if (!destroyRef.current) {
          setActionType('');
          onFail && onFail(err);
        }
        submitPrRef.current && submitPrRef.current.reject(err);
      }
    );
  });

  passwordRef.current = watch('password', '');

  // 通过ref向外暴露api
  useExportApiRef(
    apiRef,
    [
      useCallback(
        () =>
          new Promise(function (resolve, reject) {
            submitPrRef.current = { resolve, reject };
            submitBtnRef.current.click();
          }),

        [submitBtnRef]
      ),
    ],
    (submit) => ({
      submit,
    })
  );

  // onInputChange回调表单的当前输入是否完整，以供外部依赖这个状态展示某些UI
  const inputMissing = !formState.isDirty || (formState.isDirty && !formState.isValid);
  useWatch(
    [!inputMissing],
    function ([complete]) {
      onInputChange && onInputChange(complete);
    },
    { immediate: true }
  );

  return (
    <FormProvider {...formApi}>
      <form className="form register-panel flex-item flex flex--col flex--edge" onSubmit={submitHandler}>
        <PresetForm theme={theme} />

        <Controller
          name="termsRead"
          control={control}
          render={({ field: { ref, ...props } }) => (
            <Checkbox className="mt15" {...props}>
              <span>
                I have read and accepted the{' '}
                <a className="color-theme" href="/terms" target="_blank">
                  Terms of Service
                </a>{' '}
                and{' '}
                <a className="color-theme" href="/terms?privacy" target="_blank">
                  Privacy
                </a>
                .
              </span>
            </Checkbox>
          )}
        />
        {errors['termsRead'] ? <Error>{errors['termsRead'].message}</Error> : null}

        <input className="display-none" type="submit" ref={submitBtnRef} />

        {theme === 'register' && (
          <div className="pt20">
            <Button
              className={classNames('submit-btn full-width mt15', {
                'c-button--disabled': inputMissing,
              })}
              type="submit"
              loading={actionType === 'local' && rootStore.auth.isHttpLoading}
              disabled={rootStore.auth.isHttpLoading}
              onClick={() => setActionType('local')}
            >
              Sign Up
            </Button>
          </div>
        )}
      </form>
    </FormProvider>
  );
});

RegisterPanel.propTypes = {
  theme: PropTypes.oneOf(['register', 'paymentRegister']),
};

RegisterPanel.defaultProps = {
  theme: 'register',
};

export function toastRegisterError(err) {
  toast.show(getErrorMessage(err));
}

export default RegisterPanel;
