/**
 * behavior定义popper的交互行为，有效值： 'hover', 'click'。
 * content可以作为prop传，也可以作为children slot。
 * children支持function，这样可以访问api自行定义交互。
 *
 * @example
 * <Popper>
 *   {({ setShow, show }) => <>
 *     <div onClick={() => setShow(!show)}>88888888</div>
 *     <div slot="content">哈啊哈哈时间哈开始打的</div>
 *   </>}
 * </Popper>
 *
 * <Popper behavior="hover" content="哈啊哈哈时间哈开始打的">
 *   <div>88888888</div>
 * </Popper>
 */
import * as classNames from 'classnames';
import PropTypes from 'prop-types';
import { cloneElement, useEffect, useState, useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { setRef } from '../../utils';
import useWatch from '../../hooks/useWatch';
import { safeRender, toVueSlots } from '../../utils/slots';
import closest from '../../utils/dom/closest';
import './style.less';

let uuid = 0;
const instanceMap = {};

function flushGlobalInstance(id, hideFn, outSideWhiteList) {
  if (typeof hideFn === 'undefined') {
    delete instanceMap[id];
  } else {
    instanceMap[id] = [hideFn, outSideWhiteList];
  }
}

document.documentElement.addEventListener('click', function ({ target }) {
  if (!closest(target, '[data-c-popper=true]') && !closest(target, '.c-popper-content')) {
    Object.keys(instanceMap).forEach((key) => {
      const active = instanceMap[key];
      if (active) {
        let [hideFn, outSideWhiteList] = active;
        outSideWhiteList = outSideWhiteList || [];

        for (let i = 0; i < outSideWhiteList.length; i++) {
          if (closest(target, outSideWhiteList[i])) return;
        }

        hideFn();
      }
    });
  }
});

function useApi(setShow, show, update) {
  const timerRef = useRef(0);

  const open = useCallback(() => {
    clearTimeout(timerRef.current);
    setShow(true);
    update && update();
  }, [setShow, update]);

  const close = useCallback(() => {
    setShow(false);
  }, [setShow]);

  return {
    open,
    close,
    toggle: show ? close : open,
    asyncToggle: useCallback(() => {
      setShow((show) => !show);
    }, [setShow]),
    delayClose: useCallback(
      (timeout) => {
        timerRef.current = setTimeout(close, timeout);
      },
      [close]
    ),
  };
}

export default function Popper({
  updatePulse,
  contentClassName,
  value,
  disabled,
  onChange,
  children,
  behavior,
  content,
  options,
  extraOptions,
  delayClose,
  outSideWhiteList,
  keepOpenAtContentHover,
  offset,
  popperStyle,
  ...passthrough
}) {
  const [id] = useState(() => uuid++);
  const [show, setShow] = useState(!!value);

  // 注册全局callback，用于页面点击时关闭popper。
  useWatch(
    [setShow, id, outSideWhiteList],
    function ([setShow, id, outSideWhiteList]) {
      flushGlobalInstance(
        id,
        function () {
          setShow(false);
        },
        outSideWhiteList
      );
    },
    { immediate: true }
  );

  // 组件销毁时，解绑callback
  useEffect(() => {
    return () => {
      flushGlobalInstance(id);
    };
  }, [id]);

  // 实现受控、非受控功能。
  useWatch([value, show], function ([value, show], [oldValue, oldShow]) {
    if (value !== oldValue) {
      if (value !== show) setShow(value);
    } else if (show !== oldShow) {
      onChange && onChange(show);
    }
  });

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const modifiers = [{ name: 'arrow', options: { element: arrowElement } }];
  if (offset) {
    modifiers.push({
      name: 'offset',
      options: {
        offset,
      },
    });
  }

  const { styles, attributes, update, destroy } = usePopper(
    referenceElement,
    popperElement,
    options || {
      modifiers,
      ...extraOptions,
    }
  );

  const api = useApi(setShow, show, update);
  const presetEffect = {
    delayHover: {
      onMouseEnter: api.open,
      onMouseLeave: api.delayClose.bind(api, delayClose),
    },
    hover: {
      onMouseEnter: api.open,
      onMouseLeave: api.close,
    },
    click: {
      onClick: api.toggle,
    },
    focus: {
      onFocus: api.open,
    },
  };

  // 手动控制updatePulse，来控制update position。
  // 这在界面变化导致popper停留在原来的位置是很有用。
  useEffect(() => {
    update && update();

    return () => {
      destroy && destroy();
    };
  }, [updatePulse, update, destroy]);

  const slotScope = {
    setShow,
    show,
    update,
    ...api,
    ...presetEffect,
    $attrs: passthrough,
  };
  const $slots = toVueSlots(safeRender(children, slotScope));
  const preset = presetEffect[behavior] || {};
  const contentPreset =
    behavior === 'hover' || behavior === 'delayHover'
      ? preset
      : keepOpenAtContentHover
      ? {
          onMouseEnter: presetEffect['hover'].onMouseEnter,
        }
      : {};

  return (
    <>
      {cloneElement($slots.default, {
        className: classNames($slots.default.props.className, show && !disabled ? 'c-popper c-popper--open' : ''),
        'data-c-popper': 'true',
        ref(el) {
          setReferenceElement(el);
          setRef($slots.default.ref, el);
        },
        ...preset,
      })}
      {show &&
        !disabled &&
        ReactDOM.createPortal(
          <div
            ref={setPopperElement}
            className={'c-popper-content ' + (contentClassName || '')}
            style={{ ...styles.popper, ...popperStyle }}
            {...attributes.popper}
            {...contentPreset}
          >
            {content || $slots.content}
            <div
              ref={setArrowElement}
              className="c-popper-content__arrow"
              style={{ position: 'absolute', ...styles.arrow }}
            />
          </div>,
          document.body
        )}
      {$slots.otherPortal && ReactDOM.createPortal($slots.otherPortal, document.body)}
    </>
  );
}

Popper.propTypes = {
  updatePulse: PropTypes.any, // 此值变化时，进行一次popper.update()
  contentClassName: PropTypes.string,
  outSideWhiteList: PropTypes.arrayOf(PropTypes.string), // 默认点击popper外部会关闭，这里提供白名单，点击了哪些外部的选择器时，保留popper打开状态
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  behavior: PropTypes.string,
  content: PropTypes.node,
  options: PropTypes.object,
  offset: PropTypes.arrayOf(PropTypes.number),
  delayClose: PropTypes.number, // 延时关闭的时间
  keepOpenAtContentHover: PropTypes.bool,
};

Popper.defaultProps = {
  delayClose: 50,
};
