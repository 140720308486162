import * as classNames from 'classnames';
import * as URLParse from 'url-parse';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import useRootStore from '@/models';
import Icon from '../Icon';
import './style.less';

const re_url = /^(https?:)?\/\//;

const Announcement = observer(function Announcement({ className, announcement, onClose }) {
  const { link } = announcement;
  let Comp = 'div';
  let attrs;
  const store = useRootStore();
  const { ui } = store;

  if (link) {
    const url = URLParse(link);
    const isSameOrigin = url.origin === window.location.origin;
    if (isSameOrigin && !re_url.test(link)) {
      Comp = Link;
      attrs = { to: link };
    } else {
      Comp = 'a';
      attrs = { href: link };
    }
  }

  return (
    <Comp className={classNames('site-announcement', className)} {...attrs} target="_blank">
      <div
        className={classNames('page-row full-height overflow', ui.matchMedia.md ? 'flex-grid' : 'flex flex--middle')}
      >
        <Icon name="message" />
        <div className="text-overflow ff-roman">{announcement.content}</div>
        {announcement.linkText ? (
          <div className="site-announcement__link ff-roman text-underline no-linefeed">{announcement.linkText}</div>
        ) : null}
        {announcement.closeable ? <Icon name="close" onClick={onClose} /> : null}
      </div>
    </Comp>
  );
});

export default Announcement;
