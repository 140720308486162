/**
 * 基于axios的网络层封装。方便import使用，将其定位为项目的唯一网络层，所以实现为单例。
 * @example
 * // 全局基础参数
 * network.setBaseParams('channelId', 99);
 * network.setBaseParams('entry', () => 'xxxxxxxxxxx', true);
 *
 * // url别名
 * network.addAlias('/api', (req) => (req.isExternal ? '/wwyy/external' : '/wwyy/server'));
 * network.addAlias('/useApi', '/wwyy/user');
 *
 * // 自定义拦截器
 * network.interceptors.request.push(
 *   function (_req, next) {
 *     setTimeout(next, 500);
 *   },
 *   function (req, next) {
 *     network('/', null, { connectRequest: req }).then(next);
 *   }
 * );
 *
 * // 捕捉全局错误，在这里进行重定向、重请求等。
 * network.catchError = function (res, { url, params, config }) {
 *   // 访问到一个404的接口时，尝试请求另一个（重定向）。
 *   // 因为每个network调用出现业务错误都会进入这里。
 *   // 所以这里也判断一个_dontTry_的自定义标识，来中止重试，防止进入无限循环。
 *   if (res.response.status === 404 && !config._dontTry_) {
 *     return new Promise(function (resolve, reject) {
 *       setTimeout(() => {
 *         network('', null, { _dontTry_: true }) // 假装需要请求某个接口更新本地的全局参数，加上_dontTry_标识，标识这个接口都404就不用玩了。
 *           .then(() => network.setBaseParams('channelId', 2)) // 更新全局参数。这样后面的请求的参数都是最新的。
 *           .then(() => network(url + '1', params, { ...config, retry: 0, _dontTry_: true })) // 假装这是另一个接口
 *           .then(resolve, reject);
 *       }, 3000);
 *     });
 *   }
 *
 *   return Promise.reject(res);
 * };
 *
 * // 使用
 * network('/useApi/info', { id: 123, name: 'cyc' }, { method: 'post', retry: 1, debug: true, paramsFormat: 'json'});
 */
import { get } from 'lodash';
import { runInterceptor } from '../../utils/interceptor';
import axios from 'axios';

function loadQs() {
  return import('qs');
}

const globalParams = {};
const aliasList = [];
const axiosInstance = axios.create();
axiosInstance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const httpSheet = {};

function noop() {}

function each(arr, fn, eachAll, startRight) {
  var i;

  // 不严谨的判断是否数组
  if (typeof arr.length == 'number') {
    if (startRight) {
      for (i = arr.length - 1; i >= 0; i--) {
        if (fn(arr[i], i, arr) === false) {
          break;
        }
      }
    } else {
      for (i = 0; i < arr.length; i++) {
        if (fn(arr[i], i, arr) === false) {
          break;
        }
      }
    }
  } else if (arr && typeof arr === 'object') {
    for (i in arr) {
      if (eachAll || Object.prototype.hasOwnProperty.call(arr, i)) {
        if (fn(arr[i], i, arr) === false) {
          break;
        }
      }
    }
  }
}

function omit(obj, list) {
  var result = Object.assign({}, obj);
  each(list, function (key) {
    delete result[key];
  });

  return result;
}

function tryFn(val, ...args) {
  return typeof val === 'function' ? tryFn(val.apply(null, args)) : val;
}

function isArray(arr) {
  return Array.isArray(arr);
}

function cloneFormData(formData) {
  const data = new FormData();

  for (const [key, val] of formData) {
    data.append(key, val);
  }

  return data;
}

function isNativeDataObject(data) {
  return (
    (typeof FormData !== 'undefined' && data instanceof FormData) ||
    (typeof File !== 'undefined' && data instanceof File) ||
    (typeof Blob !== 'undefined' && data instanceof Blob)
  );
}

export function getGlobalParamsSnapshot(isPost, req, params) {
  const isArr = isArray(req.data) || isArray(req.params) || isArray(params);
  const res = {
    params: {},
    data: isArr ? (isArray(req.data) ? req.data : isArray(req.params) ? req.params : params) : {},
  };

  for (var key in globalParams) {
    const value = tryFn(globalParams[key].val, req);

    if (typeof value !== 'undefined' && value !== null) {
      if (isPost && !isArr && globalParams[key].dataFirst) {
        res.data[key] = value;
      } else {
        res.params[key] = value;
      }
    }
  }

  return res;
}

function transformUrl(url, req) {
  if (/^\w+:\/\//.test(url)) return url;

  let prefixFirstMatch = false; // 只匹配一次

  return aliasList.reduce(function (url, sheet) {
    if (prefixFirstMatch) return url;

    const reg = new RegExp(`^${sheet[0]}`);
    prefixFirstMatch = prefixFirstMatch || !!url.match(reg);
    return url.replace(reg, tryFn(sheet[1], req));
  }, url);
}

function getErrorMessage(res) {
  if (typeof res === 'string') return res;
  return res.message || get(res, 'data.message') || get(res, 'data.msg') || get(res, 'data') || res || '未知错误';
}

function wrapRequestInterceptor(list) {
  return list.map((fn) => {
    return function (req, next) {
      const args = arguments;
      const _next = next;
      args[1] = next = () => _next(req); // 复写next，使其总是传递axiosParams

      req.__interceptor_indicator__ = fn;
      fn.apply(null, args);

      // 如果拦截器签名没有next，则手动next。
      if (fn.length < 2) {
        next();
      }
    };
  });
}

function wrapResponseInterceptor(list) {
  return list.map((fn) => {
    return function (res, isError, reject, next, exit) {
      fn.apply(null, [
        res,
        isError,
        next,
        () => {
          reject(res);
          exit();
          next();
        },
      ]);

      // 如果拦截器签名没有next，则手动next。
      if (fn.length < 2) {
        next(res);
      }
    };
  });
}

/**
 * 网络层API
 * @param {String}       url    请求链接
 * @param {Object|Array} params 请求参数
 * @param {Object}       config 配置，其会跟axios的调用参数合并。
 * @param {String}       config.method  请求方法
 * @param {Object}       config.params  请求的url参数。通常是method为get以外是用到。
 * @param {Number}       config.retry   发生非业务正确时的重试次数。
 * @param {String}       config.paramsFormat    有效值''和'json'。为'josn'时，content-type为"application/json"。默认为"application/x-www-form-urlencoded"。
 * @param {String}       config.httpId         给请求标记一个id，当该请求未完成，而后面又有同样id的请求发起时，前者不响应。@todo后面有时间改为abort
 *                                             这在要处理竞态条件的场景很有用，因这是一个很常见的场景，所以内置在内部实现。
 * @param {Function}     config.checkValidate  区分请求是否属于业务正确。签名 (res, isError) => boolean。
 *                                             res: axios返回的原始数据。
 *                                             isError: res是否取自axios的catch。
 * @param {Function}     config.pickData       业务正确时，用于提取数据的公用方法。它接收的值是axios返回值经过拦截器后的值。
 * @param {Function}     config.onBusinessError   请求属于业务错误时的回调，请求本身是成功的。签名 (errMsg, res) => {}
 * @param {Function}     config.onError           请求发生错误（如网络错误）时的回调，与onBusinessError互斥，因为请求本身是失败的。签名 (error, config) => {}
 * @param {Function}     config.getErrorMessage   包装错误消息，其会传给onBusinessError。返回falsely值时取network 自行解析。签名 (res) => string。
 * @param {Object}       config.connectRequest    当在业务拦截器使用了network()进行请求时，这个请求默认也会重新执行一遍业务拦截器。如果不自行进行处理，会导致一直无限循环。
 *                                      这可以简单地传递拦截器的第一个参数给connectRequest，表示这个network从当前拦截器开始执行拦截器。结合代码表达，如：
 *                                        network.interceptors.request.push(
 *                                         function (req, next) {
 *                                           setTimeout(() => {
 *                                             console.log(req);
 *                                             next();
 *                                           }, 2000);
 *                                         },
 *                                         function (req, next) {
 *                                           // 去掉connectRequest的话，这个network也会重新走一次拦截器，即用上面的setTimeout开始，并回到这里。
 *                                           network('first', null, { connectRequest: req }).then(next);
 *                                         }
 *                                        );
 * @returns Promise
 */
const network = function (url, params, userConfig) {
  params = params || {};
  const config = Object.assign({}, network.config, userConfig || {});
  const Log = config.debug ? console.log : noop;

  const axiosParams = {
    ...omit(userConfig, Object.keys(network.config)),
    url: url || '',
    method: (config.method || 'get').toLowerCase(),
  };

  network.effectConfig(config, axiosParams, userConfig);

  const interceptorIndicator = network.interceptors.request.indexOf(
    config.connectRequest && config.connectRequest.__interceptor_indicator__
  );
  const internalRequestInterceptor = [
    function (req, next) {
      req.url = transformUrl(req.url, req);

      if (req.method === 'get') {
        req.params = Object.assign(getGlobalParamsSnapshot(false, req).params, config.params || {}, params);
        next();
      } else {
        let mutationParams = params;
        const paramsSnapshot = getGlobalParamsSnapshot(true, req, mutationParams);
        let pr;

        if (mutationParams && typeof FormData !== 'undefined' && mutationParams instanceof FormData) {
          // @todo 还没经过测试，本意是复制一个FormData，避免修改原始数据，不然retry时会重复append数据。
          mutationParams = cloneFormData(mutationParams);

          Object.keys(paramsSnapshot.data).forEach((key) => {
            mutationParams.append(key, paramsSnapshot.data[key]);
          });
        } else if (!isArray(paramsSnapshot.data)) {
          mutationParams = Object.assign(paramsSnapshot.data, mutationParams);
        }

        if (typeof mutationParams !== 'string' && !isNativeDataObject(mutationParams)) {
          if (config.paramsFormat !== 'json') {
            if (config.paramsFormat === 'raw') {
              req.headers = { 'Content-Type': 'application/json' };
              mutationParams = JSON.stringify(mutationParams);
            } else {
              pr = loadQs().then(function (e) {
                mutationParams = e.stringify(mutationParams);
              });
            }
          }
        }

        (pr || Promise.resolve()).then(function () {
          req.data = mutationParams;
          req.params = Object.assign(paramsSnapshot.params, config.params || {});
          next();
        });
      }
    },
  ];

  const internalResponseInterceptor = [
    function (res, isError, next, reject) {
      const { onBusinessError, checkValidate, retry, retryInterval, pickData } = config;

      function resolve(res) {
        next(pickData ? pickData(res, axiosParams, config) : res);
        network.successHandler(res);

        Log('done', res);
      }

      if (checkValidate(res, isError, axiosParams, config)) {
        resolve(res);
      } else {
        network.catchError(res, { url, params, config: userConfig }).then(resolve, function (res) {
          function _retry() {
            network(url, params, Object.assign(userConfig, { retry: retry - 1 })).then(next, reject);
          }

          if (retry > 0) {
            Log('retry', res, res.config);
            retryInterval ? setTimeout(_retry, retryInterval) : _retry();
          } else {
            const errorMsg = config.getErrorMessage(res) || getErrorMessage(res);

            Log('error', res, res.config);

            if (!res._$$hasBeenConsumed$$_) {
              onBusinessError && onBusinessError(errorMsg, res);
              network.errorHandler(res, errorMsg);
              res._$$hasBeenConsumed$$_ = true;
            }

            reject(res);
          }
        });
      }
    },
  ];

  function runResponseInterceptor(res, isError) {
    if (isError && (!res.response || res instanceof Error)) {
      const errorMsg = config.getErrorMessage(res) || getErrorMessage(res);
      config.onError && config.onError(res, config);
      network.errorHandler(res, errorMsg, isError);
      return Promise.reject(res);
    }

    return new Promise(function (resolve, reject) {
      runInterceptor(
        wrapResponseInterceptor(
          ((config.interceptors && config.interceptors.response) || [])
            .concat(network.interceptors.response)
            .concat(internalResponseInterceptor)
        ),
        resolve,
        res,
        [isError, reject]
      );
    });
  }

  return new Promise((resolve, reject) => {
    runInterceptor(
      wrapRequestInterceptor(
        internalRequestInterceptor
          .concat(
            network.interceptors.request.slice(interceptorIndicator + 1) // 跳过已经执行的业务拦截器，防止在业务拦截器死循环
          )
          .concat((config.interceptors && config.interceptors.request) || [])
      ),

      function (req) {
        let _localId;
        const { httpId } = config;
        delete req.__interceptor_indicator__;

        if (httpId) {
          _localId = httpSheet[httpId] = (httpSheet[httpId] || 0) + 1;
        }

        function checkBeforeApply(fn) {
          if (!httpId) return fn;
          return function () {
            if (_localId === httpSheet[httpId]) {
              fn.apply(this, arguments);
              delete httpSheet[httpId];
            }
          };
        }

        Log('start request', req);
        network
          .axiosInstance(req)
          .then(
            (res) => runResponseInterceptor(res),
            (error) => {
              // if (error.response) {
              //   // The request was made and the server responded with a status code
              //   // that falls out of the range of 2xx
              // } else if (error.request) {
              //   // The request was made but no response was received
              //   // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              //   // http.ClientRequest in node.js
              // } else {
              //   // Something happened in setting up the request that triggered an Error
              //   // console.log('Error', error.message);
              // }
              return runResponseInterceptor(error, true);
            }
          )
          .then(checkBeforeApply(resolve), checkBeforeApply(reject));
      },

      axiosParams
    );
  });
};

Object.assign(network, {
  /**
   * 定义项目的全局参数。用network发起的请求都会带上这些全局参数。
   * @param {String}  key 全局参数名
   * @param {*}       val 参数值。为函数是每次请求对其递归求值。签名：(req) => any
   * @param {Boolean} dataFirst 参数是否优先作为axios的data域
   * @example
   * network.setBaseParams('entry', () => 'xxx', true);
   * network('/api/aaa'); // 虽然没定义参数，但是会带上全局参数{entry: 'xxx'}。
   */
  setBaseParams(key, val, dataFirst) {
    if (arguments.length < 2) {
      delete globalParams[key];
    } else {
      globalParams[key] = {
        val,
        dataFirst,
      };
    }
  },

  /**
   * 定义项目的请求路径的别名
   * @param {String} key 别名名称
   * @param {*}      val 映射值。为函数是每次请求对其递归求值。签名：(req) => string
   * @example
   * network.addAlias('/useApi', '/api/user');
   * network('/useApi/1'); // 实际请求地址是 '/api/user/1'
   *
   */
  addAlias(key, val) {
    aliasList.push([key, val]);
  },

  // 每次有请求业务正确都会回调，包含重试的。
  successHandler: noop,
  // 每次有请求业务错误和原生错误都会回调，不包含重试的。
  // 通常在这进行日志上报等工作。
  errorHandler: noop,

  // 有请求业务错误时回调。不同于errorHandler的是，catchError专注于处理错误，而errorHandler仅仅用于通知。
  // 你可以在此统一实现重新登录，请求重定向等逻辑。
  // 函数签名：(res, entryParams) => Promise
  catchError(res) {
    return Promise.reject(res);
  },

  // 拦截器。拦截器用的是utils/interceptor，但是函数签名有小幅度调整，以符合network的直观感受。
  interceptors: {
    // 请求时的拦截器队列，签名 (req, next) => {}。network内部有比用户拦截器执行优先级更高的拦截器。
    // req为axios的参数。
    // next无需传参数，内部重载了它固定传req给下游。详见utils/interceptor
    request: [],

    // 响应时的拦截器队列，签名 (res, isError, next, reject) => {}。
    // ⚠⚠⚠ network内部有拦截器实现retry，onBusinessError，httpId（解决竞态条件）等。但它的执行优先级比用户拦截器低。
    // 内部实现用了签名的所有参数，如果用户拦截器传递了不合适的res，或者调用了reject，那么这些功能将受影响。
    // 所以确保你清楚了内部实现再考虑使用该拦截器，否则请考虑使用catchError和errorHandler。
    // res      首个用户拦截器收到的是axios的响应体，后面的拦截器收到的是上游next的值。
    // isError  res是否取自axios的catch。
    // next     详见utils/interceptor
    // reject   调用后忽略后面的拦截器，并Promise.reject该次network调用。
    response: [],
  },

  // 在拦截器之前执行，用于对config做副作用。
  effectConfig() {},

  config: {
    debug: false,
    request: [],
    response: [],
    retry: 0,
    getErrorMessage: noop,
    onError: noop,
    checkValidate(res, isError) {
      if (isError) return false;

      try {
        return !!res;
      } catch (e) {
        return false;
      }
    },
    pickData(res) {
      try {
        return JSON.parse(res.data);
      } catch (e) {
        return res.data;
      }
    },
  },
});

network.axiosInstance = axiosInstance; // 挂在network下，方便进行测试时，单独对axiosInstance进行mock。

export { transformUrl, getErrorMessage, network, axiosInstance as axios };
export default network;
