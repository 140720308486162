import { types } from 'mobx-state-tree';
import { AjaxDescribe, AjaxDescribeHelper, createAjaxDescribeMixins } from '@common/utils/describe-helper';

const SystemSettingServer = createAjaxDescribeMixins(
  'ajaxDescribe',
  AjaxDescribe,
  function () {
    this.createAjaxDescribe(
      'ajaxDescribe',
      () => [`/newApi/system/configuration`, null, { useAuth: true }],
      (state) => this.updateFromAjaxJson(state.data)
    );
  },
  'load'
);

const UnknownArray = types.optional(types.array(types.frozen()), []);
const Deposit = types.model({
  money: 25000,
  reserveTime: 72,
});

function extendPhoneNumber(phoneNumber) {
  // eslint-disable-next-line no-new-wrappers
  phoneNumber = new String(phoneNumber);
  phoneNumber.connector = `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
  phoneNumber.bracket = `(${phoneNumber.slice(0, 3)})-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
  phoneNumber.tel = `tel:${phoneNumber.connector}`;
  return phoneNumber;
}

const SystemSetting = types.compose(
  AjaxDescribeHelper,
  SystemSettingServer,
  types
    .model({
      phoneNumber: '6478121067', // 联系方式
      sellCustomPhoneNumber: '6478121067', // 用户要卖车时，联系公司的哪个电话

      interestrate: 5.99,
      deposit: types.optional(Deposit, {}),
      coverage: UnknownArray,
      gap: UnknownArray,
      protection: UnknownArray,
    })
    .views((self) => ({
      get PHONE_NUMBER() {
        return extendPhoneNumber(self.phoneNumber);
      },
      get SELL_CUSTOM_PHONE_NUMBER() {
        return extendPhoneNumber(self.sellCustomPhoneNumber);
      },
    }))
    .actions((self) => ({
      updateFromAjaxJson(json) {
        const data = { ...json };

        if (json) {
          ['protection', 'coverage', 'gap'].forEach((key) => {
            if (json[key]) {
              data[key] = json[key].slice(0).sort((a, b) => a.sort - b.sort);
            }
          });
        }

        Object.assign(self, {
          ...data,
          deposit: {
            ...self.deposit,
            ...data.deposit,
          },
        });
      },
    }))
);

export default SystemSetting;
