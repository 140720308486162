import { find } from 'lodash';
import network from '@common/libs/network';
import { rootStore } from '@/models';

const couponServer = {
  fetchCoupon(code) {
    return network(`/newApi/coupon/${code}`, null, {
      useAuth: true,
    });
  },
  fetchMyCouponTicket() {
    return network(
      `/newApi/invite/coupon`,
      {
        email: rootStore.auth.user.email,
      },
      {
        useAuth: true,
      }
    ).then((res) => (Array.isArray(res) ? res : [res]).map((item) => item.coupon || item));
  },
  fetchCouponTicket(id) {
    return couponServer.fetchMyCouponTicket().then((list) => find(list, { id }));
  },
  fetchMyRewardsCoupon() {
    return network(
      `/newApi/invite/coupon`,
      {
        email: rootStore.auth.user.email,
      },
      {
        useAuth: true,
      }
    ).then((res) => (Array.isArray(res) ? res : [res]));
  },
};

export default couponServer;
