import { observer } from 'mobx-react-lite';
import { makeObservable, observable, computed, action, makeAutoObservable } from 'mobx';
import { findIndex } from 'lodash';

let gid = 0;
const dynamicComponents = makeAutoObservable({
  list: [],

  add(key, render) {
    this.list.push({ key, render });
  },

  remove(key) {
    const index = findIndex(this.list, { key });
    if (index !== -1) {
      this.list.splice(index, 1);
    }
  },
});

export const DynamicComponentArea = observer(function DynamicComponentArea() {
  return (
    <div>
      {dynamicComponents.list.map((item) => (
        <div key={item.key}>{item.render()}</div>
      ))}
    </div>
  );
});

export class UIController {
  _subscribes = [];

  init() {
    const _gid = gid++;
    if (this._isInited) return this;

    dynamicComponents.add(_gid, () => this.render());
    this._subscribes.push(() => dynamicComponents.remove(_gid));
    this._isInited = true;
    return this;
  }

  render() {}

  destroy() {
    this._subscribes.forEach((fn) => fn());
  }
}

export class DialogUIController extends UIController {
  value;

  constructor(value) {
    super();
    this.value = value;

    makeObservable(this, {
      value: observable,
      state: computed,
      onChange: action,
      show: action,
      hide: action,
    });
  }

  get state() {
    return {
      ...this._params_,
      value: this.value,
      onChange: this.onChange.bind(this),
    };
  }

  onChange(val) {
    this.value = val;
  }

  show(params) {
    this._params_ = params;
    this.onChange(true);
  }

  hide() {
    this._params_ = null;
    this.onChange(false);
  }
}
