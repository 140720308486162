import { FeeList } from '@/models/Fee/Fee';
import ExtendedWarrantyFees from '@common/models/FinanceApplication/ExtendedWarrantyFees';

// 兼容旧订单
export function buildFromOldOrder(json) {
  if (!json) return json;
  if ('extendedWarrantyFees' in json) return json; // 新订单不用处理。extendedWarrantyFees是新订单字段。
  if (!('protectionFeeList' in json)) return json; // 新订单hold阶段没有extendedWarrantyFees。旧订单有protectionFeeList字段，至于旧hold单，先不考虑。

  // 兼容延保计划
  const extendedWarrantyFees = ExtendedWarrantyFees.create({});
  [
    ['vehicle', 'Vehicle'],
    ['corrosion', 'Corrosion'],
    ['gap', 'Gap'],
  ].forEach(function ([field, keyword]) {
    const fee = json.protectionFeeList.filter((fee) => fee.name.indexOf(keyword) !== -1)[0];
    if (!fee) return;
    extendedWarrantyFees[field].updateFromAjaxJson({
      optionId: json.protectionPlan[field],
      value: fee.value,
      optionName: fee.desc,
      name: extendedWarrantyFees[field].prefixName + ': ' + fee.desc,
    });
  });

  json = {
    ...json,
    extendedWarrantyFees: extendedWarrantyFees.toJSON(),
  };

  // 兼容费用列表
  const feeList = FeeList.create({
    list: json.extraCharges,
  });

  json.priceList = {
    list: [...feeList.commonFees.map((fee) => fee.toJSON())],
  };

  return json;
}
