import { types } from 'mobx-state-tree';
import { generateDescribeModule } from '@common/utils/describe-helper';
import { createAjaxDescribe } from '@common/utils/createAjaxDescribe';
import Coupon from '../Coupon/Coupon';
import couponServer from '@/services/coupon';

const CouponRecord = types.model({
  code: '',
  coupon: Coupon,
  id: 0,
  inviter_name: '',
});

const InvitationCouponListServer = generateDescribeModule('ajaxDescribe', [
  createAjaxDescribe,
  couponServer.fetchMyRewardsCoupon,
  {
    concurrent: true,
    onDataUpdate(data) {
      this.updateFromAjaxJson(data);
    },
  },
]);

const InvitationCouponList = types.compose(
  InvitationCouponListServer,
  types
    .model({
      list: types.optional(types.array(CouponRecord), []),
    })
    .views((self) => ({
      get availableList() {
        return self.list.map((record) => record.coupon);
      },
    }))
    .actions((self) => ({
      updateFromAjaxJson(data) {
        self.list = data || [];
      },
      getMostFavorableCoupon() {
        return self.list[0]?.coupon;
      },
      getMostFavorableRecord() {
        return self.list[0];
      },
    }))
);

export default InvitationCouponList;
