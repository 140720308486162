import { omit } from 'lodash';
import { types } from 'mobx-state-tree';
import { assign, object } from 'superstruct';
import { createValidate, formInputValue } from './util';
import Person from './Person';
import Location from './Location';
import EmploymentHistory from './EmploymentHistory';
import ResidentionInformation from './ResidentionInformation';
import { StringOrNumber } from '@common/utils/verification';

// Buyer
const Buyer = types.compose(
  Person,
  Location,
  types
    .model({
      personaInquiryId: formInputValue,
      residentionInfo: types.optional(ResidentionInformation, ResidentionInformation.create().toJSON()),
      employmentInfo: types.optional(EmploymentHistory, EmploymentHistory.create().toJSON()),
    })
    .actions((self) => ({
      mergeFrom(key, data) {
        if (key && (typeof data === 'string' || typeof data === 'number' || Array.isArray(data))) {
          self[key] = data;
        } else {
          Object.assign(key ? self[key] : self, data);
        }
      },
      toUserJSON() {
        return omit(self.toJSON(), ['residentionInfo', 'employmentInfo']);
      },
    })),
  createValidate(() => Buyer.verification)
);
Buyer.verification = assign(
  Person.verification,
  Location.verification,
  object({
    personaInquiryId: StringOrNumber.maybe,
    residentionInfo: ResidentionInformation.verification,
    employmentInfo: EmploymentHistory.verification,
  })
);

export default Buyer;
