import qs from 'qs';
import { get, set } from 'lodash';
import { network, axios, getErrorMessage } from '@common/libs/network';
import { replaceUrl } from '@common/utils/web-helper';
import { rootStore } from '@/models';
import { ADMINBACK, CDNADDR, ENDPOINT, ENDPOINT_NEW } from './api';
import * as URLParse from 'url-parse';
import toast from '@/controller/ToastController';
import { loginController } from '@/controller/async';

axios.defaults.paramsSerializer = (params) => {
  return qs.stringify(params);
};

network.addAlias('/api', ENDPOINT);
network.addAlias('/cdnApi', CDNADDR);
network.addAlias('/adminApi', ADMINBACK);
network.addAlias('/newApi', ENDPOINT_NEW);
network.CDNADDR = CDNADDR;

function createCheckValidate(code) {
  return function (res, isError) {
    if (isError) return false;

    try {
      return typeof code === 'function' ? code(res.data) : +res.data.code === code;
    } catch (e) {
      return false;
    }
  };
}

network.createCheckValidate = createCheckValidate;
network.checkCodeEqual__200 = function (res) {
  return res.status === 200;
};
network.checkCodeEqual_1 = createCheckValidate(1);
network.checkCodeEqual_0 = createCheckValidate(0);
network.checkCodeEqual_200 = createCheckValidate(200);

Object.assign(network.config, {
  paramsFormat: 'json',
  checkValidate: network.checkCodeEqual_0,
  pickData(res) {
    try {
      const json = typeof res.data === 'string' ? JSON.parse(res.data) : res.data;
      return json.data;
    } catch (e) {
      return res.data;
    }
  },
});

network.effectConfig = function (config, req, userConfig) {
  if ((!userConfig || !userConfig.checkValidate) && /^\/newApi/.test(req.url)) {
    config.checkValidate = network.checkCodeEqual_200;
  }
};

network.errorHandler = function (err) {
  const httpStatus = get(err, 'response.status');

  function goto(path) {
    replaceUrl(
      URLParse(window.location)
        .set('pathname', '/' + (path || ''))
        .toString(),
      true
    );
  }

  function goHome() {
    goto();
  }

  function toastErrAndGoHome() {
    toast.show(get(err, 'response.data.message') || getErrorMessage(err));
    setTimeout(goHome, 3000);
  }

  if (httpStatus) {
    switch (httpStatus) {
      case 401:
        if (!err.config.ignore401) {
          new Promise((resolve) => loginController.show({ autoJump: false, onSuccess: resolve }));
        }
        break;
      case 403:
        if (!err.config.ignore403) {
          toastErrAndGoHome();
        }
        break;
      // case 404:
      //   if (!err.config.ignore404) {
      //     toastErrAndGoHome();
      //   }
      //   break;
      case 405:
        if (!err.config.ignore405) {
          toastErrAndGoHome();
        }
        break;
      default:
        break;
    }
  }
};

network.interceptors.request.push(function (req) {
  // useAuth时带上 rootStore的auth.token
  // if (req.useAuth) {
  // }
  if (rootStore.auth.isLogin) {
    // set(req, 'headers.token', rootStore.auth.token);
    set(req, 'headers.Authorization', rootStore.auth.tokenStr);
    // if (get(rootStore.auth, 'user.email')) {
    //   set(req, 'headers.EMAIL', rootStore.auth.user.email);
    // }
  }
});
