import { useRef, useState, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { safeRender, toVueSlots } from '@common/utils/slots';
import { rootStore } from '@/models';
import { getErrorMessage } from '@common/libs/network';
import toast from '@/controller/ToastController';
import Button from '@/components/Button';
import GoogleLoginButton from '../GoogleLoginButton';
import PresetForm from '@/widgets/PresetForm';
import { presetTheme } from '@/widgets/PresetForm/preset';
import useDestroyRef from '@common/hooks/useDestroyRef';
import useExportApiRef from '@common/hooks/useExportApiRef';
import useWatch from '@common/hooks/useWatch';
import './LoginPanel.less';

export function toastFindPasswordSuccess() {
  toast.shortcut(
    {
      icon: require('@/static/sent.png').default,
      content: 'Your password reset instructions has been sent to your email, please check.',
      button: 'Okay',
    },
    { duration: 0, theme: 'default' }
  );
}

const formTheme = presetTheme['login'];
const defaultValues = formTheme.field.reduce((acc, key) => {
  acc[typeof key === 'string' ? key : key.name] = '';
  return acc;
}, {});

const LoginPanel = observer(function LoginPanel({
  onSuccess,
  onFail,
  onInputChange,
  children,
  apiRef,
  watchList,
  onWatchChange,
}) {
  const destroyRef = useDestroyRef();
  const submitBtnRef = useRef();
  const submitPrRef = useRef();
  const [actionType, setActionType] = useState('');

  // react-hook-form表单验证配置
  const formApi = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: formTheme.resolver(),
  });
  const { handleSubmit, formState, getValues, reset, watch } = formApi;
  const submitHandler = handleSubmit((data) => {
    rootStore.auth.login({ ...data, accesstoken: '' }).then(
      function () {
        if (!destroyRef.current) {
          setActionType('');
          onSuccess && onSuccess(getValues());
        }
        reset();
        submitPrRef.current && submitPrRef.current.resolve(getValues());
      },
      (err) => {
        if (!destroyRef.current) {
          setActionType('');
          onFail && onFail(err);
        }
        submitPrRef.current && submitPrRef.current.reject(err);
      }
    );
  });

  // 通过ref向外暴露api
  useExportApiRef(
    apiRef,
    [
      useCallback(
        () =>
          new Promise(function (resolve, reject) {
            submitPrRef.current = { resolve, reject };
            submitBtnRef.current && submitBtnRef.current.click();
          }),

        [submitBtnRef]
      ),
    ],
    (submit) => ({
      submit,
    })
  );

  useWatch(
    (watchList || []).map((key) => watch(key, '')),
    function (changeList) {
      onWatchChange && onWatchChange(changeList);
    }
  );

  // onInputChange回调表单的当前输入是否完整，以供外部依赖这个状态展示某些UI
  const inputMissing = !formState.isDirty || (formState.isDirty && !formState.isValid);
  useWatch(
    [!inputMissing],
    function ([complete]) {
      onInputChange && onInputChange(complete);
    },
    { immediate: true }
  );

  const $slots = toVueSlots(safeRender(children, {}));

  return (
    <div className="login-panel flex-item flex flex--col">
      <GoogleLoginButton onSuccess={onSuccess} onFail={onFail} />

      <div className="hr-div flex-grid ff-black">OR</div>

      <FormProvider {...formApi}>
        <form className="form flex-item flex flex--col flex--edge" onSubmit={submitHandler}>
          <PresetForm theme="login" />

          <input className="display-none" type="submit" ref={submitBtnRef} />
          {$slots.default || (
            <div>
              <Button
                className={classNames('full-width mt20', {
                  'c-button--disabled': inputMissing,
                })}
                type="submit"
                loading={actionType === 'local' && rootStore.auth.isHttpLoading}
                disabled={rootStore.auth.isHttpLoading}
                onClick={() => setActionType('local')}
              >
                Log In
              </Button>

              <div className="mt20 text-center cursor-pointer" onClick={toastFindPasswordSuccess}>
                Forgot your password?
              </div>
            </div>
          )}
        </form>
      </FormProvider>
    </div>
  );
});

export function toastLoginError(err) {
  // toast.show(getErrorMessage(err) + ', please check your username or password'); // 因登录后嵌入了完善信息流程，所以现在不能再假设login fail是账号密码错误了
  toast.show(getErrorMessage(err));
}
export default LoginPanel;
