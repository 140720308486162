import { types } from 'mobx-state-tree';
import Car from './Car';

function hasIt(list, car) {
  return list.find((t) => t.uuid === car.uuid);
}

const Collection = types
  .model({
    list: types.optional(types.array(types.late(() => Car)), []),
  })
  .views((self) => {
    return {
      isCollected(car) {
        return hasIt(self.list, car);
      },
    };
  })
  .actions((self) => ({
    add(car) {
      self.exclude(car);
      self.list.push(car);
    },

    exclude(car) {
      const index = self.list.findIndex((item) => item.uuid === car.uuid);

      if (index !== -1) {
        self.list.splice(index, 1);
      }
    }
  }));

export default Collection;
