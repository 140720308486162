import { forEach } from 'lodash';
import { type } from '.';

/**
 * 判断两个对象或数组是否相似。算法是两个对象的json表示法是否相等
 * 如果传入简单数据类型，则用相等运算符运算。如果传入其他复杂数据类型，则返回false。
 *
 * @param    a  要比较的值
 * @param    b  要比较的值
 * @param {Boolean} [congruent=true]  是否进行完整匹配。区别：
 *                                    similar({a: 1, b: 2, c: 3}, {b: 2, a: 1}, true) // false
 *                                    similar({a: 1, b: 2, c: 3}, {b: 2, a: 1}, false) // true
 * @example
 * similar({a: 1, b: 2}, {b: 2, a: 1}) // ==> true;
 * similar({a: 1}, {a: 1, b: 2}) // ==> false;
 * similar({a: 1, b: null}, {a: 1}) // ==> false;
 * similar({}, {}) // ==> true;
 * similar([], []) // ==> true;
 * similar([{a: 1}], [{a: 1}]) // ==> true;
 * similar([], [undefined]) // ==> false;
 * similar(1, '1') // ==> true;
 * similar(/a/, /a/) // ==> false;
 */
export function similar(a, b, congruent) {
  var complexTypeReg = /object|array/;
  congruent = congruent !== false;

  function contrast(a, b, congruent) {
    var a_type = type(a);
    var b_type = type(b);
    var a_len = 0;
    var b_len = 0;

    if (a === b) {
      return true;
    } else if (a_type !== b_type) {
      return false;
    } else if (complexTypeReg.test(a_type)) {
      if (congruent) {
        a_len = diffOf(a, b, congruent);

        if (a_len !== false) {
          b_len = diffOf(b, a, congruent);
          return a_len === b_len && (a_len === 0 ? true : !!a_len);
        }
        return false;
      } else {
        return !!diffOf(a, b, congruent);
      }
    } else if (b !== a) {
      return false;
    }
  }

  function diffOf(a, b, congruent) {
    var len = 0,
      result = true;

    forEach(b, function (val, key) {
      len++;
      return (result = contrast(val, a[key], congruent));
    });

    return result && (!!len || (!congruent && !len && !Object.keys(a).length));
  }

  return a === b || contrast(a, b, congruent);
}
