import { types } from 'mobx-state-tree';
import { object } from 'superstruct';
import { createValidate, formInputValue } from './util';
import { Address, City, PostalCode, Province } from '../../utils/verification';

// 地址信息
const Location = types.compose(
  types.model({
    address: formInputValue,
    city: formInputValue,
    province: formInputValue,
    postal: formInputValue,
  }),
  createValidate(() => Location.verification)
);

Location.verification = object({
  address: Address.required,
  city: City.required,
  province: Province.required,
  postal: PostalCode.required,
});

export default Location;
