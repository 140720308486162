import { locationHref } from './locationHref';

/**
 * @category web-helper
 * @description 返回适应图片的尺寸
 * @param {String} w
 * @param {String} h
 * @param {Number} maxW=Infinity
 * @param {Number} maxH=Infinity
 * @param {String} unit='px'
 *
 * @returns {Object} img
 * @returns {String} img.width 图片宽度+unit
 * @returns {String} img.height 图片高度+unit
 */
export function adaptionImg(w, h, maxW = Infinity, maxH = Infinity, unit = 'px') {
  function resolveUnit(val) {
    return unit ? val + unit : val;
  }

  if (w < maxW && h < maxH) {
    return {
      width: resolveUnit(w),
      height: resolveUnit(h),
    };
  }

  function adaptionWidth() {
    if (w > maxW) {
      h = (h * maxW) / w;
      w = maxW;
    }
  }

  adaptionWidth();

  if (h > maxH) {
    w = (w * maxH) / h;
    h = maxH;
  }

  adaptionWidth();

  return {
    width: resolveUnit(w),
    height: resolveUnit(h),
  };
}

export function replaceUrl(url, assign) {
  locationHref(url, !assign);
}
