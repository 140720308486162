const { useEffect } = require('react');
const { useStaticRef } = require('./useStaticRef');

const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;

const defaultConfig = {
  attributes: true,
  childList: true,
  characterData: true,
  subtree: true,
};

export function createMutationObserver(el, cb, config) {
  let observer;
  if (MutationObserver) {
    observer = new MutationObserver(cb);
    observer.observe(el, { ...defaultConfig, ...config });
  }

  return () => {
    observer && observer.disconnect();
  };
}

export default function useMutationObserver($el, cb, config) {
  const ref = useStaticRef({
    el: $el,
    config,
    cb,
  });

  useEffect(() => {
    const { el, config, cb } = ref;
    return createMutationObserver(el, cb, config);
  }, [ref]);
}
