import './style/main.scss';
import './style/main.less';

import React from 'react';
import ReactDOM from 'react-dom';
import './services/network-init';
import App from './App';
import { initRootStore, RootStoreProvider } from '@/models';
import { ViewportProvider } from '@common/hooks/useViewport';
import URLParse from 'url-parse';
import { DEV_MODE } from './env';
import emailController from './controller/emailController';
// import reportWebVitals from './reportWebVitals';

emailController.init();

if (DEV_MODE) {
  document.title = '.';
}

async function main() {
  const store = await initRootStore();
  window.xxx = store;

  ReactDOM.render(
    <React.StrictMode>
      <RootStoreProvider>
        <ViewportProvider
          matchMedia={{
            sm: '35.5em',
            md: '48em',
            lg: '64em',
            xl: '80em',
            max: '1420px',
            max2: '1540px', // max + 120
            max3: '1660px', // max2 + 120
          }}
        >
          <App />
        </ViewportProvider>
      </RootStoreProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

if ('debug' in URLParse(window.location.href, true).query) {
  import('eruda').then((e) => {
    e.default.init();
    //console.log(UPDATE_DATE);
    main();
  });
} else {
  main();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
