/**
 * @file 定义业务的验证实体类。基于superstruct
 */
import { get } from 'lodash';
import { size, string } from 'superstruct';
import { defineFormValidate, isNotEmpty } from './helper/superstruct-helper';

const emailReg =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function transformToFormValue(val) {
  return val === null || val === undefined ? '' : val + '';
}

export function isEmail(value) {
  return emailReg.test(value);
}

/* 具体业务类型定义 */
// email
export const Email = defineFormValidate(
  'email',
  isEmail,
  'Please enter the correct email address',
  'Please input email address'
);

// first name & last name
export const Name = defineFormValidate(
  'name',
  size(string(), 0, 200),
  "The name it's too long",
  'Please input your name'
);

export const FirstName = defineFormValidate(
  'first name',
  size(string(), 0, 200),
  "The first name it's too long",
  'Please input first name'
);
export const LastName = defineFormValidate(
  'last name',
  size(string(), 0, 200),
  "The last name it's too long",
  'Please input last name'
);

// phone
export const Phone = defineFormValidate(
  'phone',
  (value) => /^\d{10}$/.test(value),
  'Please enter the correct phone number',
  'Please input phone number'
);

// password
export const Password = defineFormValidate('password', isNotEmpty, 'Please input password', 'Please input password');
/**
 * 重复密码的验证无法静态化，这个需要动态创建。
 * @example
 * const passwordRef = useRef('');
 * const [formOptions] = useState(() => ({
 *   resolver: {
 *     confirmPassword: defineFormValidate('confirm password', (value) => passwordRef.current === value, 'Please input the same password'),
 *   }
 * }));
 *
 * const { watch } = useForm(formOptions);
 * passwordRef.current = watch('password', '');
 */

// card
export const Card = defineFormValidate(
  'card',
  isNotEmpty,
  'Please enter the correct card information',
  'Please input card information'
);

// 日期
export const Dates = defineFormValidate('date', isNotEmpty, 'Please select the date', 'Please select the date');
export const DatePicker = defineFormValidate(
  'date',
  isNotEmpty,
  'Please select the date',
  'Please select the date',
  function (obj) {
    if (typeof obj === 'string') {
      return obj;
    }

    const year = transformToFormValue(get(obj, 'year'));
    const month = transformToFormValue(get(obj, 'month'));
    const day = transformToFormValue(get(obj, 'day'));
    const hour = transformToFormValue(get(obj, 'hour'));
    const minute = transformToFormValue(get(obj, 'minute'));

    if (!year) return '';

    return `${year}-${month}-${day} ${hour}:${minute}`;
  }
);

// 地址类
export const Address = defineFormValidate('address', size(string(), 0, 500), 'The content is too long');

export const City = defineFormValidate('city', size(string(), 0, 200), 'The content is too long');
export const CitySelect = defineFormValidate(
  'city',
  size(string(), 0, 200),
  'The content is too long',
  '',
  function (obj) {
    return transformToFormValue(get(obj, 'value'));
  }
);

export const Province = defineFormValidate(
  'province',
  size(string(), 0, 200),
  'The content is too long',
  'Please select province'
);

export const PostalCode = defineFormValidate('postal code', size(string(), 0, 200), 'The content is too long');

export const Terms = defineFormValidate('terms', (value) => Boolean(value), 'Please read the terms');

/* 通用类型定义 */
const formNumber = ['number', 'string'];

export const PositiveNumber = defineFormValidate(
  'PositiveNumber',
  (value) => formNumber.indexOf(typeof value) !== -1 && +value > 0,
  'Please enter a number not less than 0',
  'This field is required'
);

export const StringOrNumber = defineFormValidate(
  'StringOrNumber',
  (value) => formNumber.indexOf(typeof value) !== -1,
  'Please enter the correct value',
  'This field is required'
);

export const NotLessThanZero = defineFormValidate(
  'PositiveNumber',
  (value) => formNumber.indexOf(typeof value) !== -1 && +value >= 0,
  'Please enter the correct value',
  'This field is required'
);

export const Ignore = defineFormValidate('Ignore', () => true);

export const SelectValidate = defineFormValidate(
  'Select',
  isNotEmpty,
  'Please select an option',
  'Please select an option'
);
