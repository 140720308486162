import { types } from 'mobx-state-tree';
import { object } from 'superstruct';
import { SelectValidate, NotLessThanZero } from '../../utils/verification';
import { createValidate, formInputValue } from './util';
import Car from '../Car';

const LendingInstitutions = types.model({
  name: '',
  contact: '',
  address: '',
  phone: '',
  notes: '',
});

// TradeIn
const TradeIn = types.compose(
  types.model({
    hasTrading: formInputValue,
    tradeInValue: types.optional(types.union(types.number, types.string), 0),
    lienAmount: types.optional(types.union(types.number, types.string), 0),
    cashback: types.optional(types.union(types.number, types.string), 0),
    ownType: 0,
    hasTradingLoan: 0,
    lendingInstitutions: types.optional(LendingInstitutions, {}),
    vehicle: types.maybe(Car)
  }),
  createValidate(() => TradeIn.verification)
);

TradeIn.verification = object({
  hasTrading: SelectValidate.required,
  tradeInValue: NotLessThanZero.optional,
  lienAmount: NotLessThanZero.optional,
});

export default TradeIn;
