import { get } from 'lodash';
import { from, of } from 'rxjs';
import { types } from 'mobx-state-tree';
import { AjaxDescribe, AjaxDescribeHelper, createAjaxDescribeMixins } from '@common/utils/describe-helper';
import { CarOrder as CarOrderBase, CarOrderList as CarOrderListBase } from '@common/models/CarOrder';
import orderServer from '@/services/order';
import { map } from 'rxjs/operators';
import Car from './Car';
import Coupon, { serverDataTranslator as serverDataTranslatorForCoupon } from './Coupon/Coupon';
import CouponCode from './CouponCode';
import FormPriceList from '@common/models/Fee/FormPriceList';
import { buildFromOldOrder } from '@/app/translator/order';

// const FinanceApplicationFormDetail = types.compose(
//   FinanceApplicationForm,
//   types.model({
//     vehicle: types.maybe(Car),
//   })
// );

// function fetchCarAndFillOrder(order) {
//   if (!get(order, 'vehicle.vin')) {
//     return of(order);
//   }

//   return Car.create()
//     .load(order.vehicle.vin)
//     .then((data) => {
//       order.vehicle = data;
//       return order;
//     });
// }

export function serverDataTranslator(obj, data) {
  data = data || obj;
  obj.couponTicket = serverDataTranslatorForCoupon(data.couponTicket);
  return obj;
}

const CarOrderServer = types.compose(
  // 获取订单
  createAjaxDescribeMixins(
    'ajaxDescribe',
    AjaxDescribe,
    function () {
      this.createStatusDataDescribe('ajaxDescribe', (id) => from(orderServer.getOrder(id || this.id)), null, {
        onDataUpdate: (data) => {
          this.updateFromAjaxJson(buildFromOldOrder(data));
        },
      });
    },
    'load'
  ),

  // 取消订单
  createAjaxDescribeMixins(
    'cancelAjaxDescribe',
    AjaxDescribe,
    function () {
      this.createStatusDataDescribe(
        'cancelAjaxDescribe',
        (id) => from(orderServer.cancelOrder(id || this.id)),
        (state) => this.updateFromAjaxJson(state.data)
      );
    },
    'cancel'
  ),

  // 编辑订单
  createAjaxDescribeMixins(
    'modifyAjaxDescribe',
    AjaxDescribe,
    function () {
      this.createStatusDataDescribe('modifyAjaxDescribe', (body) => from(orderServer.modifyOrder(this.id, body)));
    },
    'modify'
  ),

  types.model({}).actions((self) => ({
    getLastUpdatedStream() {
      if (self.ajaxDescribe.inited) {
        return of(self);
      } else {
        return from(new Promise((cb) => self._describeEmitter.on('ajaxDescribe_loaded', cb)));
      }
    },

    toValue() {
      return JSON.parse(
        JSON.stringify({
          ...self.toJSON(),
          protectionFeeList: self.protectionFeeList.map((item) => item.toJSON()),
          mdsp: undefined,

          ajaxDescribe: undefined,
          cancelAjaxDescribe: undefined,
          modifyAjaxDescribe: undefined,
        })
      );
    },
  }))
);

const CarOrderListServer = createAjaxDescribeMixins(
  'ajaxDescribe',
  AjaxDescribe,
  function () {
    this.createAjaxPagingDescribe(
      'ajaxDescribe',
      (res) => ['/newApi/order', res, { useAuth: true }],
      (state) => this.updateFromAjaxJson(state.data),
      {
        preConnect: (stream) => stream.pipe(map((res) => ({ page: 0, ...res }))),
      },
      {
        firstPageValue: 1,
        onData(data) {
          return {
            data: data ? data.content : null,
            pageNo: data ? data.index : 1,
            maxPage: data ? data.pages : Infinity,
          };
        },
      }
    );
  },
  'load'
)
  .views((self) => ({
    hasFlowingOrder(carid) {
      return self.flowingList.filter((order) => get(order, 'vehicle.uuid') === carid)[0];
    },

    get flowingList() {
      return this.list.filter((order) => order.isFlowing);
    },
  }))
  .actions((self) => ({
    updateFromAjaxJson(list) {
      self.list = list ? list.filter((item) => item.vehicle).map((item) => serverDataTranslator(item)) : [];
    },
  }));

const CarOrderBusiness = types.model({
  vehicle: types.maybe(Car),
  coupon: types.optional(CouponCode, {}),
  couponTicket: types.optional(Coupon, {}),
  priceList: types.optional(FormPriceList, {}),
});

const CarOrder = types.compose(AjaxDescribeHelper, CarOrderServer, CarOrderBase, CarOrderBusiness);

const CarOrderList = types.compose(
  AjaxDescribeHelper,
  CarOrderListBase,
  CarOrderListServer,
  types.model({
    list: types.array(CarOrder),
  })
);

export { CarOrderList, CarOrder };
