import classNames from 'classnames';
import { cloneElement, forwardRef, useCallback } from 'react';
import { safeRender, toVueSlots } from '@common/utils/slots';
import { useEventCallback } from '@common/hooks/official-hack';
import './style.less';

const falseLabel = '';

const Radio = forwardRef(function (
  { className, label, labelClassName, children, onChange, inputRef, value, onClick },
  ref
) {
  const isSelected = value === label;
  const _ref = inputRef || ref;
  const updateVal = useEventCallback(
    function (e) {
      onChange && onChange(value === label ? falseLabel : label, { label, falseLabel });
      onClick && onClick(e);
    },
    [onChange, onClick, value, label, falseLabel]
  );

  return (
    <div
      className={classNames(
        'c-radio pos-r flex flex--middle cursor-pointer',
        isSelected ? 'c-radio--selected' : '',
        className
      )}
      onClick={updateVal}
    >
      <div className="c-radio__indicator flex-grid"></div>
      <div className={classNames('c-radio__label', labelClassName || 'flex flex--middle flex-item')}>{children}</div>
      <input className="sr-only" ref={_ref} type="radio" />
    </div>
  );
});

const RadioGroup = forwardRef(function ({ children, value, inputRef, onChange }, ref) {
  const _ref = inputRef || ref;
  const changeHandler = useCallback(
    (val) => {
      onChange && val !== falseLabel && onChange(val);
    },
    [onChange]
  );

  const buildChildProps = useCallback(
    (label, index) => ({
      key: label,
      ref: index === 0 ? _ref : null,
      label,
      value,
      onChange: changeHandler,
    }),
    [changeHandler, _ref, value]
  );

  const child = toVueSlots(safeRender(children, { buildChildProps })).default;
  const childs = child && !Array.isArray(child) ? [child] : child;

  return childs.map((child, i) =>
    child.type === Radio ? cloneElement(child, buildChildProps(child.props.label, i)) : child
  );
});

export { Radio, RadioGroup };
