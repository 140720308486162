import classNames from 'classnames';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { PriorityNav } from 'react-priority-navigation';
import { safeRender, toVueSlots } from '@common/utils/slots';
import Modal from '@common/components/Modal';
import Popper from '@common/components/Popper';
import './style.less';

const NavItem = forwardRef(function NavItem({ tag, className, label, to, exact, usA, useSpan, ...props }, ref) {
  const Tag = tag || 'li';
  const match = useRouteMatch({
    path: to,
    exact,
  });

  return (
    <Tag
      ref={ref}
      className={classNames(className, 'nav-item cursor-pointer', match ? ' nav-item--active' : '')}
      {...props}
    >
      {usA ? (
        <a className="full-size flex-grid" href={to}>
          {label}
        </a>
      ) : useSpan ? (
        <span className="full-size flex-grid">{label}</span>
      ) : (
        <Link className="full-size flex-grid" to={to}>
          {label}
        </Link>
      )}
    </Tag>
  );
});

function Menu({ className, useResponsive, closeMenu }) {
  const [ready, setReady] = useState(false);
  const navList = [
    { to: '/products', label: 'Shop EVs' },
    { to: '/sellyourev', label: 'Sell your EV' },
    { to: '/aboutus', label: 'About Us' },
    // { to: '/trade', label: 'Sell Your Car' },
    // 暂时隐藏 Financing
    // { to: '/financing', label: 'Financing' },
    { to: '/testdrive', label: 'Test Drive' },
    { to: '/protection-plan', label: 'Vehicle Protection' },
    { to: '/guarantee', label: 'Guarantee' },
    { to: '/faq', label: 'FAQ' },
    { to: 'https://blog.carnex.ca', label: 'Blog', usA: true },
    /* {to: "/ContactUs" , label: "Contact Us",}, */
  ].map((item, i) => <NavItem key={i} {...item} onClick={closeMenu} />);

  useEffect(() => {
    const timer = setTimeout(() => setReady(true), 300); // 初次渲染有抖动
    return () => clearTimeout(timer);
  });

  if (useResponsive) {
    return (
      <PriorityNav
        className={classNames(className, ready ? '' : 'visibility-hidden')}
        tag="ul"
        dropdown={({ dropdownItems }) => (
          <Popper behavior="delayHover" offset={[0, 6]} contentClassName="nav-popper-content">
            {({ close }) => (
              <>
                <NavItem className="PriorityNav_Item--more" tag="div" useSpan label="More" />
                <ol slot="content">
                  {dropdownItems.map((item, i) => (
                    <NavItem key={i} {...item.props} onClick={close} />
                  ))}
                </ol>
              </>
            )}
          </Popper>
        )}
      >
        {navList}
      </PriorityNav>
    );
  }

  return <ul className={className}>{navList}</ul>;
}

export default function Nav({ value, children, onChange, forMobile, className }) {
  const $slots = toVueSlots(safeRender(children));
  const closeMenu = useCallback(() => {
    onChange(false);
  }, [onChange]);

  const menu = <Menu className="nav-list" closeMenu={closeMenu} />;

  return (
    <nav className={'page-nav ff-heavy ' + (className + '')}>
      {!forMobile
        ? React.cloneElement(menu, {
            className: menu.props.className + ' flex-grid',
            useResponsive: true,
          })
        : null}

      <Modal
        className="nav-menu-dialog dialog--slide-left"
        containerClass="flex flex--middle"
        value={value}
        backdropClose
        floatHead
        onChange={onChange}
      >
        <div>
          {menu}
          {$slots['modal-children']}
        </div>
      </Modal>
    </nav>
  );
}
