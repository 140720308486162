import { action, makeObservable, observable } from 'mobx';

class AsyncControll {
  loading = false;

  constructor(methods, loadController) {
    this.loadController = function () {
      const pr = loadController();
      let isDone = false;

      pr.catch(() => {}).then(() => {
        isDone = true;
        this.setLoading(false);
      });

      setTimeout(() => {
        if (!isDone) this.setLoading(true);
      });

      return pr;
    };

    makeObservable(this, {
      loading: observable,
      setLoading: action,
    });

    methods.forEach((key) => this.createHook(key));
  }

  setLoading(loading) {
    this.loading = loading;
  }

  createHook(name) {
    this[name] = function () {
      const args = arguments;
      return this.loadController().then((controller) => controller[name].apply(controller, args));
    };
  }
}

const dialogMethods = ['show', 'hide'];

export const loginController = new AsyncControll(dialogMethods, () =>
  import('./loginController').then((e) => e.default)
);

export const paymentController = new AsyncControll(['open'].concat(dialogMethods), () =>
  import('./paymentController').then((e) => e.default)
);

export const collectionController = new AsyncControll(dialogMethods, () =>
  import('./collectionController').then((e) => e.default)
);

export const tradeController = new AsyncControll(dialogMethods, () =>
  import('./tradeController').then((e) => e.default)
);
