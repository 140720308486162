import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PinBox from '@common/components/PinBox';
import AnnouncementList from '@/models/Announcement/AnnouncementList';
import Announcement from '.';
import { usePrevious } from '@common/hooks/official-hack';
import { useStaticRef } from '@common/hooks/useStaticRef';

const announcementList = AnnouncementList.create();

const themeClassName = ['', 'site-announcement--green', 'site-announcement--theme', 'site-announcement--theme-shallow'];

function fixSort(list, preList = []) {
  return list.sort(function (a, b) {
    return preList.indexOf(b) - preList.indexOf(a);
  });
}

const GlobalAnnouncement = observer(function GlobalAnnouncement() {
  const [list, setList] = useState(null);
  const preList = usePrevious(list);
  const preListRef = useStaticRef(preList);
  const location = useLocation();

  useEffect(() => {
    announcementList.load({ status: 1 });
  }, []);

  useEffect(() => {
    announcementList
      .getLastUpdatedStream()
      .toPromise()
      .then(function () {
        const url = location.pathname + location.search;
        const list = announcementList.list.filter((item) => item.match(url));
        setList(fixSort(list, preListRef.current));
      });
  }, [location, preListRef]);

  if (!list || !list.length) return null;

  return (
    <PinBox pin={true} pinboxKey="announcement" renderSign={list.length} groupOrder={-999}>
      {list.map((announcement, index) => (
        <Announcement
          key={announcement.id}
          className={themeClassName[index % themeClassName.length]}
          announcement={announcement}
        />
      ))}
    </PinBox>
  );
});

export default GlobalAnnouncement;
