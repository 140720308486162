import { types } from 'mobx-state-tree';
import { getLocalStorage, modifyStorage } from '../../utils/storage';
import DataAlign from '../common/DataAlign';

// Storage实体，自动保存到本地和从本地恢复。
const Storage = types.compose(
  DataAlign,
  types
    .model({
      key: 'root_storage',
      useSessionStorage: false,
      value: types.frozen(),
    })
    .actions((self) => ({
      afterCreate() {
        if (self.key) {
          self._sync();
          self.$registeredChannels && self.$registeredChannels('storage-' + self.key);
        }
      },

      get(key) {
        return typeof key === 'undefined' ? self.value : self.value[key];
      },

      _sync() {
        self.value = JSON.parse(getLocalStorage(self.key, false, self.useSessionStorage) || '{}');
      },
      _modify(doing) {
        modifyStorage(
          self.key,
          (json) => {
            doing(json);
            self.value = json;
            // self.$assign({
            //   value: json,
            // });
          },
          self.useSessionStorage
        );
      },

      del(key) {
        self._modify(function (json) {
          delete json[key];
        });
      },

      // 单个字段操作
      set(key, value) {
        self.assign({
          ...self.value,
          [key]: value,
        });
      },

      // 合并操作
      assign(obj) {
        self._modify(function (json) {
          Object.assign(json, obj);
        });
      },
    }))
);

export default Storage;
