import { from } from 'rxjs';
import network from '../libs/network';
import { createStatusDataDescribe, createStatusDataDescribeForMST } from './createStatusDataDescribe';

export function omitErudaRequest(err) {
  delete err.config;
  delete err.request;
  return Promise.reject(err);
}

function packNetwork(pr) {
  // ajax error时有携带request，eruda编辑了XMLHttpRequest的原型链之类，给request带了个erudaRequest，
  // erudaRequest._xhr又是request本身，造成循环引用。这造成一些JSON.stringify的地方会报错，如vue-data-model的vue类型的$toValue。
  return pr.catch(omitErudaRequest);
}

function networkToObservable(params) {
  return typeof params.then === 'function' ? from(packNetwork(params)) : from(packNetwork(network(...params)));
}

/**
 * 术语：
 * MST：mobx-state-tree。
 * model: mobx-state-tree的实例。由types.model({...})创建
 *
 * 背景：
 * 在MST直接使用network编写业务逻辑会简单易懂，但随着功能或交互要变得更完善，我们可能对这个ajax请求的关注点会变得越来越多，需要创建的状态就会越来越多。
 * 例如是否在loading，请求的响应数据，请求的错误错误，甚至这是一个分页请求，我们还需要保存当前页码等等。
 * 再进一步，如果要继续编写控制逻辑，我们可能会继续用到rxjs，并肯定需要定义subject来控制调用network...
 *
 * 本工具函数就是致力于统筹一个请求的状态和行为（方法），让model层尽量的干净。
 * 由于MST的工作原理和机制，要实施起来有点麻烦，已经有个AjaxDescribeHelper的model来尽量减少模板代码。
 * 而本实现代码量不多，但是理解起来可能并不容易，文档看起来也可能比较晦涩。
 * 所以先看example和注释可能会比较容易理解。
 *
 * @param {Function} resolveParams network的参数工厂，函数签名是：(res) => [url, params?, config?]。
 *                                 内部实现：network(...resolveParams(res))。
 *
 * @example
 * const Carlist = types
 *   .model({
 *     ajaxDescribe: AjaxDescribe,
 *     list: types.array(Car),
 *   })
 *   .actions((self) => {
 *     return {
 *       afterCreate() {
 *         // 这里MST并没有进行直接赋值，而是将值frozen，内部在通过遍历之类初始化self.ajaxDescribe。
 *         self.ajaxDescribe = createAjaxDescribeForMST(
 *           (res) => ['/api/car/list', res], // 必要参数1：传给network的参数列表，
 *           // onUpdate: 我们的list是一个复杂type，所以需要派生出来。
 *           (state) => {
 *             self.list = state.data;
 *           },
 *           null, // postConnect
 *           null, // preConnect
 *           null, // init
 *           {
 *             runInAction: self.runInAction.bind(self),     // 必要参数2：我们内部需要更新self.ajaxDescribe，但是更新model state必须是在action进行
 *             getProxy: () => self.ajaxDescribe,         // 必要参数3：前面说到MST并没有进行直接赋值，所以我们必须取得原state，有更新时对其进行Object.assign(state, change);
 *           }
 *         );
 *       },
 *
 *       runInAction(fn) {
 *         return fn();
 *       },
 *
 *       load() {
 *         self.ajaxDescribe.next({id: 123}).toPromise().then(state => {//...dosomething})
 *       }
 *     };
 *   });
 *
 * const carlist = Carlist.create();
 * carlist.load();
 */


/**
 * 将state的error分离到reject
 * @param {Object} state createStatusDataDescribe的返回值
 * @example
 * state.next().toPromise().then(separateReject).then(onSuccess, toastNetworkError);
 */
export function separateReject(state) {
  if (state.error) return Promise.reject(state.error);
  return state;
}

export function createAjaxDescribeForMST(resolveParams, ...other) {
  return createStatusDataDescribeForMST((res) => networkToObservable(resolveParams(res)), ...other);
}

export function createAjaxDescribe(resolveParams, ...other) {
  return createStatusDataDescribe((res) => networkToObservable(resolveParams(res)), ...other);
}
