import { types } from 'mobx-state-tree';
import { FEE_CATEGORYTYPE, FEE_TAXTYPE } from '../Fee/Fee';
import { PresetPrice } from '../Fee/FormPriceList';

const DEFINE = {
  applyTaxType: FEE_TAXTYPE.PST,
  categoryType: FEE_CATEGORYTYPE.VEHICLE_EXTRA_SERVICE,
};

const VehicleWarranty = types.compose(
  PresetPrice,
  types.model({
    prefixName: 'Extended Warranty',
    optionId: types.maybe(types.number),
    optionName: '',
    protectionPlan: types.optional(types.literal('vehicle'), 'vehicle'),
    ...DEFINE,
  })
);
const CorrosionWarranty = types.compose(
  PresetPrice,
  types.model({
    prefixName: 'Corrosion and Perforation Coverage',
    optionId: types.maybe(types.number),
    optionName: '',
    protectionPlan: types.optional(types.literal('corrosion'), 'corrosion'),
    ...DEFINE,
  })
);
const GapWarranty = types.compose(
  PresetPrice,
  types.model({
    prefixName: 'Gap Insurance',
    optionId: types.maybe(types.number),
    optionName: '',
    protectionPlan: types.optional(types.literal('gap'), 'gap'),
    ...DEFINE,
  })
);

const ExtendedWarrantyFees = types.model({
  vehicle: types.optional(VehicleWarranty, VehicleWarranty.create().toJSON()),
  corrosion: types.optional(CorrosionWarranty, CorrosionWarranty.create().toJSON()),
  gap: types.optional(GapWarranty, GapWarranty.create().toJSON()),
});

export default ExtendedWarrantyFees;
