import { useContext } from 'react';
import { parseExp } from './';

/**
 * 是一个mobx-state-tree store全局化
 * @param {String|Function|Object} getContext 提取store的部分信息
 * @returns Object
 * @example
 * // src/store/index.js
 * const rootStoreContext = Reaact.createContext({});
 * const rootStore = store.create({});
 * const useRootStore = createUseMST(() => rootStoreContext);
 *
 * function RootStoreProvider({ children }) {
 *   return <rootStoreContext.Provider value={rootStore}>{children}</rootStoreContext.Provider>;
 * }
 *
 * // src/index.js
 * <RootStoreProvider>
 *   <App />
 * </RootStoreProvider>
 *
 * // src/App.js
 * const rootStore = useRootStore();
 */
export default function createUseMST(getContext) {
  return function useMst(mapStateToProps) {
    const store = useContext(getContext());

    switch (typeof mapStateToProps) {
      case 'undefined':
        return store;

      case 'string': 
        return parseExp(store, mapStateToProps);
        
      case 'function':
        return mapStateToProps(store);

      default:
        if (!Array.isArray(mapStateToProps)) {
          throw new Error('mapStateToProps 有效值是字符串、函数或数组之一');
        }

        return mapStateToProps.reduce((acc, key) => {
          acc[key] = parseExp(store, key);
          return acc;
        }, {})
    }
  }
}