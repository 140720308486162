/**
 * @module interceptor
 *
 * @summary 简介： 轻松使用拦截器模式。
 * @description 功能： 遍历一个数组，将数组元素以拦截器模式串联执行。
 *
 * @param  {Array}    interceptor 要遍历的数组。子元素函数的签名 ([res], [...args], [next], [exit]) => {}
 *                                res: 上一个拦截器元素next()的参数
 *                                next: 执行下一个拦截器元素，参数将作为执行下一个拦截器元素的res。（只接受一个参数）
 *                                      如果拦截器元素的形参不包含next，那么相当于隐式调用next()。
 *                                exit: 终止拦截器遍历，本环节next后调用callback
 *
 * @param  {Function} cb          拦截器执行完毕后（所有拦截器元素next后）的回调。
 * @param  {*}        initParams  拦截器第一个回调的参数
 * @param  {Array}    args        数组元素的静态参数
 * @param  {Object}   options     配置拦截器的行为
 * @param  {Boolean}  options.allowMultipleNext     是否允许多次调用next()
 *
 * @todo
 * 1. 执行优先级： 尚未规范next()后面的代码及下一个拦截器元素的代码的执行顺序。在目前的实现中，异步和同步调用next，这个执行顺
 * 序有所差异。为了避免怪异行为，推荐不要在next()后执行其它代码。
 *
 * @example
 *
 * const routerInterceptor = [];
 *
 * // 因为形参没有next，所以相当于隐式调用了next()
 * routerInterceptor.push(function(res, to, from) {
 *   console.log('--->  拦截器元素1', res, to, from);
 * });
 * // 2s后执行到下一个拦截器元素，对了，我还传了一个参数。
 * routerInterceptor.push(function(res, to, from, next) {
 *   console.log('--->  拦截器元素2', res, to, from);
 *   setTimeout(() => next(666), 2000);
 * });
 *
 * // 2s后执行到下一个拦截器元素，对了，我还传了一个参数。
 * routerInterceptor.push(function(res, to, from, next) {
 *   console.log('--->  拦截器元素2 +1', res, to, from);
 *   setTimeout(() => next(res + 1), 2000);
 * });
 *
 *
 * routerInterceptor.push(function(res, to, from, next, exit) {
 *   console.log('--->  拦截器元素3', res, to, from);
 *   exit();
 *   next(res); // 由于形参带next，所以还是要主动调（跟是否调用了exit无关），否则后面的元素或callback始终在等待。
 *   next(res); // 多次next无效，除非allowMultipleNext: true
 *   next(res); // 多次next无效，除非allowMultipleNext: true
 *   console.log('--->  除非这前面return，否则后面的代码还是会执行。这个没意见吧？')
 * });
 *
 * routerInterceptor.push(function() {
 *   console.log('--->  由于前面的拦截器元素调用了exit，所以不会执行到这后面')
 * });
 *
 * routerInterceptor.push(function() {
 *   console.log('--->  2222 由于前面的拦截器元素调用了exit，所以不会执行到这后面')
 * });
 *
 * runInterceptor(routerInterceptor, function(res) {
 *   console.log('--> done', res)
 * }, ['to', 'from'], {});
 */
export function runInterceptor(interceptor, cb, initParams, args, options = {}) {
  let isBreak;
  let NO = {};
  let baseArgCount = ((args && args.length) || 0) + 1;
  const { allowMultipleNext } = options;

  function _break() {
    isBreak = true;
  }

  interceptor.reduce(
    function (preCallback, fn) {
      const fnParamsCount = fn.length;
      let _fn,
        _resolveVal = NO;

      const _next = function (res) {
        if (allowMultipleNext || _resolveVal === NO) {
          _resolveVal = res;
          _fn && _fn(res);
        }
      };

      preCallback(function (res) {
        const _isBreak = isBreak;

        if (!_isBreak) {
          const _args = (args || []).concat([_next, _break]);
          _args.unshift(res === NO ? null : res);

          fn.apply(null, _args);
        }

        if (_isBreak || fnParamsCount <= baseArgCount) {
          _next(res);
        }
      });

      return function (fn) {
        if (_resolveVal !== NO) {
          fn(_resolveVal);
        } else {
          _fn = fn;
        }
      };
    },
    function (fn) {
      fn(initParams);
    }
  )(cb);
}
