import { types } from 'mobx-state-tree';
import { generateDescribeModule } from '@common/utils/describe-helper';
import { safeAssign } from '@common/utils/assignment';
import { createAjaxDescribe } from '@common/utils/createAjaxDescribe';
import Coupon from '@common/models/Coupon/Coupon';
import couponServer from '@/services/coupon';

export function serverDataTranslator(obj, data) {
  if (!obj) return obj;
  data = data ? { ...data } : obj;
  delete data.ajaxDescribe;
  // obj.can_use = data.can_use || data.can_use;
  // obj.full_money = data.full_money || data.full_money;
  return safeAssign(obj, data);
}

const CouponServer = generateDescribeModule('ajaxDescribe', [
  createAjaxDescribe,
  couponServer.fetchCouponTicket,
  function (data) {
    this.updateFromAjaxJson(data);
  },
]);

export default types.compose(
  CouponServer,
  Coupon,
  types.model({}).actions((self) => ({
    updateFromAjaxJson(data) {
      serverDataTranslator(self, data);
    },
  }))
);
