import { reduce } from 'lodash';
import { groupBy, mapValues } from 'lodash';
import { types } from 'mobx-state-tree';
import { FEE_CATEGORYTYPE, Fee, preTaxTypeList, isBeforeTax, isOriginalCost } from './Fee';

export function calculateFeeListSum(feeList, totalFee) {
  return (feeList || []).reduce((acc, fee) => acc + fee.val(totalFee), 0);
}

const PriceList = types
  .model({
    list: types.optional(types.array(Fee), () => []),
  })
  .views((self) => ({
    get source() {
      return self.list;
    },

    // 固定费用
    get fixedValueFeeList() {
      return self.source.filter((fee) => !fee.taxType);
    },
    // 税费
    get taxList() {
      return self.source.filter((fee) => fee.taxType);
    },
    // 税费分类
    get taxMap() {
      return groupBy(self.taxList, 'taxType');
    },
    // 税费支出分类
    get taxCostMap() {
      return mapValues(self.taxMap, function (_, taxType) {
        return self.calculateTaxCost(taxType);
      });
    },
    // 总税费支出
    get totalTaxCost() {
      return reduce(self.taxCostMap, (acc, num) => acc + num, 0);
    },

    // 税前费用
    get fixedValueFeeListForBeforeTax() {
      return self.fixedValueFeeList.filter((fee) => isBeforeTax(fee.feeType));
    },
    get totalFeeForBeforeTax() {
      return calculateFeeListSum(self.fixedValueFeeListForBeforeTax);
    },
    // 税后费用
    get fixedValueFeeListForAfterTax() {
      return self.fixedValueFeeList.filter((fee) => !isBeforeTax(fee.feeType));
    },
    get totalFeeForAfterTax() {
      return calculateFeeListSum(self.fixedValueFeeListForAfterTax);
    },

    // 订单总额（含税，但不含优惠和预付费）
    get total() {
      const originalCostList = self.fixedValueFeeList.filter((fee) => isOriginalCost(fee.feeType));
      return (
        calculateFeeListSum(originalCostList) +
        reduce(
          mapValues(self.taxMap, function (_, taxType) {
            return self.calculateTaxCost(taxType, originalCostList);
          }),
          (acc, num) => acc + num,
          0
        )
      );
    },

    // 订单实付
    get payAmount() {
      return self.totalTaxCost + self.totalFeeForBeforeTax + self.totalFeeForAfterTax;
    },

    // 固定值费用按类目分类
    get categoryTypeMap() {
      return groupBy(self.fixedValueFeeList, 'categoryType');
    },
    // 将categoryTypeMap的other类按照feeType分类
    get feeTypeMapForOtherCategory() {
      return groupBy(self.categoryTypeMap[FEE_CATEGORYTYPE.OTHER], 'feeType');
    },
    // 将feeTypeMapForOtherCategory的preTaxType类按照计税方式分类
    get taxTypeMapForExtraFeeOfOtherCategory() {
      return groupBy(
        preTaxTypeList.reduce((acc, type) => acc.concat(self.feeTypeMapForOtherCategory[type] || []), []),
        'applyTaxType'
      );
    },

    calculateSum: calculateFeeListSum,
    calculateTaxCost(tax, val) {
      if (!self.taxMap[tax]) return 0;
      return self.taxMap[tax].reduce(
        (acc, tax) =>
          acc +
          tax.val(
            typeof val === 'number'
              ? val
              : calculateFeeListSum(
                  (typeof val === 'undefined' ? self.fixedValueFeeListForBeforeTax : val).filter(
                    (fee) => fee.applyTaxType === tax.taxType
                  )
                )
          ),
        0
      );
    },
    calculateTaxCostForFee(feeList) {
      feeList = Array.isArray(feeList) ? feeList : [feeList];
      return feeList.reduce(function (acc, fee) {
        const tax = fee.applyTaxType;
        const val = self.taxMap[tax] ? self.taxMap[tax].reduce((acc, tax) => acc + tax.val(fee.val()), 0) : 0;
        return acc + val;
      }, 0);
    },
  }));

export default PriceList;
