import { types } from 'mobx-state-tree';
import { object } from 'superstruct';
import { SelectValidate } from '../../utils/verification';
import { createValidate, formInputValue } from './util';

// ProtectionPlan
const ProtectionPlan = types.compose(
  types.model({
    vehicle: formInputValue,
    corrosion: formInputValue,
    gap: formInputValue,
  }),
  createValidate(() => ProtectionPlan.verification)
);

ProtectionPlan.verification = object({
  vehicle: SelectValidate.required,
  corrosion: SelectValidate.required,
  gap: SelectValidate.required,
});

export default ProtectionPlan;
