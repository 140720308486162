import { useState, useCallback } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { observer } from 'mobx-react-lite';
import { rootStore } from '@/models';
import Button from '@/components/Button';
import useDestroyRef from '@common/hooks/useDestroyRef';
import './index.less';

const GoogleLoginButton = observer(function GoogleLoginButton({
  className = 'google-login-button full-width',
  children,
  onSuccess,
  onFail,
}) {
  const [actionType, setActionType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const destroyRef = useDestroyRef();
  const loginSuccessHandler = useCallback(() => {
    setIsLoading(false);

    if (!destroyRef.current) {
      setActionType('');
      onSuccess && onSuccess();
    }
  }, [destroyRef, onSuccess]);

  const googleLogin = useGoogleLogin({
    onSuccess: (response) => {
      setIsLoading(true);
      return rootStore.auth.loginWithGoogle(response.access_token).then(loginSuccessHandler, (e) => {
        setActionType('');
        setIsLoading(false);
        onFail && onFail(e);
      });
    },
    onError: (e) => {
      setIsLoading(false);
      onFail && onFail(e);
    },
  });

  return (
    <Button
      className={className}
      border
      loading={isLoading || (actionType === 'google' && rootStore.auth.isHttpLoading)}
      onClick={(e) => {
        setActionType('google');
        googleLogin();
      }}
    >
      {children || (
        <div className="flex-grid">
          <img src={require('@/static/google.png').default} alt="" />
          <span className="color-main">Continue with Google</span>
        </div>
      )}
    </Button>
  );
});

export default GoogleLoginButton;
