import React, { isValidElement } from 'react';
import { makeObservable, observable, computed, action } from 'mobx';
import { forceToArray } from '@/app/translator';
import { UIController } from '../../frontend-common/controller/UIController';
import Toast from '@common/components/Toast';
import Spinner from '@common/components/Spinner';
import Button from '@/components/Button';
import * as classNames from 'classnames';

function checkIsInBody(el) {
  return el && document.body.contains(el);
}

let uuid = 0;

class ToastController extends UIController {
  uuid = 0;

  constructor() {
    super();

    this.setElm = this.setElm.bind(this);

    makeObservable(this, {
      uuid: observable,
      children: computed,
      state: computed,
      show: action,
      hide: action,
      hideNow: action,
    });

    this.init();
  }

  get state() {
    return {
      children: this.children,
      theme: this.theme,
      closeBtn: this.closeBtn,
      key: this.uuid,
      hide: this.hide.bind(this),
      show: this.show.bind(this),
    };
  }

  get children() {
    // eslint-disable-next-line no-unused-expressions
    this.uuid;
    return this.$children;
  }

  /**
   * 弹出toast
   * @param {React.Children|Function|String} children 一个react渲染元素或者字符串。函数时为render props，必须返回一个react渲染元素。
   * @param {Object} config           配置
   * @param {Number} config.duration  持续时间，为0时不会自动关闭。默认3000ms。
   * @param {String} config.theme     主题。查阅Toast组件了解更多。
   * @param {Boolean} config.closeBtn 是否需要右上角的关闭按钮
   * @example
   * // 默认3s后关闭
   * toast.show('内容内容');
   *
   * // 自己手动控制关闭
   * toast.show(({ hide }) => (
   *   <div className="flex-grid flex--col">
   *     <p className="mt20">Your initialization</p>
   *     <Button className="mt20" onClick={hide}>
   *       Determine
   *     </Button>
   *   </div>
   * ), {duration: 0});
   */
  show(children, config = {}) {
    let { duration, theme, closeBtn } = config;

    clearTimeout(this._timer);
    clearTimeout(this._hideTimer);
    this.hideNow();

    this.uuid = ++uuid;
    this.theme = theme;
    this.closeBtn = closeBtn;
    this.$children =
      !children || typeof children === 'function' || isValidElement(children) ? children : <div>{children}</div>;

    duration = duration === 0 ? 0 : duration || 6000;

    if (duration) {
      this._hideTimer = setTimeout(() => this.hide(), duration);
    }
  }

  shortcut(options, config) {
    let { containerClassName, icon, title, content, button, renderButton } = options;
    content = typeof content === 'string' ? [content] : forceToArray(content);

    return this.show(
      ({ hide }) => (
        <div className={classNames('flex-grid flex--col', containerClassName)}>
          {icon && <img className="toast__icon" src={icon} alt="" />}
          {title && <h1 className="toast__title">{title}</h1>}
          {content.map((txt, i) => (
            <div key={i} className="toast__p">
              {txt}
            </div>
          ))}
          {(button && (
            <Button className="toast__btn c-button--medium" onClick={hide}>
              {button}
            </Button>
          )) ||
            (renderButton && renderButton({ hide }))}
        </div>
      ),
      config
    );
  }

  hide() {
    const { $el } = this;

    if (checkIsInBody($el)) {
      $el.classList.add('c-toast--hide');
      this._timer = setTimeout(this.hideNow.bind(this), 300);
    }
  }

  hideNow() {
    if (checkIsInBody(this.$el)) {
      this.uuid = 0;
      this.setElm(null);
    }
  }

  setElm(el) {
    this.$el = el;
  }

  render() {
    return this.state.key ? <Toast {...this.state} toastRef={this.setElm} /> : null;
  }
}

class SpinnerController extends ToastController {
  show() {
    clearTimeout(this._timer);
    clearTimeout(this._hideTimer);
    this.hideNow();

    this.uuid = ++uuid;
    this.theme = 'fade';
    this.$children = (
      <div>
        <Spinner />
      </div>
    );
  }
}

const toast = new ToastController();
const spinner = new SpinnerController();

export { ToastController, spinner, toast };
export default toast;
