import { get } from 'lodash';
import { types, getParent, getEnv } from 'mobx-state-tree';
import Mdsp from './Mdsp';
import { getFeesValue } from './Fee/Fee';

export const carStatusOptions = [
  { value: 0, label: 'Initial' }, // 起始状态
  { value: 10, label: 'In stock' }, // 已入库
  { value: 20, label: 'Coming soon' }, // 待上架
  { value: 30, label: 'Selling' }, // 销售中
  { value: 40, label: 'Sold pending' }, // 已预定
  { value: 50, label: 'In process' }, // 手续中
  { value: 60, label: 'Sold' }, // 已售出
];

const ReportImage = types.compose(types.model({ url: '' }));

const CarField = types
  .model({
    id: 0,
    uuid: '',
    name: '',
    bodytype: '',
    carfaxlink: '',
    conditions: '',
    description: '',
    discount: 0,
    doors: '',
    drivetrain: '',
    engine: '',
    exteriorcolours: '',
    fueltype: '',
    hightlightfeature: '',
    horsepower: '',
    imgurl: '',
    images_small: '',
    images_main: '',
    images_all: types.optional(types.array(types.string), []),
    inspectdate: '', // sellOut: inspectdate === 'true'
    inspector: '',
    interiorcolours: '',
    isbooked: false,
    issold: false,
    isOneOwner: 0, // 一手车
    noAccident: 0, // 无事故
    make: '',
    mdspprice: 0,
    mechanicreport: '',
    mileage: '',
    model: '',
    owner: '',
    printedkeys: '',
    powerType: 0,
    prodyear: types.optional(types.union(types.string, types.number), 0),
    saleprice: 0,
    specificationreport: '',
    status: 0,
    stocknumber: '',
    torque: '',
    transmission: '',
    trim: '',
    vrlink: '',
    warranty: '',
    pv: 0,
    like: 0,
    report_images: types.optional(types.array(ReportImage), []),
    exteriorColor: '',
    interiorColor: '',

    ev: types.optional(types.frozen(), {}),
    maintenance: types.optional(types.frozen(), {}),
  })
  .views((self) => ({
    get isEv() {
      return self.powerType === 1;
    },
    get trimTitle() {
      return self.name; //  + (self.trim ? ' ' + self.trim : '');
    },
    get coverImageSmall() {
      return self.images_small; // `${network.CDNADDR}/${self.uuid}/small.png`;
    },
    get coverImage() {
      return self.images_main; // `${network.CDNADDR}/${self.uuid}/mainpic.png`;
    },
    get driveTrainValue() {
      return self.isEv ? get(self.ev, 'specifications.driveTrain') || '' : self.drivetrain;
    },
    get motorValue() {
      return (self.isEv && get(self.ev, 'specifications.motor')) || '';
    },
    get discountPriceStr() {
      return self.discountPrice / 100;
    },
    get discountPrice() {
      return self.saleprice - self.discount;
    },
    get originalPrice() {
      return self.saleprice;
    },
    get originalPriceStr() {
      return self.originalPrice / 100;
    },
    get isComingSoon() {
      return self.status < 30;
    },
    get isBooked() {
      return self.status >= 40 && self.status < 50;
    },
    get isSold() {
      return self.status >= 50;
    },

    get canBuy() {
      return !(self.status !== 30 || self.sellOut);
      // return !(self.issold || self.isbooked || self.inspectdate === 'true');
    },

    get mileageStr() {
      return self.mileage + 'km';
    },

    get INIT_JSON() {
      return INIT_JSON;
    },
  }))
  .actions((self) => ({
    toData() {
      const res = {
        ...self.toJSON(),
      };

      delete res.isAjaxLoading;
      delete res.ajaxError;
      return res;
    },
  }));

const Car = types.compose(
  CarField,
  types
    .model({
      // 附加model
      mdsp: types.optional(Mdsp, {}),
    })
    .views((self) => ({
      get omnibusFee() {
        let rootStore;
        try {
          rootStore = getParent(self);
        } catch (e) {
          rootStore = getEnv(self);
        }

        const { feeList } = rootStore;
        return self.discountPrice + getFeesValue(feeList ? feeList.globalFees : [], self.discountPrice);
      },
      get totalLienAmount() {
        let rootStore;
        try {
          rootStore = getParent(self);
        } catch (e) {
          rootStore = getEnv(self);
        }

        const { feeList } = rootStore;

        return (
          self.omnibusFee -
          (feeList
            ? getFeesValue(
                feeList.globalFees.filter((fee) => !fee.isCanFinance),
                self.discountPrice
              )
            : 0)
        );
      },
    }))
    .actions((self) => {
      return {
        $assign(res) {
          Object.keys(res).forEach((key) => {
            if (key in self.INIT_JSON) {
              self[key] = res[key];
            }
          });
        },

        updateFromAjaxJson(json) {
          Object.keys(self.INIT_JSON).forEach((key) => {
            if (key in json) {
              self[key] = json[key];
            } else {
              self[key] = self.INIT_JSON[key];
            }
          });
        },
      };
    })
);

export const INIT_JSON = Car.create().toJSON();

export default Car;
