import { types } from 'mobx-state-tree';
import { object } from 'superstruct';
import { PositiveNumber, SelectValidate, StringOrNumber } from '../../utils/verification';
import { createValidate, formInputValue } from './util';

// ResidentionInformation
const ResidentionInformation = types.compose(
  types.model({
    duration: formInputValue,
    previousLivedAddress: formInputValue,
    own: 0,
    monthly: formInputValue,
    insuranceNumber: formInputValue,
    marital: formInputValue,
    customMarital: formInputValue,
  }),
  createValidate(() => ResidentionInformation.verification)
);

ResidentionInformation.verification = object({
  duration: StringOrNumber.required,
  previousLivedAddress: StringOrNumber.required,
  own: SelectValidate.required,
  monthly: PositiveNumber.required,
  insuranceNumber: StringOrNumber.optional,
  marital: StringOrNumber.optional,
  customMarital: StringOrNumber.optional,
});

export default ResidentionInformation;
