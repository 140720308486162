import dayjs from 'dayjs';
import { get, find, isEqual } from 'lodash';

export function getOption(options, value, valueKey = 'value', useEqual) {
  return find(options || [], useEqual ? (val) => isEqual(val[valueKey], value) : { [valueKey]: value }) || '';
}

export function getValue(option, valueKey = 'value') {
  return get(option, valueKey);
}

export function formatDate(obj) {
  if (!obj || typeof obj !== 'string') return '';

  const date = typeof obj === 'string' ? obj : new Date(obj.year, obj.month - 1, obj.day);

  return dayjs(date).format('YYYY-MM-DD');
}

export function getFieldLabel(options, value, labelKey, valueKey, useEqual) {
  return get(getOption(options, value, valueKey, useEqual), labelKey || 'label') || '';
}
