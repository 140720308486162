import { types } from 'mobx-state-tree';
import DataAlign from './common/DataAlign';
import { omit } from 'lodash';

const NO = {};

const SharedMemory = types.compose(
  DataAlign.debug,
  types
    .model({
      value: types.frozen(),
    })
    .actions((self) => ({
      afterCreate() {
        self.$registeredChannels && self.$registeredChannels('root-shared-memory');
        self.$tryDataAlign();
      },
      get(key) {
        return (self.value || NO)[key];
      },
      set(key, val) {
        // DataAlign只对$assign生效
        self.$assign({
          value: {
            ...self.value,
            [key]: val,
          },
        });
      },
      remove(key) {
        if (!self.value) return;
        self.$assign({
          value: omit(self.value, [key]),
        });
      },
    }))
);

export default SharedMemory;
