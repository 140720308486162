import PropTypes from 'prop-types';
import { useEventCallback } from '@common/hooks/official-hack';
import classNames from 'classnames';
import Icon from '@/widgets/Icon';
import './style.less';

const Checkbox = function ({
  className,
  trueLabel,
  falseLabel,
  value,
  normalClass,
  activeClass,
  children,
  onChange,
  onClick,
  inputRef,
}) {
  const isChecked = value === trueLabel;
  const updateVal = useEventCallback(
    function () {
      onChange && onChange(value === trueLabel ? falseLabel : trueLabel, { trueLabel, falseLabel });
      onClick && onClick();
    },
    [onChange, value, trueLabel, falseLabel]
  );

  return (
    <div
      className={classNames('c-checkbox pos-r flex cursor-pointer', { 'c-checkbox--checked': isChecked }, className)}
      onClick={updateVal}
    >
      <div className="c-checkbox__icon">
        <Icon
          className={classNames([value === trueLabel ? activeClass : normalClass])}
          name={isChecked ? 'checked' : 'unchecked'}
        />
      </div>
      <span className="c-checkbox__label flex flex--middle">{children}</span>
      <input ref={inputRef} type="checkbox" aria-hidden="true" className="c-checkbox__original" />
    </div>
  );
};

Checkbox.propTypes = {
  trueLabel: PropTypes.any,
  falseLabel: PropTypes.any,
  value: PropTypes.any,
  normalClass: PropTypes.string,
  activeClass: PropTypes.string,
};

Checkbox.defaultProps = {
  trueLabel: true,
  falseLabel: false,
};

export default Checkbox;
