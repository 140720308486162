import { useState, useRef, useLayoutEffect, useEffect } from 'react'
import { useStaticRef } from './useStaticRef';

/**
 * 类似于vue的watch API。
 * @param {Array} val       监听的依赖项数组。即一个包含多个state/prop的数组。
 * @param {Function} fn        值变化时的回调
 * @param {Object} optionsOrFn 配置对象，或返回一个返回配置对象的函数
 * @param {Boolean} optionsOrFn.immediate 回调是否在初始化时立即调用一次
 * @param {Boolean} optionsOrFn.useLayout 回调是否在useLayoutEffect下执行，默认在useEffect下执行。
 * @example
 * useWatch([index, props.show], function([index, show], [oldIndex, oldShow]) {
 *  return {
 *    index,
 *    show,
 *    oldIndex,
 *    oldShow
 *  }
 * })
 */
export default function useWatch(val, fn, optionsOrFn) {
  const [{ immediate, useLayout }] = useState(optionsOrFn || {});
  const firstRef = useRef();
  const fnRef = useStaticRef(fn);
  const prevRef = useRef([]);

  (useLayout ? useLayoutEffect : useEffect)(() => {
    if (immediate || firstRef.current) {
      fnRef.current(val, prevRef.current);
    }

    prevRef.current = val;
    firstRef.current = true;
  }, val);
}
