import classNames from 'classnames';
import React, { useState, useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';
import { get } from 'lodash';
import '@/libs/init-dayjs';
import dayjs from 'dayjs';
import { getFieldLabel, getOption, getValue } from '@common/utils/helper/form-control-helper';
import { Row, Col } from '@common/components/Grids';
import { Radio, RadioGroup } from '@/components/Radio';
import Select from 'react-select';
import { Calendar } from '@sentisso/react-modern-calendar-datepicker';
import TimeKeeper from 'react-timekeeper';
import TimeList from './TimeList';
import Popper from '@common/components/Popper';
import Modal from '@common/components/Modal';
import Checkbox, { CheckboxGroup } from '@/components/Checkbox';
import { Counter } from '@/components/Counter';
import { dateToReactModernCalendarDatepicker, getDateForReactModernCalendarDatepicker } from './date-helper';
import '@sentisso/react-modern-calendar-datepicker/lib/DatePicker.css';

// format: 用于展示在input的格式
// valueFormat: 用于转换到value的格式，无则返回原对象
function datepickerFactory(hasTime, TimeComponent) {
  return {
    getEventValue: ORIGINAL,
    suffixIcon: 'select',
    render({
      ref,
      mode,
      format = hasTime ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY',
      forMobile,
      placeholder,
      inputClassName,
      valueFormat,
      timeProps,
      renderInput,
      ...props
    }) {
      let date;
      let transferPipe = {};
      let { value, onChange } = props;
      let transferPipeValue = value;

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const valRef = useRef();

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [show, setShow] = useState(false);

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [displayTime, setDisplayTime] = useState(false);

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const changeHandler = useCallback(() => {
        let getValue = ORIGINAL;
        if (valueFormat) {
          getValue = (e) => dayjs(getDateForReactModernCalendarDatepicker(e)).format(valueFormat);
        }

        onChange && onChange(getValue(valRef.current));
      }, [onChange, valueFormat]);

      const isDate = value instanceof Date || typeof value === 'number' || typeof value === 'string';

      if (value) {
        if (valueFormat || isDate) {
          date = dayjs(value, isDate ? undefined : valueFormat);

          if ('value' in props) {
            transferPipe.value = transferPipeValue = dateToReactModernCalendarDatepicker(date);
          }
        } else {
          date = getDateForReactModernCalendarDatepicker(value);
        }
      }

      if (mode === 'read') {
        return date ? date.format(format) : '';
      }

      const scope = {
        className: classNames(inputClassName),
        placeholder: placeholder,
        value: date ? date.format(format) : '',
        onClick: () => setShow(true),
      };

      const input = renderInput ? renderInput(scope, ref) : <input ref={ref} type="text" readOnly {...scope} />;

      const content = !displayTime ? (
        <Calendar
          calendarClassName="no-linefeed responsive-modern-calendar"
          shouldHighlightWeekends
          {...props}
          {...transferPipe}
          onChange={(e) => {
            valRef.current = e;

            if (hasTime) {
              setDisplayTime(true);
            } else {
              changeHandler();
              setShow(false);
            }
          }}
        />
      ) : (
        <TimeComponent
          time={{
            hour: get(transferPipeValue, 'hour') || 0,
            minute: get(transferPipeValue, 'minute') || 0,
          }}
          hour24Mode={true}
          switchToMinuteOnHourSelect={true}
          onDoneClick={(e) => {
            Object.assign(valRef.current, {
              hour: e.hour,
              minute: e.minute,
            });

            changeHandler();
            setDisplayTime(false);
            setShow(false);
          }}
          {...timeProps}
        />
      );

      if (forMobile) {
        return (
          <>
            {input}
            {ReactDOM.createPortal(
              <Modal
                className="dialog--slide calendar-dialog"
                containerClass="flex flex--middle"
                title={null}
                value={show}
                floatHead
                noClose
                backdropClose
                onChange={setShow}
              >
                {content}
              </Modal>,
              document.body
            )}
          </>
        );
      } else {
        return (
          <Popper contentClassName="calendar-popper-content" behavior="none" value={show} onChange={setShow}>
            {input}
            <div slot="content">{content}</div>
          </Popper>
        );
      }
    },
  };
}

function inputNumberRender({ inputClassName, fallbackValue, mode, ref, ...props }) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const valueRef = useRef('');
  const onChange = props.onChange;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const onBlur = useCallback(
    function () {
      const value = valueRef.current;
      const _fallbackValue = typeof fallbackValue === 'undefined' ? '' : fallbackValue;

      if (value === '' || isNaN(+value)) {
        onChange(createInputEvent(_fallbackValue));
      } else if (value !== +value) {
        onChange(createInputEvent(+value));
      }
    },
    [fallbackValue, onChange]
  );

  valueRef.current = props.value;

  if (mode === 'read') {
    return props.value;
  }
  return <input className={classNames(inputClassName)} type="number" {...props} onBlur={onBlur} />;
}

const ORIGINAL = (val) => val;
const getDomEventValue = (e) => e.target.value;
const createInputEvent = (value) => ({ target: { value } });

const presetControl = {
  select: {
    getEventValue: ORIGINAL,
    suffixIcon: 'drop-down',
    render(props) {
      let { valueKey, mode, renderRead, ...inputProps } = props;
      let transferPipe = {};

      valueKey = 'valueKey' in props ? valueKey : '';

      if (valueKey) {
        transferPipe = {
          onChange: (e) => {
            props.onChange(getValue(e, valueKey));
          },
        };

        if ('value' in props) {
          transferPipe.value = getOption(props.options, props.value, valueKey, true);
        }
      }

      if (mode === 'read') {
        return renderRead
          ? renderRead(getOption(props.options, inputProps.value))
          : getFieldLabel(props.options, inputProps.value);
      }

      // menuIsOpen={true}
      return (
        <Select
          className="c-select"
          menuPortalTarget={document.body}
          isSearchable={false}
          openMenuOnFocus
          classNamePrefix="c-select"
          styles={{ menuPortal: (provided) => ({ ...provided, zIndex: '100 !important' }) }}
          {...inputProps}
          {...transferPipe}
        />
      );
    },
  },

  date: datepickerFactory(),
  datetime: datepickerFactory(true, TimeKeeper),
  datetime2: datepickerFactory(true, TimeList),

  radio: {
    noControlWrapper: true,
    getEventValue: ORIGINAL,
    render({ options, radioRow, radioCol, mode, ...inputProps }) {
      if (mode === 'read') {
        return getFieldLabel(options, inputProps.value);
      }

      return (
        <div className="full-width overflow">
          <Row className="radio-group-row" {...radioRow}>
            <RadioGroup {...inputProps}>
              {({ buildChildProps }) =>
                options.map((res, i) => {
                  if (typeof res !== 'object') {
                    res = { label: res, value: res };
                  }

                  const props = buildChildProps(res.value, i);

                  return (
                    <Col className="radio-col" {...(res.radioCol || radioCol)} key={res.value}>
                      <Radio {...props}>{res.labelContent ? res.labelContent(props) : res.label}</Radio>
                    </Col>
                  );
                })
              }
            </RadioGroup>
          </Row>
        </div>
      );
    },
  },

  checkbox: {
    getEventValue: ORIGINAL,
    noControlWrapper: true,
    containerClassName: 'c-form-item-wrap--checkbox',
    render({ checkboxContent, ref, ...inputProps }) {
      return (
        <Checkbox className="flex--middle full-width" inputRef={ref} {...inputProps}>
          {checkboxContent}
        </Checkbox>
      );
    },
  },

  checkboxGroup: {
    noControlWrapper: true,
    getEventValue: ORIGINAL,
    render({ options, radioRow, radioCol, mode, ...inputProps }) {
      if (mode === 'read') {
        return getFieldLabel(options, inputProps.value);
      }

      return (
        <div className="full-width overflow">
          <Row className="radio-group-row" {...radioRow}>
            <CheckboxGroup {...inputProps}>
              {({ buildChildProps }) =>
                options.map((res, i) => {
                  if (typeof res !== 'object') {
                    res = { label: res, value: res };
                  }

                  const props = buildChildProps(res.value, i);

                  return (
                    <Col className="radio-col" {...(res.radioCol || radioCol)} key={res.value}>
                      <Checkbox {...props}>{res.labelContent ? res.labelContent(props) : res.label}</Checkbox>
                    </Col>
                  );
                })
              }
            </CheckboxGroup>
          </Row>
        </div>
      );
    },
  },

  inputNumber: {
    render: inputNumberRender,
    getEventValue: getDomEventValue,
  },

  moneyPenny: {
    render: inputNumberRender,
    parser: (val) => (val === '' ? val : val / 100),
    getEventValue(e) {
      const val = getDomEventValue(e);
      const valNum = +val;
      return val === '' ? val : valNum * 100;
    },
  },
  counter: {
    getEventValue: ORIGINAL,
    suffixIcon: 'select',
    render({ mode, ...props }) {
      if (mode === 'read') {
        return props.value;
      }
      return <Counter {...props} />;
    },
  },

  textarea: {
    render({ inputClassName, mode, ...props }) {
      if (mode === 'read') {
        return props.value;
      }

      return <textarea className={classNames(inputClassName)} {...props} />;
    },
    getEventValue: getDomEventValue,
  },

  default: {
    render({ inputClassName, mode, ...props }) {
      if (mode === 'read') {
        return props.value;
      }

      return <input className={classNames(inputClassName)} type="text" {...props} />;
    },
    getEventValue: getDomEventValue,
  },
};

export { presetControl };
