import PropTypes from 'prop-types';
import React from 'react';
import '../../style/base/_grids.less';
import './style.css';

const numReg = /^\d+$/;
const singleNumReg = /-?\d+(\.?\d+)?/;
function arithmetic(cssVal, fn) {
  const val = parseFloat(cssVal);
  return (cssVal + '').replace(singleNumReg, fn(val));
}

function divided(cssVal, ratio) {
  return arithmetic(cssVal, (val) => val / ratio);
}

export function Row(props) {
  const { tag, reverse, style } = props;
  const Wrapper = tag || 'div';
  const cProps = { space: divided(props.space, 2) };
  const space = divided(props.space, -2);

  return (
    <Wrapper
      className={'pure-g ' + (props.className || '') + (reverse ? ' pure-g--reverse' : '')}
      style={{ marginLeft: space, marginRight: space, ...style }}
    >
      {React.Children.map(props.children, (item) => (item ? React.cloneElement(item, cProps) : null))}
    </Wrapper>
  );
}

Row.propTypes = {
  tag: PropTypes.string,
  middle: PropTypes.bool,
  reverse: PropTypes.bool,
};

export function Col(props) {
  const { tag, space, omnibearingSpace, style } = props;
  const Wrapper = tag || 'div';
  const className = ['size', 'sm', 'md', 'lg', 'xl']
    .map((key) => {
      const val = props[key];
      return val ? `pure-u${key === 'size' ? '' : '-' + key}-${val}${numReg.test(val) ? '-24' : ''}` : '';
    })
    .filter(Boolean)
    .concat(['pure-u', props.className])
    .join(' ');

  return (
    <Wrapper
      className={className}
      style={{
        ...(omnibearingSpace ? { padding: space } : { paddingLeft: space, paddingRight: space }),
        ...style,
      }}
    >
      {props.children}
    </Wrapper>
  );
}
