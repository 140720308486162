import { findIndex } from 'lodash';

export function numberWithCommas(x) {
  x = x.toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x)) x = x.replace(pattern, '$1,$2');
  return x;
}

export function leadingZero(n) {
  const str = n.toString();
  return str[1] ? str : `0${str}`;
}

function _durationToString(prefixZero = true, zeroPlaceholder = true, separator = ':') {
  const self = this;
  let foundStart = zeroPlaceholder;

  return self.units.reduce((acc, unit) => {
    let val = self[unit.name];
    val = val ? val : foundStart ? 0 : '';

    if (val !== '') {
      foundStart = true;
      acc += (acc ? separator : '') + (prefixZero ? leadingZero(val) : val);
    }

    return acc;
  }, '');
}

/**
 * 格式化一个时长信息
 * @param {Number} duration 秒
 * @param {String} startUnit 起始单位
 * @returns 可能包含day, hour, minute, second等（试startUnit而定），和toString方法输出字符串。
 * @example
 * formatDuration(60*60*24*2 + 60*2 + 6, 'hour'); // => {hour: 48, minute: 2, second: 6}
 */
export function formatDuration(duration, startUnit = 'hour') {
  const allUnits = [
    { name: 'day', value: 24 * 60 * 60 },
    { name: 'hour', value: 60 * 60 },
    { name: 'minute', value: 60 },
    { name: 'second', value: 1 },
  ];

  const units = allUnits.slice(findIndex(allUnits, { name: startUnit }));
  const result = units.reduce(
    function (acc, unit, index) {
      if (index === 0) {
        acc[unit.name] = Math.floor(duration / unit.value);
      } else {
        acc[unit.name] = Math.floor((duration % units[index - 1].value) / unit.value);
      }

      return acc;
    },
    {
      units,
      toString: _durationToString,
    }
  );

  return result;
}
