import { types } from 'mobx-state-tree';

const Insurance = types.model({
  policy: '',
  effectiveDate: types.maybe(types.string),
  expiryDate: types.maybe(types.string),
  liabilityCoverage: '',
  comprDeductible: 0,
  collisionDeductible: 0,
});

export default Insurance;
