import { flattenDeep } from './flatten';

/**
 * @example
 * toNamePath([undefined, null, 0], 'name'); // [0, 'name']
 */
export function toNamePath(formField, name) {
  return flattenDeep([formField, name]).filter((str) => str !== '' && typeof str !== 'undefined' && str !== null);
}

/**
 * 设置/计算上下文值。相当于lodash的set/get
 * @param  {*}             context 上下文对象。
 * @param  {Array|String}  exp     字段表达式，以'.'和'[\d+]'分割成字段的访问堆栈
 * @param  {*}             [val]   要设置的值，不传时为get模式。
 *
 * @example
 *  var data = {
 *    asd: {qwe: 123}
 *  };
 *
 *  parseExp(data, 'asd.qwe', 789);
 */
export function parseExp(obj, exp, val) {
  let context = obj;
  let last_key;
  const isSet = arguments.length === 3;
  const chain = Array.isArray(exp)
    ? flattenDeep(exp)
    : exp
        .replace(/^\[(\d+)\]/, '$1')
        .replace(/\[(\d+)\]/g, '.$1')
        .split('.');

  if (isSet) {
    last_key = chain[chain.length - 1];
  }

  for (let i = 0; i < chain.length - (isSet ? 1 : 0); i++) {
    if (!context) return;
    context = context[chain[i]];
  }

  if (isSet) {
    if (context) {
      context[last_key] = val;
    }
    return obj;
  } else {
    return context;
  }
}

/**
 * 对一个深层次的路径进行赋值。
 * @param {Object}       obj
 * @param {Array|String} name    赋值的路径
 * @param {*}            value   要设置的值
 * @param {Boolean}      iteration 是否使用Object.assign
 * @example
 * const obj = {a: {b: [{c: 123, d: 'abc'}]}};
 * deepAssign(obj, ['a', 'b', 0, 'c'], 456); // {a: {b: [{c: 456, d: 'abc'}]}}
 * deepAssign(obj, ['a', 'b', 0], { qwe: '-----' }, true); // {a: {b: [{c: 456, d: 'abc', qwe: '-----'}]}}
 * deepAssign(obj, ['a', 'b', 0], { qwe: '-----' }); // {a: {b: [{ qwe: '-----'}]}}
 */
export function deepAssign(obj, name, value, iteration, options) {
  let { get, set } = options || {};
  if (!get) {
    get = function (key) {
      return obj[key];
    };
  }

  if (!set) {
    set = function (val) {
      Object.assign(obj, val);
    };
  }

  const path = toNamePath(name);
  const key = path[0];
  const hasKey = !!path.length;
  const secondPath = path.slice(1);
  const firstObj = get(key);

  function isObj(obj) {
    return typeof obj === 'object' && !Array.isArray(obj);
  }

  if (secondPath.length) {
    const valueWrap = parseExp(
      firstObj,
      secondPath,
      iteration && isObj(parseExp(firstObj, secondPath)) ? { ...parseExp(firstObj, secondPath), ...value } : value
    );

    set(hasKey ? { [key]: valueWrap } : valueWrap);
  } else {
    value = iteration && isObj(firstObj) ? { ...firstObj, ...value } : value;
    set(
      hasKey
        ? {
            [key]: value,
          }
        : value
    );
  }
}

/**
 * 将data与obj的交集成员合并到obj中
 * @param {Object} obj
 * @param {Object} data
 * @returns { Object } obj
 */
export function safeAssign(obj, data) {
  Object.keys(data).forEach((key) => {
    if (key in obj) {
      obj[key] = data[key];
    }
  });

  return obj;
}
