/**
 * 宿主环境的UI信息，这里支持将信息挂在state-tree，不直接访问设备API。
 * 应该在应用层访问宿主环境进行更新。
 */
import { types } from 'mobx-state-tree';

const MatchMedia = types.model({
  sm: false,
  md: false,
  lg: false,
  xl: false,
});

const UiStore = types
  .model({
    width: 0,
    height: 0,
    matchMedia: types.optional(MatchMedia, {}),
  })
  .views((self) => ({
    get isMobile() {
      return !self.matchMedia.sm;
    },
  }))
  .actions((self) => {
    return {
      updateFromViewport({ width, height, matchMedia }) {
        self.width = width;
        self.height = height;
        self.matchMedia = matchMedia;
      },
    };
  });

export default UiStore;
