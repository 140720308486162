import { find } from 'lodash';
import { types, getEnv, getParent } from 'mobx-state-tree';
import { Fee } from '../Fee/Fee';
import Car from '../Car';
import CouponCode from '../CouponCode';
import Coupon from '../Coupon/Coupon';
import Mdsp from '../Mdsp';
import TradeIn from './TradeIn';
import ProtectionPlan from './ProtectionPlan';
import Settlement from './Settlement';
import Buyer from './Buyer';
import ResidentionInformation from './ResidentionInformation';
import EmploymentHistory from './EmploymentHistory';
import Delivery from './Delivery';
import Person from './Person';
import Hold from './Hold';
import LoanScheme from './LoanScheme';
import LicenseInfo from './LicenseInfo';
import Insurance from './Insurance';
import { Package } from '../CarPackage';
import FinanceApplicationFormFeeTemplate from './FinanceApplicationFormFeeTemplate';
import AssignmentHelper from '../utils/AssignmentHelper';
import ExtendedWarrantyFees from './ExtendedWarrantyFees';

const FinanceApplicationFormProtectionFee = types.model({}).views((self) => ({
  // 必须包含systemSetting，用于读取关于Extended Warranty系统设置
  get env() {
    try {
      return getParent(self);
    } catch (e) {
      return getEnv(self);
    }
  },

  // 保护计划相关费用
  get protectionFeeList() {
    const { protection, coverage, gap } = self.env.systemSetting;
    const protectionPlanVo = self.protectionPlan;
    const { name: vehicleFeeName, price: vehicleFee } = find(protection, { id: protectionPlanVo.vehicle }) || {
      price: 0,
    };
    const { name: corrosionFeeName, price: corrosionFee } = find(coverage, { id: protectionPlanVo.corrosion }) || {
      price: 0,
    };
    const { name: gapFeeName, price: gapFee } = find(gap, { id: protectionPlanVo.gap }) || { price: 0 };

    return [
      vehicleFeeName &&
        Fee.create({
          name: 'Vehicle Protection Plan',
          desc: vehicleFeeName,
          value: vehicleFee,
          canFinance: 1,
        }),
      corrosionFeeName &&
        Fee.create({
          name: 'Corrosion and Perforation Coverage',
          desc: corrosionFeeName,
          value: corrosionFee,
          canFinance: 1,
        }),
      gapFeeName &&
        Fee.create({
          name: 'Gap Insurance',
          desc: gapFeeName,
          value: gapFee,
          canFinance: 1,
        }),
    ].filter((item) => item);
  },
}));

const FinanceApplicationFormBase = types
  .model({
    salesDirector: types.maybe(types.number),
    email: '', // 当前订单绑定的账户
    buyer: types.optional(Buyer, {}),
    coBuyer: types.optional(types.array(Buyer), []),
    coupon: types.optional(CouponCode, {}), // 本单使用的优惠券
    couponTicket: types.optional(Coupon, {}), // 本单使用的优惠券
    tradeIn: types.optional(TradeIn, TradeIn.create().toJSON()), // 置换信息
    protectionPlan: types.optional(ProtectionPlan, ProtectionPlan.create().toJSON()), // 延保信息
    extendedWarrantyFees: types.optional(ExtendedWarrantyFees, ExtendedWarrantyFees.create().toJSON()),
    settlement: types.optional(Settlement, Settlement.create().toJSON()), // 付款信息
    delivery: types.optional(Delivery, Delivery.create().toJSON()), // 提货（交付）信息
    vehicle: types.maybe(Car), // 车辆信息
    hold: types.optional(Hold, {}), // 订金信息
    mdsp: types.optional(Mdsp, {}), // 月供信息
    loanScheme: types.optional(LoanScheme, { list: [{}, {}] }), // 贷款方案信息
    licenseInfo: types.optional(LicenseInfo, {}), // 驾驶证相关信息
    insurance: types.optional(Insurance, {}), // 保险信息
    extraCharges: types.optional(types.array(Fee), []), // 系统费用快照
    packageVo: types.optional(Package, {}), // 套餐快照信息
  })
  .actions((self) => ({
    loadCoupon(code) {
      return self.coupon.load(code);
    },
    loadCouponTicket(id) {
      return self.couponTicket.load(id);
    },
    setCouponTicket(data) {
      return self.couponTicket.updateFromAjaxJson(data);
    },

    // deepAssign(path, data, iteration) {
    //   deepAssign(self, path, data, iteration, {
    //     get(key) {
    //       return clone(self[key]);
    //     },
    //   });
    //   self.updateMdsp();
    // },

    // setFrom(key, data) {
    //   self.deepAssign(key, data);
    //   self.updateMdsp();
    // },
    // mergeFrom(key, data) {
    //   self.deepAssign(key, data, true);
    //   self.updateMdsp();
    // },

    updateMdsp() {
      self.mdsp.from({
        downpay: self.settlement.downPayment,
        loanmonth: self.settlement.financeTerm,
        interestrate: self.settlement.interestrate,
      });
    },

    addCoBuyer() {
      self.coBuyer.push(...arguments);
    },
    removeCoBuyer(item) {
      const index = self.coBuyer.indexOf(item);
      if (index !== -1) {
        self.coBuyer.splice(index, 1);
      }
    },

    toValue() {
      return JSON.parse(
        JSON.stringify({
          ...self.toJSON(),
          protectionFeeList: self.protectionFeeList.map((item) => item.toJSON()),
          mdsp: undefined,
        })
      );
    },
  }));

const FinanceApplicationForm = types.compose(
  AssignmentHelper,
  FinanceApplicationFormFeeTemplate,
  FinanceApplicationFormBase,
  FinanceApplicationFormProtectionFee
);

const FinanceApplicationFormDetail = types.compose(
  AssignmentHelper,
  FinanceApplicationFormFeeTemplate,
  FinanceApplicationFormBase,
  types.model({
    protectionFeeList: types.optional(types.array(Fee), []),
  })
);

export {
  TradeIn,
  ProtectionPlan,
  Settlement,
  Buyer,
  ResidentionInformation,
  EmploymentHistory,
  Delivery,
  Person,
  FinanceApplicationFormDetail,
};

export default FinanceApplicationForm;
