import is from './is';

/**
 * 匹配特定选择器且离当前元素最近的祖先元素（也可以是当前元素本身）
 * @param {HTMLElement} node dom node
 * @param {String|Array} rule 选择器或选择器列表。为列表时，匹配到任意一个选择器结果即返回。
 * @returns HTMLElement  匹配到的节点，如果参数rule是数组，则返回一个包含匹配节点和命中的选择器的数组。[node, select]。
 * @see https://developer.mozilla.org/zh-CN/docs/Web/API/Element/closest
 * @see https://api.jquery.com/closest/
 * @example
 * closest(e.target, '.close-btn');
 */
export default function closest(node, rule) {
  var is_str = typeof rule == 'string',
    result = false,
    val;

  if (node) {
    if (is_str) {
      rule = [rule];
    }

    do {
      for (var i = 0; i < rule.length; i++) {
        val = rule[i];

        if (is(node, val)) {
          result = is_str ? node : [node, val];
          break;
        }
      }
    } while (!result && node && (node = node.parentNode));
  }

  return result;
}