/**
 * @description 保证价格不溢出到大于分
 * 厘的单位四舍五入 最多两位小数
 * @category format-helper
 * @param {Number} num
 * @returns {Number}
 * @example
 * 0.1+0.2 = 0.30000000000000004
 * parsePrice(0.1+0.3) => 0.3
 */
export function parsePrice(num) {
  return parseFloat(num.toFixed(2));
}
