import { defrac } from '@common/utils';
import { types } from 'mobx-state-tree';

// 费用类型，影响计算
// less是EXTRA，税率2%。
// const less = 600;
// const a = 3000 - 600;
// console.log(a + a*2/100) // 直接叠加，总价再计税

// less是DEDUCTION，税率2%。
// const less = 600;
// const tLess = (less - less*2/100) // 自身先计税
// const a = 3000 - tLess;
// console.log(a + a*2/100) // 再叠加，再总价计税
export const FEE_TYPE = {
  EXTRA: 0, // 额外费用，正常叠加后再计税。
  DEDUCTION: 1, // 抵扣金额，在总价税前参与计算，且自身计税后，再参与叠加。
  PRE: 2, // 预付款。在总价税后参与计算。
  DISCOUNT: 3, // 优惠类型的抵扣金额，在总价税后参与计算。
};
const FeeType = types.union(...Object.keys(FEE_TYPE).map((key) => types.literal(FEE_TYPE[key])));

export const preTaxTypeList = [FEE_TYPE.EXTRA, FEE_TYPE.DEDUCTION]; // 税前费用
export const originalCostTypeList = [FEE_TYPE.EXTRA, FEE_TYPE.DEDUCTION]; // 原始费用，加起来是一个订单的总价

export function isBeforeTax(type) {
  return preTaxTypeList.includes(type);
}

export function isOriginalCost(type) {
  return originalCostTypeList.includes(type);
}

// 费用的备注分类
export const FEE_TAXTYPE = {
  DEFAULT: 0,
  TAXES: 1, // 税费。
  HST: 2,
  PST: 3,
  GST: 4,
};
const TaxType = types.union(...Object.keys(FEE_TAXTYPE).map((key) => types.literal(FEE_TAXTYPE[key])));

// 费用的计算类型
export const FEE_VALUETYPE = {
  VALUE: 0, // 固定值。
  RATIO: 1, // 费率。
};
const Type = types.union(...Object.keys(FEE_VALUETYPE).map((key) => types.literal(FEE_VALUETYPE[key])));

// 费用的归属
export const FEE_BELONGTYPE = {
  DEFAULT: 0, // 全局通用
  FINANCE: 1, // 贷款相关费用，选择贷款时才收。
};
const FeeBelongType = types.union(...Object.keys(FEE_BELONGTYPE).map((key) => types.literal(FEE_BELONGTYPE[key])));

export const FEE_CATEGORYTYPE = {
  OTHER: 0,
  VEHICLE: 1,
  VEHICLE_EXTRA_SERVICE: 2,
  VEHICLE_TRADEIN: 3,
};
const CategoryType = types.union(...Object.keys(FEE_CATEGORYTYPE).map((key) => types.literal(FEE_CATEGORYTYPE[key])));

export function getFeesValue(list, baseValue, reverse) {
  return list.reduce(function (acc, fee) {
    return acc + (reverse ? -1 : 1) * fee.val(baseValue);
  }, 0);
}

// 费用实体类
const Fee = types
  .model('Fee', {
    id: types.optional(types.number, 0),
    key: '',
    type: types.optional(Type, FEE_VALUETYPE.VALUE), // 固定值类型和费率类型
    feeType: types.optional(FeeType, FEE_TYPE.EXTRA), // 费用计算类型，抵扣、叠加或预付费
    belongType: types.optional(FeeBelongType, FEE_BELONGTYPE.DEFAULT), // 费用归属，通用费用或贷款额外费用
    taxType: types.optional(TaxType, FEE_TAXTYPE.DEFAULT), // 费用性质类型。通用费用或税费（后面可能重命名为税费类类型）
    categoryType: types.optional(CategoryType, FEE_CATEGORYTYPE.OTHER), // 费用类目。用于前端按类目划分展示
    applyTaxType: types.optional(TaxType, FEE_TAXTYPE.DEFAULT), // 该费用计什么税
    canFinance: 0, // 费用是否能贷款
    name: types.optional(types.string, ''), // 费用名称
    value: types.optional(types.number, 0), // 费用值
    ratio: types.optional(types.number, 0), // 费率
    desc: types.optional(types.string, ''), // 费用描述，前端用于tooltips。
    detail: types.optional(types.string, ''), // 费用详情，前端展示。
  })
  .views((self) => ({
    get isDeductionFee() {
      return [FEE_TYPE.DEDUCTION, FEE_TYPE.DISCOUNT].includes(self.feeType);
    },
    get isPreFee() {
      return self.feeType === FEE_TYPE.PRE;
    },
    get isTaxes() {
      return !!self.taxType;
    },
    // 费用是否能贷款
    get isCanFinance() {
      return self.canFinance || self.isTaxes || self.isDeductionFee;
    },
    val(val) {
      const vector = self.isDeductionFee || self.isPreFee ? -1 : 1;
      return (
        (self.type === FEE_VALUETYPE.VALUE ? self.value : defrac(parseFloat(self.ratio) / 100, 6) * val || 0) * vector
      );
    },
    valueStr(val) {
      return self.val(val) / 100;
    },
  }))
  .actions((self) => ({
    setValue(val) {
      self.value = val;
    },
    toValue(val) {
      return self.val(val);
    },
    toValueStr(val) {
      return self.valueStr(val);
    },
    updateFromAjaxJson(data) {
      if (data) {
        Object.assign(self, data);
      }
    },
  }));

const FeeList = types
  .model({
    list: types.optional(types.array(Fee), () => []),
  })
  .views((self) => ({
    get globalFees() {
      return self.list.filter((fee) => fee.feeType !== FEE_TYPE.PRE && fee.belongType === FEE_BELONGTYPE.DEFAULT);
    },
    get taxes() {
      return self.globalFees.filter((fee) => fee.isTaxes);
    },
    get commonFees() {
      return self.globalFees.filter((fee) => !fee.isTaxes);
    },
  }))
  .actions((self) => ({
    updateFromAjaxJson(data) {
      self.list = data || [];
    },
  }));

export { Fee, FeeList };
