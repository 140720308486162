import { find } from 'lodash';
import { types } from 'mobx-state-tree';
import { safeAssign } from '@common/utils/assignment';
import { parsePrice } from '@common/utils/format-helper/price';

export const couponStatusOptions = [
  { value: 0, label: '' },
  { value: 1, label: '', isCanUse: 1 },
];

const Coupon = types
  .model({
    id: 0,
    name: '',
    can_use: types.optional(types.union(types.boolean, types.number), 1),
    discount: 0,
    full_money: 0,
    money: 0,
    status: 1,
    expired: '',
    code: '', // 邀请活动的code
  })
  .views((self) => ({
    get statusVo() {
      return find(couponStatusOptions, { value: self.status }) || couponStatusOptions[0];
    },
  }))
  .actions((self) => ({
    checkCanUse(price) {
      return price >= self.full_money;
    },

    toValue(price) {
      if (!self.checkCanUse(price)) {
        return price;
      }

      // return Math.max(0, parsePrice(price - self.money));
      return Math.max(0, parsePrice(price - self.money));
    },

    updateFromAjaxJson(data) {
      safeAssign(self, data);
    },
  }));

export default Coupon;
