import { types } from 'mobx-state-tree';
import { find, findIndex, map } from 'lodash';
import * as dayjs from 'dayjs';
import FinanceApplicationForm, { FinanceApplicationFormDetail } from './FinanceApplication';

export const orderStatusOptions = [
  { value: 0, label: 'Initial' },
  { value: 10, label: 'Created', desc: 'Waiting to pay the deposit' },
  { value: 11, label: 'HOLD EXPRESS', desc: 'Waiting for complete the information', isHoldOnly: true },
  { value: 20, label: 'Booked', desc: 'Waiting to be accepted', isBooked: true },
  { value: 30, label: 'Accepted', desc: 'In process' },
  { value: 40, label: 'Deliverable', desc: 'Confirm delivery is complete' },
  { value: 50, label: 'Complete', isComplete: true },
  { value: 60, label: 'Canceling' },
  { value: 70, label: 'Cancelled' },
];

export const orderPayStatusOptions = [
  { value: 0, label: 'Initial' },
  { value: 10, label: 'Try to pay' },
  { value: 20, label: 'payment successful', isSuccess: true },
  { value: 30, label: 'Payment failed' },
  { value: 40, label: 'Canceling' },
  { value: 50, label: 'Cancelled' },
];

export const orderLoanStatusOptions = [
  { value: 0, label: 'Initial' },
  { value: 10, label: 'Waiting to submit' },
  { value: 20, label: 'Under review' },
  { value: 30, label: 'Editor invited' },
  { value: 40, label: 'Review successful' },
  { value: 50, label: 'Review failed' },
  { value: 60, label: 'Cancel' },
];

const CarOrderBase = types
  .model({
    id: types.maybe(types.union(types.string, types.number)),
    order_id: '',
    created_at: '',
    status: 0,
    pay_type: 0,
    deposit_pay_status: 0,
    loan_status: 0,

    // 某个区块的信息是否已确认
    isBuyerConfirmed: 0,
    isVehicleConfirmed: 0,
    isTradeInConfirmed: 0,
    isPriceConfirmed: 0,
    isPaymentConfirmed: 0,
    isDeliveryConfirmed: 0,
    isDocumentConfirmed: 0,
  })
  .views((self) => ({
    get createdTimeVo() {
      return self.created_at ? dayjs(self.created_at) : null;
    },
    get nextStatus() {
      return self.statusVo.isComplete || self.statusVo.value === 70
        ? self.statusVo.value
        : orderStatusOptions[findIndex(orderStatusOptions, self.statusVo) + 1].value;
    },
    get statusVo() {
      return find(orderStatusOptions, { value: self.status }) || orderStatusOptions[0];
    },
    get statusText() {
      return self.statusVo.label;
    },
    get statusDesc() {
      return self.statusVo.desc || '';
    },

    get depositPayStatusVo() {
      return find(orderPayStatusOptions, { value: self.deposit_pay_status }) || orderPayStatusOptions[0];
    },
    get loanStatusVo() {
      return find(orderLoanStatusOptions, { value: self.loan_status }) || orderLoanStatusOptions[0];
    },

    get isFlowing() {
      return self.status < 40;
    },
  }))
  .actions((self) => ({
    updateFromAjaxJson(data) {
      if (data) {
        map(data, function (value, key) {
          switch (key) {
            case 'coupon':
            case 'couponTicket':
              self[key].updateFromAjaxJson(value);
              break;
            default:
              self[key] = value;
          }
        });

        self.updateMdsp();
      }
    },
  }));

const CarOrder = types.compose(FinanceApplicationFormDetail, CarOrderBase);
const EditableCarOrder = types.compose(FinanceApplicationForm, CarOrderBase);

const CarOrderList = types
  .model({
    list: types.array(CarOrder),
  })
  .actions((self) => ({
    updateFromAjaxJson(list) {
      self.list = list ? list.filter((item) => item.vehicle) : [];
    },
  }));

export { CarOrderList, CarOrder, EditableCarOrder };
