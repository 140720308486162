function tryParse(val) {
  try {
    return JSON.parse(val);
  } catch (e) {
    return val;
  }
}

/**
 * 对一个值强制规定为数组类型，不是则返回一个空数组
 * @param {any} val
 * @param {Boolean} jsonParse 对其进行JSON.parse
 * @returns Array
 */
export function forceToArray(val, jsonParse) {
  val = jsonParse ? tryParse(val) : val;

  if (Array.isArray(val)) {
    return val;
  }

  return [];
}

export function toMonthlyPayment(mdsp) {
  return `Est. $${mdsp}/mo`;
}

export function connectFeeName(list) {
  return list.map((fee) => fee.name).join(' & ');
}
