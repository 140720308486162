import { superstructResolver } from '@hookform/resolvers/superstruct';
import { object } from 'superstruct';
import {
  FirstName,
  LastName,
  Address,
  Province,
  PostalCode,
  DatePicker,
  CitySelect,
  Email,
  Password,
  Phone,
} from '@common/utils/verification';

const now = new Date();

/* 罗列业务表单控件定义，内容是FormItem的props */
export const presetControls = {
  firstname: {
    name: 'firstname',
    label: 'First Name',
    required: true,
    col: { lg: 12 },
  },

  lastname: {
    name: 'lastname',
    label: 'Last Name',
    required: true,
    col: { lg: 12 },
  },

  birth: {
    name: 'birth',
    controlType: 'date',
    valueFormat: 'YYYY-MM-DD',
    maximumDate: {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate(),
    },
    label: 'Date of Birth (MM/DD/YYYY)',
    required: true,
  },

  address: {
    name: 'address',
    label: 'Stree Address',
  },

  city: {
    name: 'city',
    label: 'City',
    // controlType: 'select',
    // valueKey: 'value',
    // options: [
    //   { value: 'New York', label: 'New York' },
    //   { value: 'ShangHai', label: 'ShangHai' },
    // ],
  },

  province: {
    name: 'province',
    label: 'Province',
    col: { lg: 12 },
  },

  postal: {
    name: 'postal',
    label: 'Postal Code',
    col: { lg: 12 },
  },
  email: {
    name: 'email',
    label: 'E-Mail',
    required: true,
  },
  phone: {
    name: 'phone',
    label: 'Phone Number',
  },
  phoneRequired: {
    name: 'phone',
    label: 'Phone Number',
    required: true,
  },
  password: {
    name: 'password',
    type: 'password',
    label: 'Password',
    required: true,
  },
  confirmPassword: {
    name: 'confirmPassword',
    type: 'password',
    label: 'Confirm Password',
    required: true,
    col: { size: 12 },
  },
};

/* 定义预设主题，声明该主题需要的表单控件 */
export const presetTheme = {
  financing: {
    field: ['firstname', 'lastname', 'phone', 'email', 'birth', 'address', 'city', 'province', 'postal'],
    resolver: (options) =>
      superstructResolver(
        object({
          firstname: FirstName.required,
          lastname: LastName.required,
          phone: Phone.required,
          email: Email.required,
          birth: DatePicker.required,
          address: Address.optional,
          city: CitySelect.optional,
          province: Province.optional,
          postal: PostalCode.optional,
          ...options,
        })
      ),
  },
  login: {
    field: ['email', 'password'],
    resolver: (options) =>
      superstructResolver(
        object({
          email: Email.required,
          password: Password.required,
          ...options,
        })
      ),
  },
  register: {
    field: [
      mergeItem('firstname', { coverLabel: true, col: { size: 12 } }),
      mergeItem('lastname', { coverLabel: true, col: { size: 12 } }),
      presetControls['phoneRequired'],
      'email',
      mergeItem('password', { coverLabel: true, col: { size: 12 } }),
      mergeItem('confirmPassword', { coverLabel: true }),
    ],
    resolver: (options) =>
      superstructResolver(
        object({
          firstname: FirstName.required,
          lastname: LastName.required,
          phone: Phone.required,
          email: Email.required,
          password: Password.required,
          ...options,
        })
      ),
  },

  paymentRegister: {
    field: [
      'email',
      mergeItem('password', { coverLabel: true, col: { size: 12 } }),
      mergeItem('confirmPassword', { coverLabel: true }),
      mergeItem('firstname', { coverLabel: true, col: { size: 12 } }),
      mergeItem('lastname', { coverLabel: true, col: { size: 12 } }),
    ],
    resolver: (options) =>
      superstructResolver(
        object({
          firstname: FirstName.required,
          lastname: LastName.required,
          email: Email.required,
          password: Password.required,
          ...options,
        })
      ),
  },

  personal: {
    field: [
      mergeItem('firstname', { coverLabel: true, col: { md: 12 } }),
      mergeItem('lastname', { coverLabel: true, col: { md: 12 } }),
      mergeItem('birth', { coverLabel: true, col: { md: 12 } }),
    ],
  },

  contact: {
    field: [
      mergeItem('phoneRequired', { coverLabel: true, col: { md: 12 } }),
      mergeItem('email', { coverLabel: true, col: { md: 12 }, readOnly: true }),
      mergeItem('address', { coverLabel: true, col: { md: 12 } }),
      mergeItem('city', { coverLabel: true, col: { md: 12 } }),
      mergeItem('province', { coverLabel: true, col: { md: 12 } }),
      mergeItem('postal', { coverLabel: true, col: { md: 12 } }),
    ],
  },

  replenish: {
    field: [
      mergeItem('firstname', { coverLabel: true, col: { size: 12 } }),
      mergeItem('lastname', { coverLabel: true, col: { size: 12 } }),
      presetControls['phoneRequired'],
    ],
    resolver: (options) =>
      superstructResolver(
        object({
          firstname: FirstName.required,
          lastname: LastName.required,
          phone: Phone.required,
          ...options,
        })
      ),
  },
};

presetTheme['profile'] = {
  field: presetTheme['personal'].field.concat(presetTheme['contact'].field),
  resolver: (options) =>
    superstructResolver(
      object({
        firstname: FirstName.required,
        lastname: LastName.required,
        birth: DatePicker.optional,

        phone: Phone.required,
        email: Email.required,
        address: Address.optional,
        city: CitySelect.optional,
        province: Province.optional,
        postal: PostalCode.optional,
        ...options,
      })
    ),
};

// 基于已有的presetControls，合并配置生成一个新的
export function mergeItem(key, item) {
  return Object.assign({}, presetControls[key] || {}, item);
}

export function mapToItem(list) {
  return list.map((item) => (typeof item === 'string' ? presetControls[item] : item));
}
