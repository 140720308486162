import { Subject } from 'rxjs';
import { useState } from 'react';
import { forEach, mapValues } from 'lodash';
import useWatch from '@common/hooks/useWatch';

function createSubject() {
  return new Subject();
}

export function useWatchObservable(dep) {
  const [subject] = useState(createSubject);
  useWatch(dep, (...rest) => subject.next(rest));
  return subject;
}

export function useVueCycle(props, mixins, refMap) {
  // 处理state
  const stateMap = mixins.reduce((acc, comp) => Object.assign(acc, (comp.useData && comp.useData(props)) || null), {});
  const stateValue = mapValues(stateMap, '0');
  const stateSetter = mapValues(stateMap, '1');
  const state = {
    state: stateValue,
    stateSetter,
  };

  // 处理computed
  const computedValueMap = {};
  const computedMap = mixins.reduce(
    (acc, comp) =>
      Object.assign(acc, (comp.computed && comp.computed(props, stateValue, computedValueMap, acc)) || null),
    {}
  );

  forEach(computedMap, function (value, key) {
    Object.defineProperty(computedValueMap, key, {
      get: function () {
        return value();
      },
    });
  });

  // 处理useRender
  const renderState = {};
  mixins.reduce(
    (acc, comp) =>
      Object.assign(
        acc,
        (comp.useRenderBefore && comp.useRenderBefore(props, state, computedValueMap, acc, refMap)) || null
      ),
    renderState
  );
  mixins.reduce(
    (acc, comp) =>
      Object.assign(acc, (comp.useRender && comp.useRender(props, state, computedValueMap, acc, refMap)) || null),
    renderState
  );

  return {
    state,
    computed: computedValueMap,
    renderState,
  };
}
