import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import * as classNames from 'classnames';
import useRootStore from '@/models';
import Button from '@/components/Button';
import { MoneyField } from '../MoneyField';
import './style.less';

const InviteActivityContent = function ({ data, forMobile }) {
  const { firstname, lastname, activity } = data;
  return (
    <section className="login-invite-activity-content text-center">
      <h1 className="upppercase self__invite-title text-bold">CONGRATULATIONS</h1>

      <p className={classNames('self__invite--subtitle text-bold', forMobile ? 'ff-heavy' : '')}>
        {firstname + (firstname || lastname ? ' ' : '') + lastname}
        <br />
        sent you a referral reward!
      </p>

      <p className="self__invite--p">
        Create an account to start using your
        <br />
        <MoneyField value={activity.money / 100} suffix=" " />
        <span>credit towards the purchase or</span>
        <br />
        sale of your vehicle with Carnex.
      </p>

      <div>
        <Link className="color-gray" to="/terms?referral" target="_blank">
          See Terms
        </Link>
      </div>
    </section>
  );
};

const LoginActivity = observer(function LoginActivity({ children, data, index, onUpdateIndex }) {
  const store = useRootStore();
  const usePc = store.ui.width > 1070;

  if (usePc) {
    return (
      <div className="flex">
        <div className="login-invite-activity">
          <InviteActivityContent data={data} />
        </div>
        {children}
      </div>
    );
  }

  if (typeof index === 'number') {
    return children;
  }
  return (
    <div className="login-invite-activity--mobile">
      <InviteActivityContent forMobile data={data} />
      <div>
        <Button size="medium" onClick={() => onUpdateIndex(0)}>
          Log in
        </Button>
        <Button size="medium" border onClick={() => onUpdateIndex(1)}>
          Sign up
        </Button>
      </div>
    </div>
  );
});

export default LoginActivity;
