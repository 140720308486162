import { useEffect } from 'react';
import useWatch from './useWatch';

/**
 * 函数组件没有实例，但是有时候我们需要向祖父组件暴露内部方法。
 * 一个例子是form组件需要向外暴露submit api，以方便进行命令式调用。
 * @param {Object}   ref      React.useRef()的返回值
 * @param {Array}    dep      api列表，应该经过useCallback等避免每次渲染都重新创建
 * @param {Function} exportFn 导出逻辑
 * @example
 * // parent.vue
 * function Parent() {
 *   const submitApiRef = React.useRef();
 *   return (
 *     <div>
 *       <Form apiRef={submitApiRef} />
 *       <div>other more component</div>
 *       <div onClick={() => submitApiRef.current.submit()}>submit</div>
 *     </div>
 *   )
 * }
 * 
 * // Form.vue
 * function Form({apiRef}) {
 *   const btnRef = React.useRef();
 * 
 *   useExportApiRef(
 *     apiRef,
 *     [
 *       useCallback(() => {
 *         btnRef.current.click();
 *       }, [btnRef]),
 *     ],
 *     (submit) => ({submit})
 *   );

 *   return (
 *     <form>
 *       <div>other more component</div>
 *       <input className="display-none" type="submit" ref={btnRef} />
 *     </form>
 *   )
 * }
 * 
 */
export default function useExportApiRef(ref, dep, exportFn) {
  useWatch(
    dep,
    function (newValues) {
      if (ref) ref.current = exportFn.apply(null, newValues);
    },
    { immediate: true }
  );

  useEffect(
    () =>
      function () {
        if (ref) ref.current = undefined;
      },
    [ref]
  );
}
