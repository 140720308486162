import { of, from } from 'rxjs';
import { getEnv, getParent, types } from 'mobx-state-tree';
import { DomainEvent } from 'domain-events';
import { isEqual } from 'lodash';
import mitt from 'mitt';
import { createStatusDataDescribeForMST } from './createStatusDataDescribe';
import { createAjaxDescribeForMST } from './createAjaxDescribe';
import { createAjaxPagingDescribe, createAjaxPagingDescribeForMST } from './createAjaxPagingDescribe';
import { packPromise } from '.';

function hookFactory(hook, self) {
  return function (field, resolveParams, onUpdate, options, ...args) {
    if (typeof onUpdate === 'string') {
      const syncKey = onUpdate;
      onUpdate = (state) => (self[syncKey] = state.data);
    }

    const ajaxDescribe = hook(
      resolveParams,
      onUpdate,
      {
        runInAction: self.runInAction.bind(self),
        getProxy: () => self[field],
        ...options,
      },
      ...args
    );

    self[field] = ajaxDescribe;

    if (!self._observables_) {
      self._observables_ = [];
    }

    self._observables_.push(ajaxDescribe);
  };
}

// 基于mobx的方案
// 这一块注释代码是原来的一种实现方式，保留仅仅用作思想启发，没有其他用途和影响。
/* import { autorun, makeAutoObservable, runInAction, toJS } from 'mobx';
function updateStateForMobx(state, changeState) {
  runInAction(() => Object.assign(state, changeState));
}

updateStateForMobx.init = makeAutoObservable;
createStatusStream.defaultSetState = updateStateForMobx;

const baseKey = ['data', 'loading', 'error'];
export const AjaxDescribe = types.custom({
  name: 'AjaxDescribe',
  fromSnapshot(value) {
    return JSON.parse(value);
  },
  toSnapshot(value) {
    return JSON.stringify(value);
  },
  isTargetType(value) {
    if (value) {
      for (let i = 0; i < baseKey.length; i++) {
        if (!(baseKey[i] in value)) return false;
      }

      return true;
    }

    return false;
  },
  getValidationMessage(value) {
    if (/^-?\d+\.\d+$/.test(value)) return ''; // OK
    return `'${value}' doesn't look like a valid decimal number`;
  },
});
// createStatusStream内部
onUpdate(state.data, state);
autorun(() => onUpdate(toJS(state).data, state)); */

// 基于mobx-state-tree的方案
const functionType = types.custom({
  name: 'functionType',
  fromSnapshot(val) {
    return val;
  },
  toSnapshot() {
    return undefined;
  },
  isTargetType(value) {
    return value instanceof Function;
  },
  getValidationMessage(value) {
    return value + ' not is functionType';
  },
});

export const AjaxDescribe = types
  .model('AjaxDescribe', {
    data: types.frozen(),
    loading: types.boolean,
    error: types.frozen(),
    inited: types.boolean,
    initedAsync: types.boolean,
    timeStamp: types.number,
    next: types.maybe(functionType),
    unsubscribe: types.maybe(functionType),
  })
  .actions((self) => ({
    afterCreate() {
      // 一般不会关注它的json，因为要序列化，functionType不会被保存。如果导出的json意外用于其它地方的赋值，将因为缺少functionType相关内容而发生错误。
      self.$treenode.getSnapshot = function () {};
    },
  }));

export const StatusDescribe = AjaxDescribe;

export const AjaxPagingDescribe = types.compose(
  types.model('AjaxPagingDescribe', {
    pageNo: 0,
    maxPage: 0,
    reload: true,
    pager: types.maybe(functionType),
    nextPage: types.maybe(functionType),
  }),
  AjaxDescribe
);

export const AjaxDescribeHelper = types.model({}).actions((self) => ({
  beforeDestroy() {
    if (self._observables_) {
      self._observables_.splice(0).forEach((desc) => desc.unsubscribe());
    }
  },

  runInAction(fn) {
    return fn();
  },

  createStatusDataDescribe: hookFactory(createStatusDataDescribeForMST, self),
  createAjaxDescribe: hookFactory(createAjaxDescribeForMST, self),
  createAjaxPagingDescribe: hookFactory(createAjaxPagingDescribeForMST, self),
}));

export function createAjaxDescribeMixins(key, Type, createAjaxDescribe, actionKey, lazyActionKey) {
  let preArgs;
  return types
    .model({
      [key]: types.maybe(Type),
    })
    .actions((self) => ({
      afterCreate() {
        createAjaxDescribe.call(self);
        if (!self._describeEmitter) {
          self._describeEmitter = mitt();
        }
      },
      beforeDestroy() {
        self[key].unsubscribe();
      },
      ...(actionKey
        ? {
            [actionKey](res) {
              const stream = self[key].next(res);

              stream.subscribe(() => {
                self._describeEmitter.emit(key + '_loaded');
                self._describeEmitter.all.delete(key + '_loaded');
              });

              return stream;
            },
          }
        : {}),

      ...(lazyActionKey
        ? {
            [lazyActionKey](timeSpan, ...args) {
              const ajaxDescribe = self[key];

              if (typeof timeSpan === 'undefined') {
                timeSpan = 5000;
              }

              if (
                ajaxDescribe.inited &&
                !ajaxDescribe.error &&
                Date.now() - ajaxDescribe.timeStamp < timeSpan &&
                isEqual(args, preArgs)
              ) {
                return of(ajaxDescribe.data);
              }

              preArgs = args;
              return self[actionKey](...args);
            },
          }
        : {}),
    }));
}

export function defineDomainEvent(name) {
  return class CustomDomainEvent extends DomainEvent {
    static eventName() {
      return name;
    }

    constructor(aEventPayload) {
      super(name, aEventPayload);
    }
  };
}

export function tryDispatchDomainEvent(DomainEvent, res, store) {
  let val;

  try {
    // 发布领域事件
    const { eventBus } = getEnv(store);
    if (eventBus) {
      val = eventBus.globalDispatch(new DomainEvent(res));
    }
  } catch (e) {
    // console.error(e);
  }

  return packPromise(val);
}

function getLastUpdatedStream(key = 'ajaxDescribe') {
  const self = this;
  const { loading, inited } = self[key];

  if (!loading && inited) {
    return of(self);
  } else {
    return from(new Promise((cb) => self._describeEmitter.on(`${key}_loaded`, cb)));
  }
}

function noop() {}
function _onUpdate() {
  if (this.error) {
    console.error(this.error);
  }
}
function getCreateAjaxDescribeMixinsMethods(actionKey, lazyActionKey, key) {
  let preArgs;
  const self = this;
  return {
    ...(actionKey
      ? {
          [actionKey](res) {
            const stream = self[key].next(res);
            stream.subscribe(() => {
              self._describeEmitter.emit(key + '_loaded');
              self._describeEmitter.all.delete(key + '_loaded');
            });

            return stream;
          },

          ...(lazyActionKey
            ? {
                [lazyActionKey](timeSpan, ...args) {
                  const ajaxDescribe = self[key];

                  if (typeof timeSpan === 'undefined') {
                    timeSpan = 5000;
                  }

                  if (
                    timeSpan &&
                    ajaxDescribe.inited &&
                    !ajaxDescribe.error &&
                    Date.now() - ajaxDescribe.timeStamp < timeSpan &&
                    isEqual(args, preArgs)
                  ) {
                    return of(ajaxDescribe.data);
                  }

                  preArgs = args;
                  return self[actionKey](...args);
                },
              }
            : null),
        }
      : null),
  };
}

/**
 * 动态生成一个Describe的类型
 * @param {Function} createDescribe 生成Describe的工厂函数。已知有createAjaxDescribe，createAjaxPagingDescribe和createStatusDataDescribe。
 * @param {Function} subStreamFactory createDescribe的第1个参数。
 * @param {Function|Object} config    为object时代表createDescribe的第3个参数。
 *                                    为function时分2种情况：
 *                                    subStreamFactory为function时，代表是config.onDataUpdate。如果不需要设置onDataUpdate以外的字段时方便使用。但更多是因为方便迁移历史包袱。
 *                                    subStreamFactory为falsy值时，代表是构造createDescribe参数列表的函数。一般当createDescribe的多个参数需要用到this，或者公用一个闭包作用域时用到。
 * @param {Function|Object} config.needData    是否需要data字段。如果data内容会拷贝到parent，那么建议始终为false，这样toJSON时就减少不必要的数据冗余。
 *                                             默认是false。createDescribe是createAjaxPagingDescribe时，默认为true。在createAjaxPagingDescribe使用false的话，应该配合onEffect，或者自行实现concatData。
 * @param {Function|Object} pagingConfig    createDescribe的第4个参数。为function时，用parent作为this求值。
 * @returns
 */
const EMPTY = {};
export function generateDescribeType(createDescribe, subStreamFactory, config, pagingConfig) {
  const isPagingDescribe = createAjaxPagingDescribe === createDescribe;
  return types.snapshotProcessor(
    types.optional(
      types
        .model({
          data: types.frozen(),
          error: types.frozen(),
          loading: false,
          inited: false, // 是否已经初始化过
          initedAsync: false, // 为了后面addListener的listener能读取到“正确/合适”的inited，创建一个延迟变更的状态
          // next: types.maybe(functionType),
          // unsubscribe: types.maybe(functionType),
          ...(isPagingDescribe
            ? {
                pageNo: 0,
                maxPage: 0,
                totalCount: 0,
                reload: true,
                // setParams: types.maybe(functionType),
                // nextPage: types.maybe(functionType),
                // getPagerParams: types.maybe(functionType),
                // pager: types.maybe(functionType),
                // load: types.maybe(functionType),
                // changePageSize: types.maybe(functionType),
              }
            : {}),
        })
        .actions((self) => ({
          afterAttach() {
            const parent = getParent(self);
            let _subStreamFactory = subStreamFactory;
            let _config = config;
            let _pagingConfig;

            // typeof _config === 'function'时，根据有无subStreamFactory而代表onDataUpdate或paramsGenerator
            if (typeof _config === 'function' && !_subStreamFactory) {
              [_subStreamFactory, _config, _pagingConfig] = _config.call(parent, self);
            }

            pagingConfig = _pagingConfig || pagingConfig;
            const configObj = Object.assign(
              { needData: isPagingDescribe },
              typeof _config === 'function' ? null : _config
            );
            const onDataUpdate = typeof _config === 'function' ? _config : (_config && _config.onDataUpdate) || noop;

            createDescribe(
              _subStreamFactory.bind(parent),
              null,
              {
                ...configObj,
                _status: self,
                setState(state, changState) {
                  self.runInAction(function () {
                    (configObj.setState || Object.assign)(state, changState);
                  });
                },
                onDataUpdate(data) {
                  if (!configObj || !configObj.needData) {
                    self.runInAction(function () {
                      self.data = Array.isArray(data) ? Array.from({ length: data.length }) : EMPTY; // 总是给个空对象，让<stream-container />能如期工作
                    });
                  }

                  return onDataUpdate.call(parent, data);
                },
              },
              typeof pagingConfig === 'function' ? pagingConfig.call(parent) : pagingConfig
            );

            self._onUpdate = _onUpdate;
          },
          beforeDestroy() {
            self.unsubscribe();
          },
          // next: noop,
          // unsubscribe: noop,
          runInAction(fn) {
            return fn();
          },
        })),
      {}
    ),
    {
      // from instance to snapshot
      postProcessor() {
        // 一般不会关注它的json，因为要序列化，functionType不会被保存。如果导出的json意外用于其它地方的赋值，将因为缺少functionType相关内容而发生错误。
      },
    }
  );
}

export function generateDescribeModule(key, describeTypeArgs, actionKey = 'load', lazyActionKey) {
  return types
    .model({
      [key]: generateDescribeType(...describeTypeArgs),
    })
    .actions((self) => {
      return {
        afterCreate() {
          if (!self._describeEmitter) {
            self._describeEmitter = mitt();
            self.getLastUpdatedStream = getLastUpdatedStream;
          }
        },
        beforeDestroy() {
          self[key].unsubscribe();
        },
        ...getCreateAjaxDescribeMixinsMethods.call(self, actionKey, lazyActionKey, key),
      };
    });
}
