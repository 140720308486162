if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.matchesSelector ||
  Element.prototype.mozMatchesSelector ||
  Element.prototype.msMatchesSelector ||
  Element.prototype.oMatchesSelector ||
  Element.prototype.webkitMatchesSelector || function(s) {
    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
      i = matches.length;

    /* eslint no-empty: "off" */
    while (--i >= 0 && matches.item(i) !== this) {
    }

    return i > -1;
  };
}

/**
 * 检查元素是否被指定的选择器字符串选择
 * @param {HTMLElement} node dom node
 * @param {String} selector css选择器
 * @see https://developer.mozilla.org/zh-CN/docs/Web/API/Element/matches
 * @returns Boolean
 */
export default function is(node, selector) {
  if (typeof selector !== 'string') {
    return node === selector;
  } else {
    return node.matches && node.matches(selector);
  }
}
