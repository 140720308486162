import { cloneElement, forwardRef, useCallback } from 'react';
import { safeRender, toVueSlots } from '@common/utils/slots';
import Checkbox from '.';

const falseLabel = {};

function newValuesAfterChange(values, val, oriVal) {
  if (val !== falseLabel) {
    return values.concat(val);
  }

  return values.filter((value) => value !== oriVal);
}

const CheckboxGroup = forwardRef(function CheckboxGroup({ children, value, inputRef, onChange }, ref) {
  const _ref = inputRef || ref;
  const changeHandler = useCallback(
    (val, e) => {
      onChange && onChange(newValuesAfterChange(value, val, e.trueLabel));
    },
    [onChange, value]
  );
  const buildChildProps = useCallback(
    (trueLabel, index) => ({
      key: trueLabel,
      ref: index === 0 ? _ref : null,
      trueLabel,
      falseLabel,
      value: value.indexOf(trueLabel) !== -1 ? trueLabel : falseLabel,
      onChange: changeHandler,
    }),
    [_ref, changeHandler, value]
  );

  const child = toVueSlots(safeRender(children, { buildChildProps })).default;
  const childs = child && !Array.isArray(child) ? [child] : child || [];

  return childs.map((child, i) =>
    child.type === Checkbox ? cloneElement(child, buildChildProps(child.props.trueLabel, i)) : child
  );
});

export default CheckboxGroup;
