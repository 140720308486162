export function calculateMdsp(saleprice, downpay, interestrate, loanmonth) {
  var vprice = saleprice;
  var down_payment = downpay;
  var apr = interestrate;
  var term = loanmonth;
  var principal = vprice - down_payment;
  var r = apr / 1200;
  var n = term;
  var t = Math.pow(1 + r, n);
  var _mdsp = 0;
  if (r) {
    _mdsp = (principal * r * t) / (t - 1);
  }

  return _mdsp.toFixed(2);
}
