import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import Login from '@/widgets/Login';
import { rootStore } from '@/models';
import { DialogUIController } from '../../frontend-common/controller/UIController';
import LoginActivity from '@/widgets/LoginActivity';
import invitationController from '@/controller/invitationController';
import useAjaxStateDescribe from '@common/hooks/useAjaxStateDescribe';
import { createSingletonPattern } from '@common/utils';
import Spinner from '@common/components/Spinner';

const resolveModal = createSingletonPattern(() => import('@common/helper/modal').then((e) => e.default));

const LoginComp = observer(function LoginComp(props) {
  const [index, setIndex] = useState(props.defaultIndex);
  const [ticketInfoDescribe, request] = useAjaxStateDescribe(invitationController.fetchTicketInfo);
  useEffect(() => {
    request();
  }, [request]);

  return (
    <Login
      {...props}
      defaultIndex={index}
      children={function (DefaultContent) {
        if (ticketInfoDescribe.loading) return <Spinner />;
        if (ticketInfoDescribe.data && ticketInfoDescribe.data.id) {
          return (
            <LoginActivity index={index} data={ticketInfoDescribe.data} onUpdateIndex={setIndex}>
              {DefaultContent}
            </LoginActivity>
          );
        }

        return DefaultContent;
      }}
    />
  );
});

class LoginController extends DialogUIController {
  constructor(value) {
    super(value);
    this.init();
  }

  render() {
    return <LoginComp {...this.state} />;
  }
}

function login() {
  const { auth } = rootStore;
  if (!auth.isLogin) {
    return new Promise((resolve) => loginController.show({ autoJump: false, onSuccess: resolve, onClose: resolve }));
  }
}

export async function checkReplenish() {
  const { auth } = rootStore;
  if (auth.user.needReplenish) {
    await Promise.all([import('@/widgets/User/ReplenishInfomation').then((e) => e.default), resolveModal()]).then(
      ([ReplenishInfomation, modal]) =>
        modal
          .alert(
            function ({ onChange }) {
              return <ReplenishInfomation onClose={() => onChange(false)} />;
            },
            {
              title: 'Please complete to log in',
              titleClass: 'dialog__title--left text-bold',
              dialogClass: 'replenish-infomation-form-dialog small-form-dialog',
            }
          )
          .toPromise()
    );
  }

  return auth.user.needReplenish
    ? Promise.reject({ message: 'Please complete the required information to continue.' })
    : true;
}
/**
 * 检查是否已经登录。登录时resolve，未登录则reject
 * @param {Boolean} needReplenish 是否要求完成必填信息
 * @returns 有值表示经过登录，没有则表示本身已经处于登录状态
 */
export function checkLogin(needReplenish) {
  const { auth } = rootStore;
  return (auth.isLogin ? Promise.resolve() : login())
    .then((res) => {
      if (!auth.isLogin) {
        return Promise.reject({
          message: 'Please login to continue.',
        });
      }

      return res;
    })
    .then((res) => {
      if (needReplenish) {
        return checkReplenish().then(res);
      }
      return res;
    });
}

const loginController = new LoginController(false);

export default loginController;
