import network from '@common/libs/network';

const orderServer = {
  getOrder(id) {
    return network(`/newApi/order/${id}`);
  },

  addOrder(body) {
    return network(`/newApi/order`, body, {
      method: 'post',
    });
  },

  modifyOrder(id, body) {
    return network(
      `/newApi/order/${id}`,
      {
        ...body,
        id,
      },
      {
        method: 'put',
      }
    );
  },

  cancelOrder(id) {
    return orderServer.modifyOrder(id, {
      status: 70,
    });
  },
};

export default orderServer;
