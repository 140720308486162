import { autorun, intercept } from 'mobx';
import { getParent, types } from 'mobx-state-tree';
import { Fee, FEE_BELONGTYPE, FEE_TAXTYPE } from '../Fee/Fee';
import Mdsp from '../Mdsp';
import AssignmentHelper from '../utils/AssignmentHelper';

const Scheme = types.compose(
  AssignmentHelper,
  types
    .model({
      downPayment: 0,
      financeTerm: 0,
      interestrate: 5.99,
      organization: '',
      ppsa: 0,
      enable: false,
      mdsp: types.optional(Mdsp, {}), // 月供信息
    })
    .views((self) => {
      let _ppsa;

      return {
        // 订单实例
        get order() {
          const parent = getParent(self);
          // isArray的话，是LoanScheme的list
          return Array.isArray(parent) ? getParent(parent, 2) : parent;
        },
        get ppsaFee() {
          const fee =
            _ppsa ||
            Fee.create({
              belongType: FEE_BELONGTYPE.FINANCE,
              applyTaxType: FEE_TAXTYPE.HST,
            });

          _ppsa = fee;
          fee.setValue(self.ppsa);

          return fee;
        },

        // 需要的贷款
        get loanAmount() {
          return Math.max(self.totalLienAmountForMdsp - self.downPayment, 0);
        },

        // 订单金额 + 贷款的额外费用（含税）
        get totalLienAmountForMdsp() {
          const { order, ppsaFee } = self;
          return order.totalFee + ppsaFee.toValue() + order.priceList.calculateTaxCostForFee(ppsaFee);
        },
      };
    })
    .actions((self) => {
      let autoUpdateDisposer;
      let interceptDisposer;

      return {
        // 初始化，跟mdsp同步
        afterCreate() {
          function syncMdsp(mdsp) {
            mdsp.from({
              downpay: self.downPayment,
              loanmonth: self.financeTerm,
              interestrate: self.interestrate,
            });
          }

          // 如果mdsp和其它字段同时被赋值，会“错过”那次autorun，直接以新的mdsp赋值为结果。
          interceptDisposer = intercept(self, 'mdsp', function (change) {
            syncMdsp(change.newValue.storedValue);
            return change;
          });

          autoUpdateDisposer = autorun(function () {
            syncMdsp(self.mdsp);
          });
        },

        beforeDestroy() {
          interceptDisposer();
          autoUpdateDisposer();
        },
      };
    })
);

const LoanScheme = types
  .model({
    list: types.optional(types.array(Scheme), []),
    use: 0,
  })
  .views((self) => ({
    get scheme() {
      return self.list[self.use];
    },
  }));

export default LoanScheme;
