import { DEV_MODE, IS_DEV, IS_TEST, IS_UAT, IS_PROD } from '@/env';

export const CDNADDR = 'https://carnexcdn.azureedge.net/public';
export const ADMINBACK = 'https://carnexadminback.azurewebsites.net';
export const ENDPOINT = DEV_MODE ? 'https://carnexapi.azurewebsites.net' : 'https://carnexapi.azurewebsites.net'; // 'https://carnexbetatestbackend.azurewebsites.net';
export const ADMINFRONTEND = IS_PROD ? 'http://adminback.carnex.ca' : 'https://admin.cnx.work.gd';

export const ENDPOINT_NEW =
  DEV_MODE || IS_DEV
    ? 'https://api.cnxdev.fun/api'
    : IS_UAT
    ? 'https://adminapibeta.carnex.ca/'
    : IS_TEST
    ? 'https://api.cnxdev.fun/api'
    : 'https://adminapi.carnex.ca/api';
