import { types } from 'mobx-state-tree';
import URLParse from 'url-parse';
import { safeAssign } from '../../utils/assignment';
import { similar } from '@common/utils/similar';
import * as dayjs from 'dayjs';

function pathToRegexp(path) {
  return new RegExp(
    path.replace(/\*\*|\*/g, function (word) {
      return word === '**' ? '[\\s\\S]*' : '[^/]*';
    }) + '$'
  );
}

export const matchTypeOptions = [
  { value: 0, label: 'Normal' },
  { value: 1, label: 'RegExp' },
];

const MatchItem = types.model({
  matchType: 0, // 0-字符串匹配 1-正则匹配
  matchPath: '',
});

const Announcement = types
  .model({
    id: 0,
    title: '',
    content: '',
    link: '',
    linkText: '',
    status: 0,
    matchList: types.optional(types.array(MatchItem), []),
    effectiveStartTime: '',
    effectiveEndTime: '',
    closeable: false,
  })
  .views((self) => ({
    get effectiveStartTimeVo() {
      return self.effectiveStartTime ? dayjs(self.effectiveStartTime) : null;
    },
    get effectiveEndTimeVo() {
      return self.effectiveEndTime ? dayjs(self.effectiveEndTime) : null;
    },
    get withinEffectiveTime() {
      const now = dayjs();
      return (
        self.effectiveStartTimeVo &&
        self.effectiveEndTimeVo &&
        now.isAfter(self.effectiveStartTimeVo) &&
        now.isBefore(self.effectiveEndTimeVo)
      );
    },
  }))
  .actions((self) => ({
    updateFromAjaxJson(data) {
      safeAssign(self, data);
    },
    match(val) {
      return self.matchList.some((item) => {
        const { matchType, matchPath } = item;
        switch (matchType) {
          case 0:
            const url = URLParse(matchPath, true);
            val = URLParse(val, true);
            // console.log(
            //   'match announcement: ',
            //   url.pathname,
            //   val.pathname,
            //   url.query,
            //   val.query,
            //   url.pathname === val.pathname,
            //   similar(url.query, val.query, false)
            // );
            return (
              (url.pathname === val.pathname || pathToRegexp(url.pathname).test(val.pathname)) &&
              similar(url.query, val.query, false)
            );
          case 1:
            return new RegExp(matchPath).test(val);
          default:
            return false;
        }
      });
    },
  }));

export default Announcement;
