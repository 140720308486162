import { pick } from 'lodash';
import { types } from 'mobx-state-tree';
import { validate } from 'superstruct';

export const formInputValue = types.optional(types.maybeNull(types.union(types.number, types.string)), '');

export function createValidate(getSchema) {
  return types.model({}).views((self) => ({
    get validateResult() {
      const Schema = getSchema();
      return validate(pick(self, Object.keys(Schema.schema)), Schema);
    },
    get isValid() {
      return typeof self.validateResult[0] === 'undefined';
    },
  }));
}
