import { useCallback, useEffect, useRef, useState } from 'react';
import { createAjaxDescribe } from '../utils/createAjaxDescribe';
import useDestroyRef from './useDestroyRef';
import useStateDescribe from './useStateDescribe';

const keys = ['data', 'loading', 'error'];

export default function useAjaxStateDescribe(resolveParams, postConnect, preConnect, options) {
  const [describe, , , , , setDescribe] = useStateDescribe(null, true);
  const resolveParamsRef = useRef(resolveParams);
  const taskRef = useRef(null);
  const destroyRef = useDestroyRef();
  const [ajaxDescribe] = useState(() =>
    createAjaxDescribe((res) => resolveParamsRef.current(res), null, {
      ...options,
      postConnect,
      preConnect,
      setState(state, changeState) {
        let hasDifference = false;
        let difference = {};

        keys.forEach((key) => {
          if (key in changeState && changeState[key] !== state[key]) {
            hasDifference = true;
            difference[key] = changeState[key];
          }
        });

        if (hasDifference) {
          if (!destroyRef.current) {
            setDescribe((state) => ({ ...state, ...difference }));
          }
        }

        Object.assign(state, changeState);
      },
    })
  );

  const request = useCallback(
    (res) => {
      const stream = ajaxDescribe.next(res);
      taskRef.current = stream.toPromise();
      return stream;
    },
    [ajaxDescribe]
  );

  useEffect(() => {
    resolveParamsRef.current = resolveParams;
  }, [resolveParams]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => ajaxDescribe.unsubscribe, []);
  return [describe, request, setDescribe, taskRef];
}
