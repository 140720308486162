import { types } from 'mobx-state-tree';
import { getFeesValue } from '../Fee/Fee';
import PriceList from '../Fee/PriceList';

const FinanceApplicationFormFeeTemplate = types
  .model({
    priceList: types.optional(PriceList, {}),
  })
  .views((self) => ({
    get protectionFee() {
      return getFeesValue(Object.keys(self.extendedWarrantyFees).map((key) => self.extendedWarrantyFees[key]));
    },

    // 订单总价（含税）。不包含预付费和优惠。
    get totalFee() {
      return self.priceList.total;
    },

    // 实付费用：订单总费用 - 预付费 - 优惠
    get payFee() {
      return self.priceList.payAmount;
    },

    // 贷款金额
    get totalLienAmountForMdsp() {
      return self.loanScheme.scheme.totalLienAmountForMdsp; // mdsp会减去downPayment
    },
  }));

export default FinanceApplicationFormFeeTemplate;
