import React from 'react';
import PropTypes from 'prop-types';
import NP from 'number-precision';
import './style.less';

class CounterInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value,
      inputValue: this.props.value,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState(
        {
          value: this.props.value,
          inputValue: this.props.value.toString(),
        },
        this.handleChange
      );
    }
  }

  resolveInputValue = () => {
    const { inputValue } = this.state;
    let num = NP.strip(inputValue);
    num = num > this.props.max ? this.props.max : num;
    num = num < this.props.min ? this.props.min : num;

    return num;
  };

  decrement = () => {
    const { value } = this.state;
    const { min, step } = this.props;

    if (value <= min) {
      return;
    }

    if (this.props.onChange === undefined) return;

    this.setState((state) => {
      const value = NP.minus(state.value, step);
      return {
        value,
        inputValue: value.toString(),
      };
    }, this.handleChange);
  };

  increment = () => {
    const { value } = this.state;
    const { max, step } = this.props;

    if (value >= max) {
      return;
    }

    if (this.props.onChange === undefined) return;

    this.setState((state) => {
      const value = NP.plus(state.value, step);

      return {
        value,
        inputValue: value.toString(),
      };
    }, this.handleChange);
  };

  handleBlur = () => {
    const { value } = this.state;
    const num = this.resolveInputValue();

    if (isNaN(num) === true) {
      this.setState({ inputValue: value }, this.emitChangeInput);
    } else {
      this.setState(
        {
          value: num,
          inputValue: num.toString(),
        },
        this.handleChange
      );
    }
  };

  handleChange = () => {
    if (this.props.onChange !== undefined) {
      this.props.onChange(this.state.value);
      this.setState({
        inputValue: this.props.value.toString(),
      });
    }
  };

  handleInput = () => {
    if (this.props.onInput !== undefined) {
      this.props.onInput(this.resolveInputValue());
    }
  };

  emitChangeInput = () => {
    this.handleChangeInput({ target: { value: this.state.inputValue } });
  };

  handleChangeInput = ({ target: { value: inputValue } }) => {
    if (this.props.onChange === undefined) return;
    this.setState({ inputValue }, this.handleInput);
  };

  render() {
    return this.props.children({
      style: {
        wrapperStyle: {
          ...wrapperStyle,
          ...this.props.wrapperStyle,
        },
        btnStyle: {
          ...btnStyle,
          ...this.props.btnStyle,
        },
        inputStyle: {
          ...inputStyle,
          ...this.props.inputStyle,
        },
      },
      decrement: this.decrement,
      handleChangeInput: this.handleChangeInput,
      handleBlur: this.handleBlur,
      increment: this.increment,
      state: this.state,
    });
  }
}

const wrapperStyle = {
  display: 'flex',
  alignItems: 'center',
};

const btnStyle = {
  cursor: 'pointer',
  padding: 10,
};

const inputStyle = {
  width: 40,
  height: 20,
  background: 'none',
  border: 'none',
  padding: 5,
  textAlign: 'center',
  fontSize: '1em',
};

const renderChildren = ({ decrement, handleChangeInput, handleBlur, increment, state: { inputValue }, style }) => (
  <div style={style.wrapperStyle}>
    <div style={style.btnStyle} onClick={decrement}>
      &#8722;
    </div>
    <input style={style.inputStyle} type="text" value={inputValue} onChange={handleChangeInput} onBlur={handleBlur} />
    <div style={style.btnStyle} onClick={increment}>
      &#43;
    </div>
  </div>
);

CounterInput.defaultProps = {
  children: renderChildren,
  value: 0,
  step: 1,
  max: Infinity,
  min: -Infinity,
};

CounterInput.propTypes = {
  value: PropTypes.number,
  step: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  onInput: PropTypes.func,
  onChange: PropTypes.func,
};

function Counter({ className, ...props }) {
  // @todo 键盘和长按
  return (
    <CounterInput {...props}>
      {({ decrement, handleChangeInput, handleBlur, increment, state: { inputValue } }) => (
        <div className="counter flex flex--middle">
          <input
            className={'counter__input ' + (className || '')}
            type="text"
            value={inputValue}
            onChange={handleChangeInput}
            onBlur={handleBlur}
          />
          <div className="flex-grid flex--col">
            <div className="counter__inc" onClick={increment}></div>
            <div className="counter__dec" onClick={decrement}></div>
          </div>
        </div>
      )}
    </CounterInput>
  );
}

export { Counter };
export default CounterInput;
