import React, { useEffect, useRef, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import './index.css';

function calcEventRelativePos(event) {
  const rect = event.currentTarget.getBoundingClientRect();
  return {
    time: Date.now(),
    x: event.clientX - rect.left,
    y: event.clientY - rect.top,
  };
}

/**
 * @example
 * <button>
 *   <Ripple spawnData={spawnData} duration={500} />
 *   <span>{props.text}</span>
 * </button>;
 *
 * import { Ripple, calcEventRelativePos } from './ripple';
 * function onClick(event) {
 *   props.onClick && props.onClick();
 *   setSpawnData(calcEventRelativePos(event));
 * }
 */
function Ripple(props) {
  const isInit = useRef(true);
  const rippleEl = useRef(null);
  const configRef = useRef(null);
  const { spawnData, config } = props;
  configRef.current = {
    duration: props.duration || 800,
    ...config,
  };

  const [rippleAnim, api] = useSpring(() => ({
    opacity: 1,
    width: 0,
    height: 0,
    left: 0,
    top: 0,
    scale: 0,
  }));

  useEffect(() => {
    if (isInit.current) {
      isInit.current = false;
    } else {
      const parentEl = rippleEl.current.parentElement;

      const size = Math.max(parentEl.offsetWidth, parentEl.offsetHeight);
      const data = {
        height: size,
        top: spawnData.y - size / 2 || 0,
        left: spawnData.x - size / 2 || 0,
        width: size,
      };

      api
        .start({
          ...data,
          opacity: 1,
          scale: 0,
          immediate: true,
        })[0]
        .then(
          () =>
            api.start({
              scale: 3,
              opacity: 0,
              config: configRef.current,
            })[0]
        )
        .then(
          () =>
            api.start({
              width: 0,
              height: 0,
              immediate: true,
            })[0]
        );
    }
  }, [api, spawnData]);

  return <animated.span ref={rippleEl} className="g-ripple" style={rippleAnim} />;
}

function RippleIt({ tag, children, onClick, rippleProps, ...props }) {
  const Wrapper = tag || 'div';
  const [spawnData, setSpawnData] = useState({});
  function clickHandler(event) {
    onClick && onClick();
    setSpawnData(calcEventRelativePos(event));
  }

  return (
    <Wrapper {...props} onClick={clickHandler}>
      <Ripple spawnData={spawnData} {...rippleProps} />
      {children}
    </Wrapper>
  );
}

export { Ripple, RippleIt, calcEventRelativePos };
