import network from '@common/libs/network';

export function fetchVehicle(uuid) {
  return network(`/newApi/vehicle/${uuid}`);
}

export const sendToAdf = function (data) {
  return network(`/newApi/zapier/adf`, data, {
    method: 'post',
    // paramsFormat: 'raw',
    // checkValidate: network.checkCodeEqual__200,
  });
};