import { getEnv, getParent, types } from 'mobx-state-tree';
import PriceList from './PriceList';
import { Fee, FEE_TAXTYPE, FEE_CATEGORYTYPE, FEE_TYPE } from './Fee';

const PresetPrice = types.compose(
  Fee,
  types.model({
    IS_PRESET: types.optional(types.literal(true), true),
  })
);

const FormPriceList = types.compose(
  PriceList,
  types
    .model({})
    .views((self) => ({
      get env() {
        let parent;
        let env = getEnv(self);

        try {
          parent = getParent(self);
        } catch (e) {
          parent = {};
        }

        return {
          vehicle: parent.vehicle,
          order: parent,
          ...env,
        };
      },

      get depositMoney() {
        const { order } = self.env;
        return (order.hold.paymenttoken && order.hold.money) || 0;
      },

      get extendedWarrantyList() {
        const { order } = self.env;
        return Object.keys(order.extendedWarrantyFees).map((key) => order.extendedWarrantyFees[key]);
      },

      get presetCostList() {
        const { vehicle, order } = self.env;
        return self.extendedWarrantyList.concat(
          [
            // { name: 'GST 5%', ratio: 5, type: FEE_VALUETYPE.RATIO, taxType: FEE_TAXTYPE.GST },
            // { name: 'PST 8%', ratio: 8, type: FEE_VALUETYPE.RATIO, taxType: FEE_TAXTYPE.PST },
            // { name: 'HST 13%', ratio: 13, type: FEE_VALUETYPE.RATIO, taxType: FEE_TAXTYPE.HST },
            ...order.extraCharges.filter((fee) => fee.isTaxes).map((fee) => fee.toJSON()),

            {
              name: vehicle.trimTitle,
              value: vehicle.discountPrice,
              applyTaxType: FEE_TAXTYPE.HST,
              categoryType: FEE_CATEGORYTYPE.VEHICLE,
            },

            {
              name: 'Trade Allowance',
              value: order.tradeIn.tradeInValue * -1,
              applyTaxType: FEE_TAXTYPE.HST,
              categoryType: FEE_CATEGORYTYPE.VEHICLE_TRADEIN,
            },
            {
              name: 'Lien Payout',
              value: order.tradeIn.lienAmount,
              categoryType: FEE_CATEGORYTYPE.VEHICLE_TRADEIN,
            },
            { name: 'Cashback', value: order.tradeIn.cashback, categoryType: FEE_CATEGORYTYPE.VEHICLE_TRADEIN },

            { name: 'LESS: Promotional discount', value: order.coupon.discount, feeType: FEE_TYPE.DISCOUNT },
            order.couponTicket.id
              ? {
                  name: `LESS: Coupon${order.couponTicket.name ? `(${order.couponTicket.name})` : ''}`,
                  value: vehicle.discountPrice - order.couponTicket.toValue(vehicle.discountPrice),
                  feeType: FEE_TYPE.DISCOUNT,
                }
              : null,
            {
              name: `LESS: DEPOSIT(Online credit card)${
                order.hold.paymenttoken ? `\npay No: ${order.hold.paymenttoken}` : ''
              }`,
              value: self.depositMoney,
              feeType: FEE_TYPE.PRE,
            },
          ]
            .filter(Boolean)
            .map((fee) => PresetPrice.create(fee))
        );
      },

      get source() {
        return self.presetCostList.concat(self.list);
      },
    }))
    .actions((self) => ({
      setList(list) {
        self.list = list || [];
      },
    }))
);

export { PresetPrice };
export default FormPriceList;
