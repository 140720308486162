import classNames from 'classnames';
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { mergeFn } from '@common/utils';
import { safeRender, toVueSlots } from '@common/utils/slots';
import Icon from '@w/Icon';
import { presetControl } from './FormItemPreset';
import './FormItem.less';

function selectInputContent(e) {
  try {
    e.target.select();
  } catch (e) {}
}

function noop(val) {
  return val;
}

export default function FormItem(props) {
  let {
    children,
    controlType,
    prefixIcon,
    suffixIcon,
    prefixClassName,
    suffixClassName,
    coverLabel,
    label,
    colon,
    mode,
    readPrefix,
    readSuffix,
    noControlWrapper,
    labelStyle,
    placeholder,
    value,
    error,
    required,
    selectAtFocus,
    onChange,
    inputRef,
    containerClassName,
    labelClassName,
    style,
    ...inputProps
  } = props;
  const isControlled = 'value' in props;
  const controlRes = presetControl[controlType] || presetControl['default'];
  value = (controlRes.parser || noop)(value);

  const [stateValue, setStateValue] = useState(isControlled ? value : '');
  const [prevValue, setPrevValue] = useState();
  const [isFocus, setFocus] = useState();
  const changeHandler = useMemo(() => {
    if (onChange || !isControlled) {
      return function (e) {
        const value = controlRes.getEventValue(e);
        !isControlled && setStateValue(value);
        onChange && onChange(value);
      };
    }
  }, [controlRes, isControlled, onChange]);

  if (isControlled && value !== prevValue) {
    if (value !== stateValue) {
      setStateValue(value);
    }

    setPrevValue(value);
  }

  const scope = {
    ...inputProps,
    mode,
    ref: inputRef,
    placeholder: placeholder || ' ',
    value: stateValue,
    onChange: changeHandler,
  };

  scope.onFocus = mergeFn(function () {
    setFocus(true);
  }, scope.onFocus);
  scope.onBlur = mergeFn(function () {
    setFocus(false);
  }, scope.onBlur);

  if (selectAtFocus) {
    scope.onFocus = mergeFn(selectInputContent, scope.onFocus);
  }

  const $slots = toVueSlots(safeRender(children, scope));

  noControlWrapper = noControlWrapper || controlRes.noControlWrapper;
  label = $slots.label || label || '';
  prefixIcon = prefixIcon === null ? null : prefixIcon || controlRes.prefixIcon;
  suffixIcon = suffixIcon === null ? null : suffixIcon || controlRes.suffixIcon;

  const isOutSideLabel = labelStyle === 'top';
  const requiredMark = required ? <span className="color-theme">*</span> : null;
  const labelText = (
    <>
      {label}
      {colon ? ':' : null}
      {requiredMark}
    </>
  );
  const labelContent =
    labelStyle !== 'placeholder' && labelStyle !== 'line' ? (
      label ? (
        <div className={classNames('c-form-item__label', labelClassName)}>{labelText}</div>
      ) : null
    ) : (
      label &&
      (labelStyle === 'line' || !stateValue) && (
        <div className={classNames('c-form-item__label pos-a full-size', labelClassName)}>
          <div className="full-size flex flex--middle">{labelText}</div>
        </div>
      )
    );

  const isRead = mode === 'read';
  const controlContent = $slots.default || controlRes.render(scope);

  if (isRead) {
    return (
      <div className={classNames(['c-form-field flex', containerClassName])}>
        {labelContent ? <div className="c-form-field__label">{labelText}</div> : null}
        <div className="c-form-field__content flex-item">
          {readPrefix}
          {controlContent}
          {readSuffix}
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        'c-form-item-wrap',
        containerClassName,
        controlRes.containerClassName,
        'c-form-item-wrap--label-' + labelStyle,
        {
          'c-form-item-wrap--focus': isFocus,
          'c-form-item-wrap--error': !!error,
          'c-form-item-wrap--custom': noControlWrapper,
          'c-form-item-wrap--has-value': stateValue && stateValue.length,
        }
      )}
      style={style}
    >
      {isOutSideLabel && (
        <div className="flex flex--middle flex--wrap">
          <div
            className={
              'flex-item--small max-full-width' + (coverLabel && $slots.top ? ' c-form-item__label-wrap--hide' : '')
            }
          >
            {labelContent}
          </div>
          {$slots.top}
        </div>
      )}
      <div className="c-form-item flex-item flex-grid pos-r">
        {!isOutSideLabel && labelContent}
        {prefixIcon && (
          <div className="c-form-item__prefix-icon flex-grid">
            <Icon name={prefixIcon} />
          </div>
        )}
        {$slots.prefix ? (
          <div className={classNames(['c-form-item__prefix', prefixClassName])}>{$slots.prefix}</div>
        ) : null}
        {noControlWrapper ? (
          controlContent
        ) : (
          <div className="c-form-item__control flex-item full-width flex-item--stretch">{controlContent}</div>
        )}
        {$slots.suffix ? (
          <div className={classNames(['c-form-item__suffix', suffixClassName])}>{$slots.suffix}</div>
        ) : null}
        {suffixIcon && (
          <div className="c-form-item__suffix-icon flex-grid">
            <Icon name={suffixIcon} />
          </div>
        )}
      </div>
    </div>
  );
}

FormItem.propTypes = {
  containerClassName: PropTypes.string,
  noControlWrapper: PropTypes.bool, // 不使用默认样式
  coverLabel: PropTypes.bool, // 附加信息是否覆盖label。labelStyle: 'top'是有效
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelStyle: PropTypes.oneOf(['top', 'inner', 'placeholder', 'line']), // label的展示方式。
  placeholder: PropTypes.string,
  controlType: PropTypes.string, // 控件类型，见presetControl
  suffixIcon: PropTypes.string, // 后置icon
  children: PropTypes.node,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

FormItem.defaultProps = {
  labelStyle: 'top',
};
