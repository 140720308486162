import { of, Subject } from 'rxjs';
import { filter, take, catchError, skip, startWith, switchMap, tap, map } from 'rxjs/operators';
import { createStatusStream } from './helper/stream-helper';
// import { debug } from './rx-extend';

const NO = {};

function noop(s) {
  return s;
}

function subscribeStatusStreamOnce(observable, emitAtion) {
  let val = NO;

  observable = observable.pipe(skip(1));

  const subscription = observable.subscribe(function (value) {
    val = value;
  });

  emitAtion && emitAtion();
  subscription.unsubscribe();

  return observable.pipe(
    startWith(val),
    filter((val) => val !== NO),
    take(1)
  );
}

/**
 * 创建一个observable的状态描述。
 * @param {Function} subStreamFactory 生成子observable的工厂函数，入参是preConnect的发射值。
 * @param {Function} [onUpdate]    内部触发data更新时调用。函数签名 (state) => unkown。
 *                                 注意：
 *                                 1. onUpdate在初始化也会被调用一次，而且state.data === null。
 * @param {Object}   [options]
 * @param {Function} [options.concurrent]  是否允许并发
 * @param {Function} [options.postConnect] observable的后置连接，函数签名是：(observable, state, setErr, updateState) => observable
 * @param {Function} [options.preConnect]  observable的前置连接，函数签名是：(observable, state, setErr, updateState) => observable
 * @param {Function} [options.init]        初始化时会调用一次。函数签名是：(updateState, state) => unkown
 * @param {Function} [options.onDataUpdate] 内部触发data更新时调用。函数签名 (state) => unkown。
 * @param {Function} [options.setState]    state的更新逻辑，默认是Object.assign。函数签名是：(state, changeState) => unkown
 * @returns   statusDescribe   一个observable的状态描述，各个字段会根据实际状态进行详情变化，这在vue之类的响应式框架使用起来很容易。
 *                           但在mobx/MST之类则需要做额外的工作，因为它们规定state的变化必须在action中。
 *                           ajaxDescribe包含一下字段：
 *                           data:     子observable最终的返回值，初始值为null。
 *                           loading:  next()之后，直至子observable发射值之前保持为true，其余时间保持为false。
 *                           error:    最新一个错误。
 *                           inited:   如果已经发起过一个没有发生错误的ajax，则为true
 *                           initedAsync: initedd的异步版本
 *                           timeStamp: 最近data更新的时间戳
 *                           next:      一个方法，发射一个值，这个值是preConnect的入参。
 *                           unsubscribe: 一个方法，用于取消订阅，销毁ajaxDescribe。
 * @example
 * const statusDescribe = createStatusDataDescribe((id) => of(id).pipe(delay(5000), map(val => val + 's')));
 * console.log(statusDescribe); // {data: null, inited: false, loading: false, ....}
 * statusDescribe.next(5);
 * console.log(statusDescribe); // {data: null, inited: false, loading: true, ....}
 * setTimeout(function() {
 *   console.log(statusDescribe); // {data: '5s', inited: true, loading: false, ....}
 * }, 6000)
 */
export function createStatusDataDescribe(
  subStreamFactory,
  onUpdate,
  { postConnect, preConnect, init, setState, onDataUpdate, concurrent, _status } = {}
) {
  let state;
  const action$$ = new Subject();

  function _setState(state, changeState) {
    (setState || Object.assign)(state, changeState);
    // state._onUpdate && state._onUpdate(state);
  }

  const observable = createStatusStream(
    action$$,
    (stream, _state, setErr, updateState) => {
      state = _state;
      updateState({ timeStamp: Date.now() });
      init && init(updateState, state);

      return stream.pipe(
        // debug('start'),
        switchMap((res) =>
          (postConnect || noop)(
            (preConnect || noop)(of(res), state, setErr, updateState).pipe(
              switchMap(subStreamFactory),
              catchError((e) => {
                setErr(e);
                // console.error(e);
                return of(null);
              })
            ),
            state,
            setErr,
            updateState
          )
        ),
        // debug('end'),
        tap(() => updateState({ timeStamp: Date.now() }))
      );
    },
    concurrent,
    _setState,
    undefined,
    true,
    _status
  );

  let _data;
  const subscription = observable
    .pipe(
      tap((state) => {
        onUpdate && onUpdate(state);
        state._onUpdate && state._onUpdate.call(state, state);
      }),
      skip(1)
    )
    .subscribe(function (state) {
      _data = state.data;
      onDataUpdate && onDataUpdate(state.data);
    });

  return Object.assign(state, {
    next(res) {
      return subscribeStatusStreamOnce(
        observable.pipe(
          // map(() => _data)
          map((res) => ({
            ...res,
            data: _data,
          }))
        ),
        () => action$$.next(res)
      );
    },
    unsubscribe: subscription.unsubscribe.bind(subscription),
  });
}

/**
 * 用于mobx-state-tree的createStatusDataDescribe版本
 * @param {*} subStreamFactory 见createStatusDataDescribe。
 * @param {*} onUpdate 见createStatusDataDescribe。
 * @param {Object}   options              除以下配置项，其余见createStatusDataDescribe。
 * @param {Function} options.onSetState   state被更新是调用
 * @param {Function} options.runInAction  一个model的action，函数签名是：(fn) => fn()。内部用于修改state，因为MST对state的修改必须在action中进行。
 * @param {Function} options.getProxy     一个返回model的某个字段proxy的函数。
 *                   背景：createAjaxDescribe()的返回值应该赋值给model的某个字段，而MST内部使用了proxy/setter进行了代理，再使用for-in之类的进行赋值操作。
 *                   所以实际上并没有进行引用赋值，所以createAjaxDescribe内部实现不能通过修改原来的返回值来取得跟model的同步。
 * @returns statusDescribe
 */
export function createStatusDataDescribeForMST(subStreamFactory, onUpdate, options) {
  const { runInAction, getProxy, onSetState } = options;
  return Object.assign(
    {},
    createStatusDataDescribe(subStreamFactory, onUpdate, {
      ...options,
      setState: function (state, changeState) {
        Object.assign(state, changeState);

        runInAction(() => {
          getProxy() && Object.assign(getProxy(), changeState);
          onSetState && onSetState(state, changeState);
        });
      },
    })
  );
}
