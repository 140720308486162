import { types } from 'mobx-state-tree';
import { deepAssign } from '@common/utils/assignment';

const AssignmentHelper = types.model({}).actions((self) => ({
  deepAssign(path, data, iteration) {
    deepAssign(self, path, data, iteration, {
      get(key) {
        const val = self[key];
        return typeof val === 'object' ? JSON.parse(JSON.stringify(val)) : val;
      },
    });
  },
  setFrom(key, data) {
    self.deepAssign(key, data);
  },
  mergeFrom(key, data) {
    self.deepAssign(key, data, true);
  },
}));

export default AssignmentHelper;
