import { types } from 'mobx-state-tree';
import Announcement from './Announcement';

const AnnouncementList = types
  .model({
    list: types.optional(types.array(Announcement), []),
  })
  .actions((self) => ({
    updateFromAjaxJson(data) {
      self.list = data || [];
    },
  }));

export default AnnouncementList;
