import { types } from 'mobx-state-tree';
import { object } from 'superstruct';
import { createValidate, formInputValue } from './util';
import { DatePicker, FirstName, LastName, Phone, Email, StringOrNumber } from '../../utils/verification';

const Person = types.compose(
  types
    .model({
      firstname: formInputValue,
      lastname: formInputValue,
      phone: formInputValue,
      email: formInputValue,
      birth: formInputValue,
      license: formInputValue,
    })
    .views((self) => ({
      get fullName() {
        const { firstname, lastname } = self;
        return firstname + (firstname || lastname ? ' ' : '') + lastname;
      },
    })),
  createValidate(() => Person.verification)
);

// 个人信息
Person.verification = object({
  firstname: FirstName.required,
  lastname: LastName.required,
  phone: Phone.required,
  email: Email.maybe,
  birth: DatePicker.maybe,
  license: StringOrNumber.maybe,
});

export default Person;
