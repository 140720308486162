/**
 * @file 数据对齐类，用于同一含义的不同实例之间的数据对齐。
 *
 * # 背景
 * 当一份数据渲染在一或多个页面（像vue-router keep-live）的不同地方时，如果开发人员为图方便将这些数据独立实例化时，就会存在同步问题。
 * 即使大聪明的你可能想到将它提升到store（万一这份数据身份不足以提升到store呢）或者利用DelayDestroy实现为优化版本的单例，甚至利
 * 用vue-data-model的Identifier来保证实例唯一。但当要你考虑不同webview或浏览器标签页时，这就崩溃了吧？
 *
 * # 机制
 * 事件发布/订阅机制。
 * 如果有数据对齐（数据同步）的需要，则手动调用$registeredChannels(name)注册一个通道。
 * 内部重载了$assign，在$assign被执行时，会同时发布事件到事件总线。接下来同一个通道的其他实例会被执行一次$assign，以达到数据对齐。
 *
 * # 使用限制
 * 务必确保你的实体类是可以被序列化和反序列化的。
 */
import { types } from 'mobx-state-tree';
import Emiiter from '../../libs/network/emiiter';

function getRandomStr() {
  return Math.random().toString(36).slice(-8);
}

const WIN_RANDOM_STR = getRandomStr();

const emiiter = new Emiiter();
window.addEventListener('storage', (e) => {
  if (e.key === 'webview-align-event' || e.key === 'webview-align-request') {
    const res = JSON.parse(e.newValue);
    if ('payload' in res) {
      emiiter.emit(e.key, res);
    }
  }
});

let outSideeffect, buildOutSideEventObservable;

function publishData(vm, data, eventName = 'webview-align-event') {
  const res = {
    uid: vm.__dataalign_uid__,
    key: vm.__dataalign_key__,
    payload: vm,
    ...data,
  };

  vm.__dataalign_debug__ && console.log(`++  publish ${eventName} ++`, res);
  outSideeffect && outSideeffect(res); // 同步到app的其他webview
  emiiter.emit(eventName, res); // 同步到本地的其他实例
  localStorage.setItem(eventName, JSON.stringify(res)); // 同步到同一浏览器的其他tab的实例
  localStorage.setItem(eventName, JSON.stringify({ key: res.key }));
}

const assignPro = function () {
  Object.assign(this, ...arguments);
  if (this._from_event_) return;
  publishData(this);
};

const DataAlign = types.model({}).actions((self) => ({
  beforeDestroy() {
    self.$destroyChannels();
  },
  runInAction(fn) {
    fn.call(self);
  },
  // 注册通道
  $registeredChannels(name) {
    self.$destroyChannels();
    self.__dataalign_uid__ = WIN_RANDOM_STR + '-' + getRandomStr(); // 消息通道个体的唯一id
    self.__dataalign_key__ = name; //  // 消息通道的名称
    self.__dataalign_handler__ = ({ key, payload, uid, targetUid, signature }) => {
      self.runInAction(function () {
        if (key === name && payload !== self) {
          if (
            (targetUid === self.__dataalign_uid__ && signature === self._once_signature_) ||
            (!targetUid && self.__dataalign_uid__ !== uid)
          ) {
            self.__dataalign_debug__ &&
              console.log('##  from webview-align-event  ##', key, payload, self.__dataalign_uid__, uid);
            self._once_signature_ = '';
            self._from_event_ = true;
            self.$assign(payload.toJSON ? payload.toJSON() : payload);
            self._from_event_ = false;
          }
        }
      });
    };
    self.__dataalign_request_handler__ = ({ key, payload, uid }) => {
      if (key === name && self.__dataalign_uid__ !== uid) {
        self.__dataalign_debug__ &&
          console.log('##  from webview-align-request  ##', key, payload, self.__dataalign_uid__, uid);

        publishData(self, {
          targetUid: uid,
          signature: payload,
        });
      }
    };

    if (buildOutSideEventObservable) {
      self.__dataalign_subscription__ = buildOutSideEventObservable().subscribe({
        next: self.__dataalign_handler__,
      });
    }

    // 监听数据变更
    emiiter.on('webview-align-event', self.__dataalign_handler__);

    // 监听数据同步请求
    emiiter.on('webview-align-request', self.__dataalign_request_handler__);
  },

  // 发起一次同步请求
  $tryDataAlign() {
    publishData(
      self,
      {
        payload: (self._once_signature_ = getRandomStr()),
      },
      'webview-align-request'
    );
  },

  $destroyChannels() {
    if (!self.__dataalign_handler__) return;
    emiiter.off('webview-align-event', self.__dataalign_handler__);
    self.__dataalign_subscription__ && self.__dataalign_subscription__.unsubscribe();
    self.__dataalign_subscription__ = self.__dataalign_handler__ = null;
  },

  $assign() {
    assignPro.call(self, ...arguments);
  },
}));

DataAlign.debug = types.compose(
  DataAlign,
  types.model({}).actions((self) => ({
    afterCreate() {
      self.__dataalign_debug__ = true;
    },
  }))
);

/**
 * 设置非标准浏览器（一般指app）数据变更Observable的构建函数。即如何构建一个监听其它webview数据变更的Observable。
 * @param {Function} fn
 * @example
 * setOutSideEventObservableGenerator(function() {
 *    return onH5Event('webview-align-event');
 * });
 */
export function setOutSideEventObservableGenerator(fn) {
  buildOutSideEventObservable = fn;
}

/**
 * 设置非标准浏览器（一般指app）对外副作用的方式。即实例数据发生变化时，如何同步到其它webview。
 * 要求fn被调用时，其它webview的setOutSideEventObservableGenerator的fn()返回值（Observable）会发射{ key, payload, uid, targetUid, signature }。
 * @param {Function} fn
 * @example
 * setOutSideEffect(function(res) {
 *    publishH5Event('webview-align-event', res); // 同步到app的其他webview
 * });
 */
export function setOutSideEffect(fn) {
  outSideeffect = fn;
}

export default DataAlign;
