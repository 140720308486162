import { types } from 'mobx-state-tree';

const PackageItem = types.model({
  name: '',
  desc: '',
  price: 0,
  moreText: '',
  moreTemplate: '',
});

export const Package = types.model({
  name: '',
  shortDesc: '',
  price: 0,
  originalPrice: 0,
  contents: types.optional(types.array(PackageItem), []),
});

const CarPackageList = types
  .model({
    list: types.optional(types.array(Package), []),
  })
  .actions((self) => ({
    updateFromAjaxJson(data) {
      self.list = data || [];
    },
  }));

export default CarPackageList;
