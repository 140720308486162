import PropTypes from 'prop-types';
import styled from 'styled-components';
import './style.less';

const Container = ({ className, style, global, children }) => (
  <div className={className + ' c-spinner lds-ring' + (global ? ' lds-ring--global' : '')} style={style}>
    {children}
  </div>
);

function resolveCssVal(val) {
  return +val ? val + 'px' : 'px';
}

const SContainer = styled(Container)`
  ${({ size, border }) =>
    size
      ? `
    width: ${resolveCssVal(size)};
    height: ${resolveCssVal(size)};
    .lds-ring__inner {
      width: 100%;
      height: 100%;
      margin: 0;
      border-width: ${resolveCssVal(border || 2)};
    }`
      : ''}
`;

export default function Spinner(props) {
  return (
    <SContainer {...props}>
      <div className="lds-ring__inner"></div>
      <div className="lds-ring__inner"></div>
      <div className="lds-ring__inner"></div>
      <div className="lds-ring__inner"></div>
    </SContainer>
  );
}

Spinner.propTypes = {
  global: PropTypes.bool, // 使用.lds-ring--global样式
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // 自定义Spinner大小
  border: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // 自定义Spinner边框大小
};
