/**
 * @example
 * step 1:
 * 用ViewportProvider包住应用的根节点。
 * 可选地传入matchMedia进行“@media screen and (min-width: val)”的媒体查询。
 * 这与其它响应式css框架配合时很有用。例如pure.css grid。
 * <ViewportProvider matchMedia={{
 *   sm: '35.5em',
 *   md: '48em',
 *   lg: '64em',
 *   xl: '80em',
 * }}>
 *   <App />
 * </ViewportProvider>
 *
 * step 2:
 * 在任意子组件使用useViewport(...args<Number>)获得viewport信息。
 * res.width        当前window.innerWidth
 * res.height       当前window.innerHeight
 * res.matchMedia   当前媒体查询结果。如： {sm: true, md: false, lg: false, xl: false}
 * res.result       根据useViewport的参数，生成的结果列表，每个结果项是res.width >= item。 如：[true, true, false]
 *
 * function() {
 *   const {width, height, matchMedia, result} = useViewport(375, 640, 960);
 *   // 假如当前窗口宽度640px
 *   // => {width: 640, height: xxx, matchMedia: {sm: true, md: false, lg: false, xl: false}, result: [true, true, false]}
 *
 *   return (
 *     <div>
 *       <p>哈哈哈哈啊哈哈哈哈哈</p>
 *       {
 *         matchMedia.lg && <div>pc下才渲染</div>
 *       }
 *     </div>
 *   )
 * }
 *
 */

import React, {
  useRef,
  createContext,
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
} from 'react';
const viewportContext = createContext({});
const slice = Array.prototype.slice;
const emptyObject = {};
const list = [];

const htmlClassTemplate = 'app--';
const doc = document.documentElement;

function calculateMatchMedia(obj) {
  let determinedValue = false;

  list.splice(0);

  for (var key in obj) {
    list.push({ val: obj[key], key });
  }

  list.sort(function (a, b) {
    return parseFloat(b.val) - parseFloat(a.val);
  });

  return list.reduce(function (acc, item) {
    acc[item.key] = determinedValue =
      determinedValue || window.matchMedia(`(min-width:${item.val})`).matches;

    return acc;
  }, {});
}

// 添加class到html
function updateHtmlClassName(matchMedia) {
  const classKeyList = Object.keys(matchMedia);
  doc.classList.remove(
    ...classKeyList.map((key) => htmlClassTemplate + key),
    ...classKeyList.map((key) => htmlClassTemplate + 'not-' + key)
  );
  doc.classList.add(
    ...classKeyList.map((key) => (htmlClassTemplate + (matchMedia[key] ? '' : 'not-') + key))
  );
}

function shouldUpdateMatch(matchMedia, prevMatchMedia) {
  const classKeyList = Object.keys(matchMedia);
  classKeyList.sort();

  if (prevMatchMedia) {
    const prevClassKeyList = Object.keys(prevMatchMedia);
    prevClassKeyList.sort();

    if (prevClassKeyList.join('') === classKeyList.join('')) {
      for (var key in matchMedia) {
        if (matchMedia[key] !== prevMatchMedia[key]) return true;
      }

      return false;
    }
  }

  return true;
}

export const ViewportProvider = ({ children, matchMedia: sheet }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [matchMedia, setMatchMedia] = useState(calculateMatchMedia(sheet || emptyObject));
  const matchMediaRef = useRef(matchMedia);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);

    const matchMedia = calculateMatchMedia(sheet || emptyObject);

    if (shouldUpdateMatch(matchMedia, matchMediaRef.current)) {
      setMatchMedia(matchMedia);
      updateHtmlClassName(matchMedia);
    }
  };

  useEffect(() => {
    matchMediaRef.current = matchMedia;
  });

  useLayoutEffect(() => {
    updateHtmlClassName(matchMedia);

    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <viewportContext.Provider value={{ width, height, matchMedia }}>
      {children}
    </viewportContext.Provider>
  );
};

export const useViewport = function () {
  let args = slice.apply(arguments);
  const { width, height, matchMedia } = useContext(viewportContext);

  if (!args.length) {
    args.push(620);
  }

  return { width, height, matchMedia, result: args.map((val) => width >= val) };
};

export default useViewport;
