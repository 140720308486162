import { mapValues } from 'lodash';
import { getEnv, getParent, types } from 'mobx-state-tree';
import { calculateMdsp } from '../utils/calculate';

const Mdsp = types
  .model({
    downpay: 0,
    interestrate: 5.99,
    loanmonth: 90,
  })
  .views((self) => ({
    get car() {
      return getEnv(self).car || getParent(self);
    },
    get value() {
      const { car } = self;
      return Math.max(
        Math.round(
          calculateMdsp(
            car.totalLienAmountForMdsp || car.totalLienAmount || car.omnibusFee || car.discountPrice,
            self.downpay,
            self.interestrate,
            self.loanmonth
          )
        ),
        0
      );
    },
    get valueStr() {
      return self.value / 100;
    },

    get preTaxValue() {
      const { car } = self;
      return Math.round(calculateMdsp(car.discountPrice, self.downpay, self.interestrate, self.loanmonth));
    },
    get preTaxValueStr() {
      return self.preTaxValue / 100;
    },
  }))
  .actions((self) => ({
    from(res) {
      const fields = ['downpay', 'interestrate', 'loanmonth'];

      mapValues(res, (value, key) => {
        if (fields.indexOf(key) !== -1) {
          self[key] = value || 0;
        }
      });
    },
  }));

export default Mdsp;
