import { types } from 'mobx-state-tree';

export const INVALID_STATUS = 0;
export const VALID_STATUS = 1;

const Status = types.union(types.literal(INVALID_STATUS), types.literal(VALID_STATUS));

const CouponCode = types
  .model({
    id: 0,
    code: types.optional(types.string, ''),
    discount: types.optional(types.number, 0),
    status: types.optional(Status, INVALID_STATUS),
  })
  .views((self) => ({
    get isInvalid() {
      return self.status === INVALID_STATUS;
    },
  }))
  .actions((self) => ({
    updateFromAjaxJson(data) {
      if (data) {
        self.id = data.id || 0;
        self.code = data.code || '';
        self.discount = data.discount || 0;
        self.status = data.status || 0;
      }
    },
  }));

export default CouponCode;
