import emailjs from 'emailjs-com';

const emailController = {
  SERVER_DEFAULT: 'service_xytm5eq',
  SERVER_INVITE: 'service_qadwc9m',
  FINANCING_REQUEST: 'template_8e68d3e',
  CAR_CARE_TIPS: 'template_cw5ncps',
  INVITE: 'template_1th9xca',
  ORDER_CONFIRM: 'template_0a0gxyq',
  SELL_YOUR_EV: 'template_7aqlgu5',
  HOLD_ORDER: 'template_yuf1tpt',

  init() {
    emailjs.init('38QmpYgJeIWC9Z0EN');
  },

  send(templateId, params, serverId) {
    return emailjs.send(serverId || emailController.SERVER_DEFAULT, templateId, params);
  },
};

export default emailController;
