import { types } from 'mobx-state-tree';
import network from '@common/libs/network';
import { Person } from '@common/models/FinanceApplication';
import Location from '@common/models/FinanceApplication/Location';
import { pick } from 'lodash';
import { tryDispatchDomainEvent } from '@common/utils/describe-helper';
import LoginSuccessEvent from './events/login-success.domain-event';
import { rootStore } from '..';
import { checkReplenish } from '@/controller/loginController';

function reject(error) {
  return Promise.reject(error);
}

export function putUserInfo(res, config) {
  res = pick(res, User.propertyNames);
  return network(`/newApi/user/${res.email}`, res, {
    method: 'post',
    ...config,
  });
}

export function getUserInfo(email, config) {
  return network(`/newApi/user/${email}`, null, {
    headers: {
      EMAIL: email,
    },
    ...config,
  }).then((info) => {
    return { ...info, birth: (info && info.birth) || '' };
  });
}

export function getCurrentUserInfo(config) {
  return network(`/newApi/user`, null, config);
}

export function login(params) {
  return network('/newApi/auth/login', LoginParams.create(params).toJSON(), {
    method: 'post',
  });
}

export function logout(token) {
  return network('/newApi/auth/logout', null, {
    // checkValidate: network.checkCodeEqual__200,
    method: 'post',
    headers: {
      token: token,
    },
  });
}

export function register(params) {
  return network('/newApi/auth/register', RegisterParams.create(params).toJSON(), {
    method: 'post',
  });
}

function loginThenGetUser(info, loginSuccess) {
  return login({
    // accesstoken: info.accesstoken,
    email: info.email,
    password: info.password,
    type: info.type || 'local',
  }).then((res) => {
    loginSuccess && loginSuccess(res.auth);
    return res.user;
    // return getUserInfo(info.email);
  });
}

function registerThenLogin(params, loginSuccess) {
  return register(params).then(() => loginThenGetUser(params, loginSuccess));
}

function loginWithGoogle(access_token, loginSuccess) {
  return loginThenGetUser(
    {
      email: '',
      password: access_token, // 这个 password 是 google登录后返回的access_token
      type: 'google',
    },
    loginSuccess
  );
}

export const RegisterParams = types
  .model({
    // accesstoken: '',
    email: '',
    password: '',
    type: 'local',
    role: 'client',
    address: '',
    firstname: '',
    lastname: '',
    imgurl: '',
    phone: '',
  })
  .actions((self) => ({
    fullWithGoogle(googleProfileObj) {
      // self.accesstoken = googleProfileObj.googleId;
      self.email = googleProfileObj.email;
      self.password = googleProfileObj.googleId;
      self.type = 'google';
      self.firstname = googleProfileObj.givenName;
      self.lastname = googleProfileObj.familyName;
      self.imgurl = googleProfileObj.imageUrl;

      return self;
    },
  }));

export const LoginParams = types.model({
  // accesstoken: types.string,
  email: types.string,
  password: types.string,
  type: '',
});

export const User = types.compose(
  Person,
  Location,
  types
    .model({
      // accesstoken: '',
      imgurl: '',
      password: '',
      role: '',
      type: '',
      id: 0,
      code: '', // 邀请活动码
    })
    .views((self) => ({
      // 需要完善资料
      get needReplenish() {
        return !self.phone;
      },
      get firstCharacter() {
        return self.firstname[0] || self.lastname[0] || 'T';
      },
    }))
);

export const Auth = types
  .model({
    token: '',
    tokenType: '',
    user: types.maybe(User),
    isHttpLoading: false,
  })
  .views((self) => ({
    get tokenStr() {
      return self.tokenType + ' ' + self.token;
    },
    get tokenConfig() {
      return {
        headers: { Authorization: self.tokenStr },
      };
    },
    get isLogin() {
      return !!(self.token && self.user && self.user.email && !self.user.needReplenish);
    },
  }))
  .actions((self) => ({
    _resetLoading() {
      self.isHttpLoading = false;
    },

    _doLoginAction(action) {
      self.isHttpLoading = true;
      return action.then(self.setUser, (err) => {
        self._resetLoading();
        return reject(err);
      });
    },

    _checkReplenish(res) {
      return checkReplenish().then(() => res);
    },

    _publishLoginEvent(res) {
      return tryDispatchDomainEvent(LoginSuccessEvent, null, rootStore).then(() => res);
    },

    setAuth(auth) {
      self.token = auth.access_token || '';
      self.tokenType = auth.token_type || '';
      rootStore.storage.set('gauth', pick(self, ['token', 'tokenType']));
    },

    setUser(user) {
      self.user = user || {};
      self._resetLoading();
      return user;
    },

    getCurrentUser(config) {
      return self._doLoginAction(getCurrentUserInfo(config));
    },

    getUser(config) {
      return self._doLoginAction(getUserInfo(self.user ? self.user.email : '', config));
    },

    putUser(res) {
      return self._doLoginAction(
        putUserInfo(res, {
          headers: { Authorization: self.tokenStr },
        }).then(() => self.getUser(self.tokenConfig))
      );
    },

    logout() {
      if (!self.token) return Promise.resolve();

      return self._doLoginAction(logout(self.token).then(() => self.setAuth({})));
    },

    login(res) {
      return self
        ._doLoginAction(loginThenGetUser(res, self.setAuth))
        .then(self._checkReplenish)
        .then(self._publishLoginEvent);
    },

    registerThenLogin(res) {
      return self
        ._doLoginAction(registerThenLogin(res, self.setAuth))
        .then(self._checkReplenish)
        .then(self._publishLoginEvent);
    },

    loginWithGoogle(access_token) {
      return self
        ._doLoginAction(loginWithGoogle(access_token, self.setAuth))
        .then(self._checkReplenish)
        .then(self._publishLoginEvent);
    },
  }));
