import { createContext } from 'react';
import { EventBus } from 'domain-events';
import createUseMST from '@common/utils/create-use-mst';
import RootStore from './Root';
import Car from './Car';
import Collection from '@common/models/Collection';
import SharedMemory from '@common/models/SharedMemory';
import { Auth } from './Auth';
// import { DEV_MODE, MOCK_MODE } from '@/env';

const rootStoreContext = createContext({});
const rootStore = RootStore.create(
  {
    ui: {},
    car: Car.create(),
    collection: Collection.create({ list: [] }),
    auth: Auth.create(),
    sharedMemory: SharedMemory.create(),
    feeList: {},
    orderList: { list: [] },
    invitationCouponList: { list: [] },
  },
  {
    eventBus: new EventBus(),
  }
);

export function RootStoreProvider({ children }) {
  return <rootStoreContext.Provider value={rootStore}>{children}</rootStoreContext.Provider>;
}

export async function initRootStore() {
  await rootStore.init();

  // if (MOCK_MODE) {
  //   await rootStore.auth.login({
  //     accesstoken: '',
  //     email: 'zhiqinyigu@gmail.com',
  //     password: DEV_MODE ? '103512599255808536233' : '103512599255808536233',
  //     type: 'local',
  //   });
  // }

  return rootStore;
}

const useRootStore = createUseMST(() => rootStoreContext);

export { rootStore };
export default useRootStore;
