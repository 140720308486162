import { types } from 'mobx-state-tree';
import CarBase from '@common/models/Car';
import { generateDescribeModule } from '@common/utils/describe-helper';
import { fetchVehicle } from '@/services/vehicle';
import { rootStore } from '.';
import { createAjaxDescribe } from '@common/utils/createAjaxDescribe';

const carServers = [
  generateDescribeModule(
    'ajaxDescribe',
    [
      createAjaxDescribe,
      function (uuid) {
        return fetchVehicle(uuid || this.uuid).then((data) => {
          data.mdsp = {
            interestrate: rootStore.systemSetting.interestrate,
          };
          return data;
        });
      },

      function (data) {
        this.updateFromAjaxJson(data);
      },
    ],
    '_load'
  ),
];

export default types.compose(
  ...carServers,
  // AjaxDescribeHelper,
  CarBase,
  types
    .model({})
    .views((self) => ({
      get isAjaxLoading() {
        return self.ajaxDescribe.loading;
      },
      get ajaxError() {
        return self.ajaxDescribe.error;
      },
    }))
    .actions((self) => ({
      load(uuid) {
        return self._load(uuid || self.uuid).toPromise();
      },
    }))
);
