/* eslint no-empty: "off" */
import { get } from 'lodash';
import { tryJsonParse } from '.';

function packTimelinessData(data, exp) {
  return JSON.stringify({
    e: Date.now() + exp * 1000,
    v: data,
  });
}

function getTimelinessData(data) {
  const json = tryJsonParse(data);
  if (json && typeof json.e === 'number' && 'v' in json) {
    return json;
  }

  return false;
}

/**
 * @module storage
 * @description stroage工具函数，兼容小程序
 */

// 一个无法持久化的storage hack，应对safari这类隐身模式，调用localStorage直接报错的问题。
function createHack() {
  let monitor;
  let inMemoryStorage = {};
  let storageHack = {
    setItem(name, val) {
      monitor && monitor('set', name, val);
      inMemoryStorage[name] = String(val);
    },
    getItem(name) {
      monitor && monitor('get', name);
      if (Object.prototype.hasOwnProperty.call(inMemoryStorage, name)) {
        return inMemoryStorage[name];
      }
      return null;
    },
    removeItem(name) {
      monitor && monitor('remove', name);
      delete inMemoryStorage[name];
    },
    setMonitor(fn) {
      monitor = fn;
    },
  };

  const isSupported = (function () {
    try {
      const testKey = '__is_safari_private__';
      localStorage.setItem(testKey, 1);
      localStorage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  })();

  return { storageHack, isSupported };
}

const { storageHack, isSupported } = createHack();

function getStorage(useSessionStorage) {
  return isSupported ? (useSessionStorage ? sessionStorage : localStorage) : storageHack;
}

export { storageHack };

/**
 * 删除所有过期的storage
 */
export function deleteAllExpires(useSessionStorage) {
  const storage = getStorage(useSessionStorage);
  for (const key in storage) {
    if (Object.hasOwnProperty.call(storage, key)) {
      const timelinessData = getTimelinessData(storage[key]);
      if (timelinessData !== false && timelinessData.e < Date.now()) {
        removeLocalStorage(key, useSessionStorage);
      }
    }
  }
}

/**
 * @description setLocalStorage
 * @param {String} storageKey 缓存的唯一key值
 * @param {any} val
 * @param {Boolean} useSessionStorage 是否使用sessionStorage
 * @param {Object}  config      高级配置
 * @param {Number}  config.exp  多少秒后过期
 * @returns {null}
 * @example
 * setLocalStorage(storageKey, val, useSessionStorage, config);
 * setLocalStorage(storageKey, val, useSessionStorage);
 * setLocalStorage(storageKey, val, config);
 */
export function setLocalStorage(storageKey, val, useSessionStorage, config) {
  if (typeof useSessionStorage === 'object') {
    config = useSessionStorage;
    useSessionStorage = false;
  }

  if (config && typeof config.exp === 'number') {
    val = packTimelinessData(val, config.exp);
  }

  getStorage(useSessionStorage).setItem(storageKey, val);
}

/**
 * @description getLocalStorage
 * @param {String} storageKey 缓存的唯一key值
 * @param {Boolean} consume 是否查询一次后立即移除标记
 * @param {Boolean} useSessionStorage 是否使用sessionStorage
 * @returns {any} 缓存的数据
 */
export function getLocalStorage(storageKey, consume, useSessionStorage) {
  let val = getStorage(useSessionStorage).getItem(storageKey);
  const timelinessData = getTimelinessData(val);

  if (timelinessData !== false) {
    if (timelinessData.e < Date.now()) {
      val = undefined;
      removeLocalStorage(storageKey, useSessionStorage);
    } else {
      val = timelinessData.v;
    }
  }

  if (consume) {
    removeLocalStorage(storageKey, useSessionStorage);
  }

  return val;
}

/**
 * @description removeLocalStorage
 * @param {any} storageKey
 * @param {any} useSessionStorage 是否使用sessionStorage
 * @returns {undefined}
 */
export function removeLocalStorage(storageKey, useSessionStorage) {
  return getStorage(useSessionStorage).removeItem(storageKey);
}

/**
 * @description 修改json字符串的内容
 * @param {String} str 待修改的json字符串
 * @param {Function} doing 修改的操作
 * @returns {String} 修改后的json字符串
 */
export function modifyJsonString(str, doing) {
  const obj = JSON.parse(str || '{}');
  doing(obj);
  return JSON.stringify(obj);
}

/**
 * 修改storage中的json字符串的内容
 * @param {String} storageKey 缓存的唯一key值
 * @param {Function} doing 修改的操作
 * @param {Boolean} useSessionStorage 是否使用sessionStorage
 * @returns {Object} 修改后的值内容
 */
export function modifyStorage(storageKey, doing, useSessionStorage) {
  let res;
  setLocalStorage(
    storageKey,
    modifyJsonString(getLocalStorage(storageKey, false, useSessionStorage), function (obj) {
      res = doing(obj);
    }),
    useSessionStorage
  );

  return res;
}

/**
 * 创建一个uid的modifyStorage版本
 * @param {String} uid 唯一id
 * @param {Array} path 预设的字段访问路径
 * @param {Boolean} useSessionStorage 是否用sessionStorage访问
 * @returns Function 操作函数。函数签名(key, doing)或(doing)。key为要操作的字段名称，doing的返回值将作用到storage，返回undefined视为删除。
 * @example
 * const uidModifyStorage = uidModifyStorageGenerator(userKey, ['course']);
 * uidModifyStorage('123', function(json) {
 *  // dosomething.....
 *  return json;
 * })
 */
export function uidModifyStorageGenerator(uid, path, useSessionStorage) {
  path = path || [];
  return function (key, doing) {
    if (arguments.length < 2) {
      doing = key;
      key = '';
    }

    const _pathList = path.concat(typeof key === 'function' ? key() : key).filter((str) => str !== '');
    const _path = _pathList.join('.');

    return modifyStorage(
      uid,
      (json) => {
        const val = doing(get(json, _path));

        // 返回undefined视为删除
        if (typeof val === 'undefined') {
          const res = _pathList.length === 1 ? json : get(json, _pathList.slice(0, -1).join('.'));
          if (res) {
            delete res[_pathList[_pathList.length - 1]];
          }
        }
        // 否则视为赋值
        else {
          get(json, _path, val);
        }

        return json;
      },
      useSessionStorage
    );
  };
}

deleteAllExpires();
