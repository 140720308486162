import { flattenDeep, get, groupBy } from 'lodash';
import { cloneElement } from 'react';

function toArray(children) {
  if (!Array.isArray(children)) {
    return [children];
  }

  return children;
}

function flattenChildren(children) {
  if (
    children &&
    typeof children === 'object' &&
    children.type &&
    typeof children.type.toString !== 'undefined' &&
    children.type.toString().indexOf('react.fragment') !== -1
  ) {
    children = flattenChildren(children.props.children);
  }

  if (Array.isArray(children)) {
    return children.map(flattenChildren);
  }

  return children;
}

function renderItem(item, args) {
  if (typeof item === 'function') {
    return item.apply(null, args);
  } else {
    return item;
  }
}

/**
 * 有时候props.children可能是一个函数，这时候不能直接用toVueSlots，需要先将其渲染。
 * @param {Function|Array|Object} slot
 * @returns
 * @example
 * <Modal>
 *  {(props) => (<>
 *  <div>默认内容</div>,
 *  <div slot="title">标题</div>,
 *  <div slot="footer">脚部</div>
 * </>)}
 * </Modal>
 *
 * // Modal的渲染函数：
 * render({children}) {
 *  const scope = {};
 *  const $slots = toVueSlots(safeRender(children, scope));
 * }
 */
export function safeRender(slot) {
  const args = Array.prototype.slice.call(arguments, 1);

  if (Array.isArray(slot)) {
    return slot.map((item) => renderItem(item, args));
  } else {
    return renderItem(slot, args);
  }
}

/**
 * 将react组件的children转成vue slot的格式。
 * @param {Array|Object} children react渲染对象或包含react渲染对象的数组
 * @returns Object
 * @example
 * <Modal>
 *  <div>默认内容</div>
 *  <div slot="title">标题</div>
 *  <div slot="footer">脚部</div>
 * </Modal>
 *
 * // Modal的渲染函数：
 * render({children}) {
 *  const $slots = toVueSlots(children);
 *
 *  return (
 *    <div>
 *      <div className="header">
 *        {$slots.title}
 *      </div>
 *      <div className="body">
 *        {$slots.default}
 *      </div>
 *      <div className="footer">
 *        {$slots.footer}
 *      </div>
 *    </div
 *  )
 * }
 */
export function toVueSlots(children, scope) {
  children = safeRender(children, scope);
  children = flattenChildren(children);

  const group = groupBy(flattenDeep(toArray(children)), (item) => get(item, 'props.slot') || 'default');

  for (var key in group) {
    const vaildChildren = group[key]
      .filter((c) => c !== null && typeof c !== 'undefined')
      .map((c) => {
        return c.props && c.props.slot ? cloneElement(c, { slot: undefined }) : c;
      });

    if (vaildChildren.length <= 1) {
      group[key] = vaildChildren[0];
    } else {
      group[key] = vaildChildren;
    }
  }

  return group;
}
