import { types } from 'mobx-state-tree';

const FormValue = types.optional(types.union(types.string, types.number), '');

const License = types.model({
  licenseOwner: '',
  licenseOwnerIndex: FormValue,
  vehicleOwnerIndex: FormValue,
  number: '',
  vehicleOwner: '',
  rinNumber: '',
  plateNumber: '',
  ownershipNumber: '',
  registeredProvince: '',
  plate: '',
});

const LicenseInfo = types.model({
  major: types.optional(License, {}),
  secondary: types.optional(License, {}),
});

export default LicenseInfo;
