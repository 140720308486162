import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react-lite';
import { Tabs, TabPanel, Tab, TabList } from 'react-tabs';
import { safeRender } from '@common/utils/slots';
import Modal from '@common/components/Modal';
import useRootStore from '@/models';
import Icon from '../Icon';
import LoginPanel, { toastLoginError } from './LoginPanel';
import RegisterPanel, { toastRegisterError } from './RegisterPanel';
import './style.less';

export function LoginPanelContent({ defaultIndex, onClose, onSuccess }) {
  return (
    <Tabs className="login-tab flex flex--col" forceRenderTabPanel defaultIndex={defaultIndex || 0}>
      <div className="login-dialog__head flex-grid">
        <TabList className="carnex-tab flex-grid">
          <Tab className="carnex-tab__item text-bold" selectedClassName="carnex-tab__item--active">
            Log in
          </Tab>
          <Tab className="carnex-tab__item text-bold" selectedClassName="carnex-tab__item--active">
            Sign up
          </Tab>
        </TabList>

        <div className="flex-item flex flex--middle flex--right">
          <Icon className="cursor-pointer" name="close" onClick={onClose} />
        </div>
      </div>

      <TabPanel className="login-dialog__body react-tabs__tab-panel flex-item">
        <LoginPanel onSuccess={onSuccess} onFail={toastLoginError} />
      </TabPanel>
      <TabPanel className="login-dialog__body react-tabs__tab-panel flex-item">
        <RegisterPanel onSuccess={onSuccess} onFail={toastRegisterError} />
      </TabPanel>
    </Tabs>
  );
}

const Login = observer(function Login({ value, defaultIndex, children, autoJump, onChange, onSuccess, onClose }) {
  const store = useRootStore();
  const history = useHistory();
  const jumpProfile = useCallback(
    (res) => {
      onChange(false);
      onSuccess && onSuccess(res);
      autoJump !== false && history.push('/profile/hold');
    },
    [autoJump, history, onChange, onSuccess]
  );
  const close = useCallback(() => onChange(false), [onChange]);

  const DefaultContent = <LoginPanelContent defaultIndex={defaultIndex} onSuccess={jumpProfile} onClose={close} />;
  children = safeRender(children, DefaultContent, { store }) || DefaultContent;

  return (
    <Modal dialogClass="login-dialog" value={value} noClose floatHead onChange={onChange} onClose={onClose}>
      {children}
    </Modal>
  );
});

export default Login;
