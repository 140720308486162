let jumpMethod = (url, useReplace) => {
  if (useReplace) {
    window.location.replace(url);
  } else {
    window.location.href = url;
  }
};

export function reloadJumpMethods(func) {
  jumpMethod = func(jumpMethod);
}

/**
 * @param {string} href 代理 location.href 路由跳转
 * @param {boolean} useReplace 使用location.replace()
 */
export function locationHref(href, useReplace, ...args) {
  jumpMethod(href, useReplace, ...args);
}
