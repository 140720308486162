import { types } from 'mobx-state-tree';
import { object } from 'superstruct';
import { NotLessThanZero, StringOrNumber } from '../../utils/verification';
import { defineFormValidate } from '../../utils/helper/superstruct-helper';
import { createValidate, formInputValue } from './util';

// 结算方式
export const settlementTypeMap = {
  UNKNOWN: -1,
  CASH: 0,
  FINANCE: 1,
};

export const settlementTypeMapOptions = [
  { label: '-', value: settlementTypeMap.UNKNOWN },
  { label: 'Finance', value: settlementTypeMap.FINANCE },
  { label: 'Cash', value: settlementTypeMap.CASH },
];

export const Settlement = types.compose(
  types.model({
    type: types.optional(
      types.union(
        types.literal(settlementTypeMap.UNKNOWN),
        types.literal(settlementTypeMap.CASH),
        types.literal(settlementTypeMap.FINANCE)
      ),
      settlementTypeMap.UNKNOWN
    ),
    interestrate: 5.99,
    downPayment: types.optional(types.union(types.number, types.string), 0),
    financeTerm: formInputValue,
    monthly: 0,
  }),
  createValidate(() => Settlement.verification)
);

Settlement.verification = object({
  type: defineFormValidate(
    'settlementType',
    (value) => value === settlementTypeMap.CASH || value === settlementTypeMap.FINANCE,
    'Please choose the settlement type'
  ).required,
  interestrate: StringOrNumber.maybe,
  monthly: StringOrNumber.maybe,
  downPayment: NotLessThanZero.optional,
  financeTerm: StringOrNumber.optional,
});

export default Settlement;
