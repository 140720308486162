import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { find, pick } from 'lodash';
import { createAjaxDescribe, createAjaxDescribeForMST } from './createAjaxDescribe';

function noop(s) {
  return s;
}

const defaultPagingOptions = {
  pageNoName: 'page',
  pageSizeName: 'size',
  firstPageValue: 0,
  onData(data) {
    return {
      data: data ? data.content : null,
      pageNo: data ? data.index : 0,
      maxPage: data ? data.pages : Infinity,
    };
  },
  onEffect() {},
  concatData(state, data) {
    return state.data.concat(data);
  },
};

function createAjaxPagingDescribeFactory(createAjaxDescribe) {
  /**
   * 创建一个分页请求的ajaxDescribe。
   * @param {Function} resolveParams 见createAjaxDescribe
   * @param {Function} onUpdate      见createAjaxDescribe
   * @param {Object}   options  配置
   * @param {String}   [options.postConnect]    见createAjaxDescribe
   * @param {String}   [options.preConnect]     见createAjaxDescribe
   * @param {String}   pagingOptions                             分页配置
   * @param {String}   [pagingOptions.pageNoName='pageNo']       页码的参数名
   * @param {String}   [pagingOptions.pageSizeName='pageSize']   页码大小的参数名
   * @param {String}   [pagingOptions.firstPageValue=1]          第一页是什么值。有效值是0或1，有些团队喜欢数组索引表示，有些团队喜欢直接用数字表示。
   * @param {Function} [pagingOptions.onData]                    用于解析分页结构。需要返回一个包含三个字段的对象data，pageNo，maxPage。参考defaultPagingOptions.onData。
   * @param {Function} [pagingOptions.concatData]                拼接新数据的逻辑，签名(state, data) => []。默认是(state, data) => state.data.concat(data)。
   * @param {Function} [pagingOptions.onEffect]                  state.data发生变化是的回调，签名(data, type) => {}。type是'set'或'add'。
   * @returns   ajaxDescribe   比AjaxDescribe多出一些字段：
   *                          pageNo：  当前页码
   *                          maxPage： 最大页码
   *                          totalCount： 数据总数量
   *                          reload：  是否属于清空数据更新。请求第一页时属于清空数据更新。
   *                          pager：   一个基于上次请求参数，进行页码更新的请求方法。函数签名(pageNo, pageSize) => observable
   *                          nextPage：请求下一页，如果没有下一页（根据已有pageNo和pageSize来决定）则不发起请求。函数签名() => observable
   */
  return function createAjaxPagingDescribe(resolveParams, onUpdate, options, pagingOptions) {
    options = options || {};
    let prevParams = {};
    let _state;
    const { postConnect, preConnect, init: _init } = options;
    const { pageNoName, pageSizeName, onData, onEffect, concatData, firstPageValue } = Object.assign(
      {},
      defaultPagingOptions,
      pagingOptions || {}
    );
    const init = (updateState, state) => {
      _state = state;
      updateState({
        pageNo: firstPageValue,
        maxPage: Infinity,
        totalCount: 0,
        reload: true,
      });
      _init && _init(updateState, state);
    };

    const ajaxDescribe = createAjaxDescribe(resolveParams, onUpdate, {
      ...options,
      init,
      postConnect(stream, state, setErr, updateState) {
        return (postConnect || noop)(
          stream.pipe(
            // debug('>>>'),
            map((json) => {
              const { data, ...changeState } = onData(json, _state, ajaxDescribe);
              updateState(changeState);

              if (data) {
                if (state.reload || !state.data) {
                  onEffect(data, 'set');
                  return data;
                } else {
                  onEffect(data, 'add');
                  return concatData(state, data);
                }
              }

              return state.data;
            })
          )
        );
      },
      preConnect(stream, state, setErr, updateState) {
        return (preConnect || noop)(stream).pipe(
          tap((res) => {
            updateState({
              reload: res.$isPagerReload || res[pageNoName] === firstPageValue,
            });
            delete res.$isPagerReload;
          })
        );
      },
    });

    function buildPagerParams(pageNo, pageSize) {
      return {
        ...prevParams,
        ...{ [pageNoName]: pageNo },
        ...(typeof pageSize !== 'undefined' ? { [pageSizeName]: pageSize } : {}),
      };
    }

    const next = (function (next) {
      return function (res) {
        if (!res) {
          return ajaxDescribe.pager(firstPageValue);
        }

        ajaxDescribe.setParams(res);
        return next(res);
      };
    })(ajaxDescribe.next);

    ajaxDescribe.setParams = function (res) {
      return (prevParams = res);
    };
    ajaxDescribe.next = next;
    ajaxDescribe.nextPage = function () {
      return _state.maxPage + firstPageValue - 1 > _state.pageNo ? ajaxDescribe.pager(_state.pageNo + 1) : of(null);
    };

    ajaxDescribe.getPagerParams = function () {
      return {
        pageNo: prevParams[pageNoName] || _state.pageNo,
        pageSize: prevParams[pageSizeName],
      };
    };
    ajaxDescribe.pager = function (pageNo, pageSize) {
      return next(buildPagerParams(pageNo, pageSize));
    };
    ajaxDescribe.load = function (pageNo, pageSize) {
      return next({ ...buildPagerParams(pageNo, pageSize), $isPagerReload: true });
    };
    ajaxDescribe.changePageSize = function (size) {
      const { pageNo, pageSize } = ajaxDescribe.getPagerParams();
      ajaxDescribe.pageNo = pageSize / size + firstPageValue - 1;
      ajaxDescribe.maxPage = (ajaxDescribe.maxPage * pageSize) / size;
      return ajaxDescribe.setParams(buildPagerParams(pageNo, size));
    };

    return ajaxDescribe;
  };
}

/**
 * 创建一个分页请求的ajaxDescribe。
 * @param {Function} resolveParams 见createAjaxDescribe
 * @param {Function} runInAction   见createAjaxDescribe
 * @param {Function} getProxy      见createAjaxDescribe
 * @param {Function} onUpdate      见createAjaxDescribe
 * @param {Function} postConnect   见createAjaxDescribe
 * @param {Function} preConnect    见createAjaxDescribe
 * @param {Object}   options  配置
 * @param {String}   [options.pageNoName='page']     页码的参数名
 * @param {String}   [options.pageSizeName='size']   页码大小的参数名
 * @param {String}   [options.firstPageValue=0]      第一页是什么值。有效值是0或1，有些团队喜欢数组索引表示，有些团队喜欢直接用数字表示。
 * @param {Function} [onData]                        用于解析分页结构。需要返回一个包含三个字段的对象data，pageNo，maxPage。参考defaultPagingOptions.onData。
 * @returns   ajaxDescribe   比AjaxDescribe多出一些字段：
 *                          pageNo：  当前页码
 *                          maxPage： 最大页码
 *                          reload：  是否属于清空数据更新。请求第一页时属于清空数据更新。
 *                          pager：   一个基于上次请求参数，进行页码更新的请求方法。函数签名(pageNo, pageSize) => observable
 *                          nextPage：请求下一页，如果没有下一页（根据已有pageNo和pageSize来决定）则不发起请求。函数签名() => observable
 */
const createAjaxPagingDescribe = createAjaxPagingDescribeFactory(createAjaxDescribe);
const createAjaxPagingDescribeForMST = createAjaxPagingDescribeFactory(createAjaxDescribeForMST);

/**
 * 实在不知道起什么名字，直接说它要解决的问题：
 * createAjaxPagingDescribe是分页特性的，每次加载后，已经加载的数据会存在内存里。
 * 加载后续的分页数据时，会将内存的数据一起拼成一个完整的已知页码的所有数据（list）返回。
 * 如果是简单是没什么问题的，但在vue-data-model里应用，就很容易出问题：
 * 1. 首先对性能不好，每次加载分页，都要将整一个列表重新实例化。
 * 2. 既然都用了vue-data-model，那每个list的item是一个功能完整的实体类也是常事了，当这些实体类
 *    发生了自我更新时（例如从状态A变到B，或数值A变到B），这个更新肯定不会同步到createAjaxPagingDescribe
 *    的内存里的，这样list一重新赋值，B就变回A了。
 *
 * 所以在一些场景，我们就将消费createAjaxPagingDescribe.data的逻辑放在createAjaxPagingDescribe options onEffect里，
 * 进行更细致的操作，才避免上面两个问题。
 *
 * @param {Object} vm types.vue实例
 * @param {String} field 操作哪个字段
 * @param {Array|String} [uniqList] 如果有去重需求，列出能标识唯一性的字段
 * @param {Function}   [translator] 数据清洗逻辑
 * @returns Function  可用于onEffect的方法
 */
function setListWithEffect(vm, field, uniqList, translator) {
  translator = translator || noop;
  return function (list, type) {
    if (type === 'add') {
      if (typeof uniqList === 'string') {
        uniqList = [uniqList];
      }
      vm[field].push(...translator(uniqList ? list.filter((item) => !find(vm[field], pick(item, uniqList))) : list));
    } else {
      vm[field] = translator(list);
    }
  };
}

createAjaxPagingDescribe.setListWithEffect = setListWithEffect;

export { createAjaxPagingDescribe, createAjaxPagingDescribeForMST };
