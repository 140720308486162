import { types } from 'mobx-state-tree';
import { generateDescribeModule } from '@common/utils/describe-helper';
import { createAjaxDescribe } from '@common/utils/createAjaxDescribe';
import AnnouncementBase from '@common/models/Announcement/Announcement';
import announcementServer from '@/services/announcement';

const AnnouncementServer = generateDescribeModule('ajaxDescribe', [
  createAjaxDescribe,
  announcementServer.fetchAnnouncementList,
  function (data) {
    this.updateFromAjaxJson(data);
  },
]);

const Announcement = types.compose(AnnouncementServer, AnnouncementBase);
export default Announcement;
