import { define, optional, validate } from 'superstruct';

function noop(val) {
  return val;
}

const notEmptyReg = /\S/;

export function isNotEmpty(value) {
  const type = typeof value;

  if (Array.isArray(value)) {
    return !!value.length;
  }

  if (type === 'number' || type === 'boolean') {
    value = isNaN(value) ? '' : value + '';
  }

  if (type === 'string') {
    return notEmptyReg.test(value);
  }

  return !!value;
}

/**
 * 基于现有的struct，前置一个验证逻辑，并返回一个新的struct。
 * 类似于官方的refine，但refine时后置一个验证逻辑。
 * @param {Struct}   struct   原struct
 * @param {Function} check    验证逻辑
 * @param {Boolean}  useAnd   用验证逻辑和原struct使用“且”的关系。
 * @param {Function} getValue 在验证之前，对value进行转换
 * @returns Struct  一个新的Struct
 */
export function preReload(struct, check, useAnd, getValue) {
  return define(struct.type, (value) => {
    let result = check(getValue ? getValue(value) : value);

    if (useAnd ? result === true : result !== true) {
      const res = validateStruct(value, struct);
      result = res === true || res.message;
    }

    return result;
  });
}

export function required(struct, message, getValue) {
  return preReload(struct, (value) => isNotEmpty(value) || message || `${struct.type} is required`, true, getValue);
}

export function allowEmpty(struct, getValue) {
  return preReload(struct, (value) => typeof value === 'string' && value.trim() === '', false, getValue);
}

/**
 * 定义一个表单验证类，同时加上optional，required的验证风格。
 * optional：该值为空时，视为有效值。
 * required：该值为空时，错误信息是emptyMsg。
 *
 * @param {String}        name        struct name。
 * @param {Strut|Funtion} structOrFn  定义验证逻辑，可以是Struct或者验证函数 (value) => boolean。
 * @param {string}        invalidMsg  验证不通过时的错误信息
 * @param {string}        emptyMsg    struct.required验证风格用，值为空时的错误信息。
 * @param {Function}      getValue    在验证之前，对value进行转换
 * @returns Struct
 * @returns Struct.optional
 * @returns Struct.required
 */
export function defineFormValidate(name, structOrFn, invalidMsg, emptyMsg, getValue) {
  getValue = getValue || noop;

  const struct = define(name, function (value) {
    let result;
    value = getValue(value);

    if (typeof structOrFn === 'function') {
      result = structOrFn(value);
    } else {
      const res = validateStruct(value, structOrFn);
      result = res === true || res.message;
    }

    return result === true || (typeof invalidMsg === 'function' ? invalidMsg(value) : invalidMsg);
  });

  struct.optional = allowEmpty(struct, getValue);
  struct.required = required(struct, emptyMsg, getValue);
  struct.maybe = optional(struct.optional);
  return struct;
}

export function validateStruct(value, struct) {
  const error = validate(value, struct)[0];
  return error || true;
}
