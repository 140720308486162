import { each } from 'lodash';
export { parseExp } from './assignment';

const NO = {};

/*
 * 类型判断
 */
var class2type = {},
  toString = class2type.toString;

// Populate the class2type map
each('Boolean Number String Function Array Date RegExp Object Error'.split(' '), function (name) {
  class2type['[object ' + name + ']'] = name.toLowerCase();
});

/**
 * 获取传递过来的参数的数据类型
 * @param  {*} obj 需要检测的变量
 * @return {String}
 */
export function type(obj) {
  return obj == null ? String(obj) : class2type[toString.call(obj)] || 'object';
}

/**
 * 根据一个对象生成一个状态格式的class
 * @param {String} baseName class名
 * @param {Object} obj      状态信息
 * @param {String} connector 状态连接符
 * @returns String
 * @example
 * buildSuffixClassName('btn', {md: true, lg: true, xl: false});
 * // => 'btn--md btn--lg'
 */
export function buildSuffixClassName(baseName, obj, connector = '--') {
  const list = Object.keys(obj);
  return list.reduce((acc, key) => {
    if (obj[key]) return (acc += `${baseName}${connector}${key} `);

    return acc;
  }, '');
}

export function defrac(input, n = 0) {
  const factor = Math.pow(10, n);
  return Math.round(input * factor) / factor;
}

/**
 * withFn
 * @memberof function
 * @param {function} fn
 * @returns {function}
 */
export function withFn(fn = function () {}) {
  if (typeof fn !== 'function') throw new Error(fn + ' not a function');
  const fnList = [];
  const result = function (...rest) {
    return fnList.reduce((next, _fn) => {
      return _fn.bind(this, next, ...rest);
    }, fn.bind(this, ...rest))();
  };
  result.registerFn = (...rest) => fnList.push(...rest);
  return result;
}

/**
 * 循环一个固定逻辑，取一个符合条件的值
 * @memberof object
 * @param  ori               初始值
 * @category _lodash
 * @param  {Function} next   获取下一个值
 * @param  {Function} filter 过滤逻辑
 *
 * @example
 * loopWith($span, node => node.parentNode, node => node.className === 'App')
 */

export function loopWith(ori, next, filter) {
  while (!filter(ori)) {
    ori = next(ori);
  }

  return ori;
}

export function mergeFn(fn1, fn2) {
  return function () {
    fn1 && fn1.apply(this, arguments);
    return fn2 && fn2.apply(this, arguments);
  };
}

/**
 * 缓存计算结果
 * @memberof function
 * @param {function} getVal
 * @returns {any}
 */
export function createSingletonPattern(getVal) {
  let value = NO;
  const fn = function () {
    if (value === NO) value = getVal.apply(null, arguments);
    return value;
  };
  fn.clear = function (val) {
    value = NO;
    return val;
  };
  fn.clear.reject = function (e) {
    fn.clear();
    return Promise.reject(e);
  };

  return fn;
}

export function getErrorMessage(error) {
  return typeof error === 'string' ? error : error.message || error.msg;
}

export function navigatorBack() {
  window.history.back();
}

export function setRef(ref, val) {
  if (ref) {
    if (typeof ref === 'function') {
      ref(val);
    } else if ('current' in ref) {
      ref.current = val;
    }
  }
}

export function packPromise(val) {
  return typeof val === 'object' && 'then' in val ? val : Promise.resolve(val);
}

export function tryJsonParse(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
}

/**
 * 尝试执行一个函数，并返回其结果
 * @param {Function} fn
 * @param {Object}   scope fn的this
 * @returns
 */
export function tryInvoke(fn, scope) {
  try {
    return fn.call(scope || null);
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

export function waitTime(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}
