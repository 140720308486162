import * as classNames from 'classnames';
import { leadingZero } from '@common/utils/format';
import './style.less';

const { forwardRef } = require('react');

function timeGenerator(start, end, gap) {
  let list = [];
  let current = start;

  while (current <= end) {
    list.push({
      hour: Math.floor(current / gap),
      minute: Math.floor(current % gap),
    });

    current += gap;
  }

  return list;
}

const TimeList = forwardRef(function TimeList(
  {
    time,
    hour24Mode,
    startTime = { hour: 0, minute: 0 },
    endTime = { hour: 23, minute: 0 },
    gapTime = 60,
    onDoneClick,
  },
  ref
) {
  time = time || {};
  const list = timeGenerator(startTime.hour * 60 + startTime.minute, endTime.hour * 60 + endTime.minute, gapTime);

  return (
    <ol ref={ref} className="time-list flex flex--wrap">
      {list.map((item) => (
        <li
          key={time.hour + '-' + item.hour}
          className={classNames('time-list__item', {
            'time-list__item--selectd': time.hour === item.hour && time.minute === item.minute,
          })}
          onClick={() => onDoneClick(item)}
        >{`${leadingZero(item.hour)}:${leadingZero(item.minute)}`}</li>
      ))}
    </ol>
  );
});

export default TimeList;
