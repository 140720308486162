import { types } from 'mobx-state-tree';
import { object } from 'superstruct';
import { StringOrNumber, PositiveNumber, Phone } from '../../utils/verification';
import { createValidate, formInputValue } from './util';

// EmploymentHistory
const EmploymentHistory = types.compose(
  types
    .model({
      employer: formInputValue,
      employerAddress: formInputValue,
      title: formInputValue,
      person: formInputValue,
      income: formInputValue,
      seniority: formInputValue,
      phone: formInputValue,
      additionalIncome: formInputValue,
      previousCompanyName: formInputValue,
      previousCompanyAddress: formInputValue,
    })
    .views((self) => ({
      get hasPreviousCompany() {
        return !!(self.previousCompanyName || self.previousCompanyAddress);
      },
    })),
  createValidate(() => EmploymentHistory.verification)
);

EmploymentHistory.verification = object({
  employer: StringOrNumber.required,
  employerAddress: StringOrNumber.required,
  title: StringOrNumber.required,
  person: StringOrNumber.required,
  income: PositiveNumber.required,
  seniority: PositiveNumber.required,
  phone: Phone.required,
  additionalIncome: PositiveNumber.required,
  previousCompanyName: StringOrNumber.required,
  previousCompanyAddress: StringOrNumber.required,
});

export default EmploymentHistory;
