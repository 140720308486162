import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Row, Col } from '@common/components/Grids';
import useRootStore from '@/models';
import Icon from '@w/Icon';
import './style.less';

function FooterIconItem(props) {
  return (
    <div className="flex footer-icon-item">
      <div className="footer-icon-item__icon flex-grid">
        {props.icon && <Icon className="color-theme" name={props.icon} />}
      </div>
      <div className="flex-item">{props.children}</div>
    </div>
  );
}

const Footer = observer(function Footer() {
  const matchMedia = useRootStore('ui.matchMedia');
  const { PHONE_NUMBER } = useRootStore().systemSetting;

  return (
    <section className="page-footer lh1-6">
      <div className="page-footer__row">
        <Row className="footer-nav-wrap">
          <Col className="logo-col footer-nav" tag="section" md="9" lg="5" xl="6">
            <Link className="logo-wrap block" to="/">
              <img className="logo block" src={require('@/static/logo.svg').default} alt="" />
            </Link>

            <Col className="page-footer__content-item" size="9" md="24">
              <Link to="/terms">Terms of Service</Link>
            </Col>
            <Col className="page-footer__content-item" size="8" md="24">
              <Link to="/terms?privacy">Privacy</Link>
            </Col>
          </Col>

          <Col className="footer-nav" tag="section" md="8" lg="5" xl="4">
            <h2 className="footer-nav__title text-bold">Explore Carnex</h2>
            <Row>
              <Col className="page-footer__content-item" size="8" md="24">
                <Link to="/sellyourev">Sell your EV</Link>
              </Col>
              <Col className="page-footer__content-item" size="8" md="24">
                <Link to="/trade">Appraise Your Vehicle</Link>
              </Col>
              <Col className="page-footer__content-item" size="8" md="24">
                <Link to="/products">Shop EVs</Link>
              </Col>
              <Col className="page-footer__content-item" size="8" md="24">
                <Link to="/financing">Financing</Link>
              </Col>
              <Col className="page-footer__content-item" size="8" md="24">
                <Link to="/testdrive">Test Drive</Link>
              </Col>
            </Row>
          </Col>

          <Col className="footer-nav" tag="section" md="7" lg="4">
            <h2 className="footer-nav__title text-bold">Protection</h2>
            <Row>
              <Col className="page-footer__content-item" size="8" md="24">
                <Link to="/protection-plan">Vehicle Protection</Link>
              </Col>
              <Col className="page-footer__content-item" size="8" md="24">
                <Link to="/guarantee">Our Guarantee</Link>
              </Col>
            </Row>
          </Col>

          <Col className="footer-nav" tag="section" md="9" lg="4">
            <h2 className="footer-nav__title text-bold">Company</h2>
            <Row>
              <Col className="page-footer__content-item" size="8" md="24">
                <Link to="/aboutus">About Us</Link>
              </Col>
              <Col className="page-footer__content-item" size="8" md="24">
                <Link to="/faq">FAQ</Link>
              </Col>
              <Col className="page-footer__content-item" size="8" md="24">
                <a href="https://blog.carnex.ca">Blog</a>
              </Col>
            </Row>
          </Col>

          <Col className="footer-nav" tag="section" md="15" lg="6">
            <h2 className="footer-nav__title text-bold">Contact Us</h2>
            <FooterIconItem icon="microphone">
              <a href={PHONE_NUMBER.tel} id="carnex-phone-call">
                {PHONE_NUMBER.bracket}
              </a>
            </FooterIconItem>
            <FooterIconItem icon="location">
              <address>#5-1170 Burnhamthorpe Road, Mississauga, Ontario. L5C4E6</address>
            </FooterIconItem>
            <FooterIconItem icon="email">
              <a href="mailto:info@carnex.ca">Info@carnex.ca</a>
            </FooterIconItem>
          </Col>

          {/* <Col className="footer-nav" tag="section" md="12" lg="7">
            <h2 className="footer-nav__title text-bold">Test Drive</h2>
            <FooterIconItem icon="test-drive">
              Test drive availability according to appointment only. Call us today !
            </FooterIconItem>
            <FooterIconItem icon="time">Test drive available from Monday to Saturday 10:00 am - 6:00 pm</FooterIconItem>
          </Col> */}
        </Row>
      </div>

      <div
        className={
          'page-footer__row page-footer__copyright flex-grid ' + (matchMedia.md ? 'flex--reverse' : 'flex--col')
        }
      >
        <div className="social-media__box flex flex--wrap">
          <a
            className="social-media__item color-theme flex-grid"
            href="https://www.facebook.com/CARNEXca-103282401783051"
            target="_blank"
            rel="noreferrer"
          >
            <Icon name="facebook" />
          </a>
          <a
            className="social-media__item color-theme flex-grid"
            href="https://www.instagram.com/carnex.ca/"
            target="_blank"
            rel="noreferrer"
          >
            <Icon name="ins" />
          </a>
          <a
            className="social-media__item color-theme flex-grid"
            href="https://twitter.com/CANEX69802099"
            target="_blank"
            rel="noreferrer"
          >
            <Icon name="twitter" />
          </a>
          <a
            className="social-media__item color-theme flex-grid"
            href="https://www.youtube.com/channel/UC8SJL-hikjB8lY0MUysqfjw"
            target="_blank"
            rel="noreferrer"
          >
            <Icon name="youtube" />
          </a>
        </div>

        <div className="flex-item">
          <p>© 2021-2024 Carnex Canada. All rights reserved.</p>
          <div className="flex flex--middle">
            <div style={{ marginRight: '.5em' }}>Carnex is a proud member of </div>
            <a href="https://www.omvic.ca/">
              <img className="omvic-img block" src={require('@/static/omvic.jpg').default} alt="OMVIC" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Footer;
