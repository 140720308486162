import { filter, map, shareReplay, startWith, tap } from 'rxjs/operators';

function passthrough(n) {
  return n;
}

const setStateSheet = {
  vue(state, changeState) {
    Object.assign(state, changeState);
  },
};

/**
 * 背景：
 * 一般情况下，直接使用rxjs编写业务逻辑没有问题。
 * 但有时候我们只关注流最末端的值，和流的源头发射值后(下列的参数stream)，流是否还在等待中(下列的“loading”字段)。
 * 例如：
 * (stream = Stream.of(1))
 *   .flattenMap(() => Stream.timeout(1000))
 *   .flattenMap(() => Stream.timeout(500))
 *   .do(...)
 *
 * 在这个例子里，源头发射值后，还需要等待1500毫秒后，一次值流动才正式流到末端。
 * 而这种情况通常我们需要将loading状态体现在UI上，而每次创建一个loading外部变量显得很麻烦。
 *
 * 因此我提出一个叫做“流的实时描述”的概念。简单地说就是，针对上述的情景，将流的末端值(如: val)往外包
 * 装一层(得出{data: val})，这样我们还可以往这个包装对象添加更多描述性或状态字段，这个包装对象称之为“流的实时描述”
 *
 *
 * 方法简介：
 * 创建一个关于流的实时描述，包含字段：
 * loading: 流是否在等待中
 * data: 流最新的数据
 * error: 流的错误
 * inited: 流是否已经流通过一次，并且没有错误
 * initedAsync: inited的异步版本
 *
 * @param  {Stream}   stream   - 流的数据源
 * @param  {Function} joint    - 数据源后面的拼接逻辑，返回值必须是个Stream实例，函数签名是(stream, status, setError, updateState)
 * @param  {boolean|Function}  allowConcurrency  - 是否允许并发
 * @param  {String}   setState - 更改内部status的方法，它函数签名是(state, changeState, params)
 * @param  {[type]}   params   - 作为setState的第三个参数
 * @return {Object} 返回一个流，它包含参数流的描述
 */
export function createStatusStream(
  stream,
  joint,
  allowConcurrency,
  setState = createStatusStream.defaultSetState,
  params,
  needInitedAsync,
  _status
) {
  const status = _status || {
    data: null,
    loading: false,
    inited: false, // 是否已经初始化过
    initedAsync: false, // 为了后面addListener的listener能读取到“正确/合适”的inited，创建一个延迟变更的状态
    error: null,
  };

  setState = typeof setState === 'function' ? setState : setStateSheet[setState];

  if (setState.init) {
    setState.init(status);
  }

  function updateState(change) {
    return setState(status, change, params);
  }

  stream = joint(
    stream.pipe(
      filter(
        (val) =>
          (typeof allowConcurrency === 'function' ? allowConcurrency(val, status) : allowConcurrency) || !status.loading
      ),
      tap((val) => {
        const _status = {
          loading: true,
          error: null,
        };

        if (val === null) {
          _status.data = val;
        }

        updateState(_status);
      })
    ),
    status,
    (err) => updateState({ error: err }),
    updateState
  );

  return stream.pipe(
    map((data) => {
      const copyState = updateState({
        loading: false,
        inited: status.inited || status.error === null,
        data,
      });

      if (needInitedAsync) {
        setTimeout(function () {
          updateState({
            initedAsync: status.inited,
          });
        });
      }

      return typeof copyState === 'undefined' ? status : copyState;
    }),
    startWith(status),
    map(updateState.format || passthrough),
    shareReplay(1)
  );
}

createStatusStream.defaultSetState = 'vue';
