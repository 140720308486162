import { rootStore } from '@/models';
import invitationServer from '@/services/invitation';

let ticketInfo;

const invitationController = {
  getTicket() {
    const memoryTicket = rootStore.sharedMemory.get('invitationTicket');

    const invitationTicket = rootStore.entryQuery.it;
    if (invitationTicket) {
      rootStore.sharedMemory.set('invitationTicket', invitationTicket);
      return invitationTicket;
    }

    return memoryTicket;
  },
  fetchTicketInfo() {
    const code = invitationController.getTicket();
    if (code) {
      if (ticketInfo && ticketInfo.code === code) {
        return Promise.resolve(code);
      }

      return invitationServer.fetchTicketInfo(code).then((res) => (ticketInfo = res));
    }
  },
  bindTicket() {
    const code = invitationController.getTicket();
    if (code) {
      console.log(`invite code: ${code}`);
      return invitationServer.bindTicket(code);
    }
  },
};

export default invitationController;
