import { types } from 'mobx-state-tree';
import { tap } from 'rxjs/operators';
import { AjaxDescribeHelper, generateDescribeModule } from '@common/utils/describe-helper';
import { createAjaxDescribe } from '@common/utils/createAjaxDescribe';
import CouponCodeBase from '@common/models/CouponCode';
import couponServer from '@/services/coupon';

const CouponCodeServer = generateDescribeModule('ajaxDescribe', [
  createAjaxDescribe,
  function (code) {
    return code ? couponServer.fetchCoupon(code) : Promise.resolve(null);
  },
  {
    concurrent: true,
    onDataUpdate(data) {
      this.updateFromAjaxJson(data);
    },
    preConnect: (stream, state, setErr, updateState) => {
      return stream.pipe(tap((code) => updateState({ data: { code } })));
    },
    //   postConnect: (stream) =>
    //     stream.pipe(
    //       switchMap((data) => {
    //         const { code } = this;
    //         console.log(data);
    //         return code === '1111'
    //           ? of({ code: code, money: 123, status: 1 }).pipe(delay(1000))
    //           : of(data || (code ? null : {})); // 有code不用处理，preConnect已经reset
    //       })
    //     ),
  },
]);

const CouponCode = types.compose(AjaxDescribeHelper, CouponCodeServer, CouponCodeBase);

export default CouponCode;
