import { types } from 'mobx-state-tree';
import { assign, object } from 'superstruct';
import { DatePicker, StringOrNumber } from '../../utils/verification';
import { createValidate, formInputValue } from './util';
import Location from './Location';

export const deliveryMethodOption = [
  { label: 'Delivery', value: 1, desc: "We'll bring the car to you." },
  {
    label: 'Pick up at the store.',
    value: 0,
    desc: 'After the procedures are completed, we will contact you to confirm the time.',
  },
];

const Delivery = types.compose(
  Location,
  types.model({
    deliveryMethod: formInputValue,
    deliveryTime: formInputValue,
  }),
  createValidate(() => Delivery.verification)
);

Delivery.verification = assign(
  Location.verification,
  object({
    deliveryMethod: StringOrNumber.required,
    deliveryTime: DatePicker.required,
  })
);

export default Delivery;
