import { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useRootStore from '@/models';
import { safeRender, toVueSlots } from '@common/utils/slots';
import { useEventCallback } from '@common/hooks/official-hack';
import Spinner from '@common/components/Spinner';
import { calcEventRelativePos, Ripple } from '../Ripple';
import './style.less';

const defaultClass = 'fs20 text-bold';
const defaultClassForMobile = 'fs16 text-bold';

const Button = forwardRef(function Button(
  {
    className,
    baseClass,
    extraClassName,
    tag,
    border,
    loading,
    size,
    color,
    theme,
    readOnly,
    persistContentWhenLoading,
    onClick,
    onPrevent,
    children,
    ...attrs
  },
  ref
) {
  const matchMedia = useRootStore('ui.matchMedia');
  const $slots = toVueSlots(safeRender(children));
  const Wrap = tag || 'button';
  const [spawnData, setSpawnData] = useState({});

  const clickHandler = useEventCallback(
    (e) => {
      if (!readOnly && !loading) {
        setSpawnData(calcEventRelativePos(e));
        onClick && onClick(e);
      } else {
        onPrevent && onPrevent(e);
      }
    },
    [readOnly, loading, onClick]
  );

  return (
    <Wrap
      ref={ref}
      className={classNames(
        baseClass || (matchMedia.md ? defaultClass : defaultClassForMobile),
        'c-button flex-grid pos-r',
        loading && 'c-button--loading',
        border && 'c-button--border',
        size ? `c-button--${size}` : '',
        color ? `c-button--${color}` : '',
        theme ? `c-button--${theme}` : '',
        className,
        extraClassName
      )}
      type="button"
      onClick={clickHandler}
      readOnly={readOnly}
      {...attrs}
    >
      <Ripple spawnData={spawnData} duration={500} />
      {loading ? <div className="flex-grid full-size pos-a">{$slots.loading || <Spinner />}</div> : null}
      {loading && persistContentWhenLoading ? (
        <div style={{ visibility: 'hidden' }}>{$slots.default}</div>
      ) : (
        $slots.default
      )}
    </Wrap>
  );
});

Button.propTypes = {
  border: PropTypes.bool, // 展示线条样式的按钮
  size: PropTypes.string,
  color: PropTypes.string,
  loading: PropTypes.bool,
  readOnly: PropTypes.bool,
  persistContentWhenLoading: PropTypes.bool, // laoding也展示原内容，避免展示Spinner时button布局塌陷。
};

Button.defaultProps = {
  persistContentWhenLoading: true,
};

export default Button;
