// import { IS_PROD } from '@/env';
export const config = {
  products: '/products',
  pageSize: 10,
  advertIndex: 5,
  delay: 0,
  // clientId: IS_PROD ? '708701582472-rjuaru690seilhnn84f6i90fkb94qlhf.apps.googleusercontent.com' : '680796250055-4lmpt075l7c1in3a5cg0hpva951da286.apps.googleusercontent.com',
  clientId: '680796250055-4lmpt075l7c1in3a5cg0hpva951da286.apps.googleusercontent.com',
    // '708701582472-rjuaru690seilhnn84f6i90fkb94qlhf.apps.googleusercontent.com'  => old
    // '680796250055-4lmpt075l7c1in3a5cg0hpva951da286.apps.googleusercontent.com'  => info@carnex.ca
};
