import { isEqual, pick } from 'lodash';
import { useRef, useCallback, useLayoutEffect, useState } from 'react';

export default function useRect(elmRef, attention, callback, monitorScroll) {
  const valueRef = useRef({});
  const [rect, setRect] = useState({});
  const emitChange = useCallback(() => {
    if (elmRef.current) {
      const size = elmRef.current.getBoundingClientRect();

      if (!attention || !isEqual(pick(valueRef.current, attention), pick(size, attention))) {
        setRect(size);
        valueRef.current = size;
        callback && callback(size);
      }
    }
  }, [attention, callback, elmRef]);

  useLayoutEffect(() => {
    emitChange();

    if (monitorScroll) {
      window.addEventListener('scroll', emitChange);
    }

    window.addEventListener('resize', emitChange);
    return () => {
      window.removeEventListener('resize', emitChange);
      window.removeEventListener('scroll', emitChange);
    };
  }, [emitChange, monitorScroll]);

  return [rect, emitChange];
}
