import { forwardRef } from 'react';
import './style.less';

const Icon = forwardRef(function Icon({ className, name, style, onClick }, ref) {
  return (
    <i
      ref={ref}
      className={'flex-grid iconfont c-icon' + (name ? ' c-icon-' + name : '') + (className ? ' ' + className : '')}
      style={style}
      onClick={onClick}
    ></i>
  );
});

export default Icon;
