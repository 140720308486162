import { getEnv, types } from 'mobx-state-tree';
import { EventBus } from 'domain-events';
import URLParse from 'url-parse';
import UI from '@common/models/UI';
import Car from './Car';
import { createSingletonPattern } from '@common/utils';
import { safeAssign } from '@common/utils/assignment';
import Emiiter from '@common/libs/network/emiiter';
import Collection from '@common/models/Collection';
import Storage from '@common/models/Storage/Storage';
import SharedMemory from '@common/models/SharedMemory';
import { Auth } from './Auth';
import { FeeList } from './Fee/Fee';
import SystemSetting from './SystemSetting';
import { CarOrderList } from '@/models/CarOrder';
import InvitationCouponList from './Invitation/InvitationCouponList';
import LoginSuccessEvent from './Auth/events/login-success.domain-event';
import { tryDispatchDomainEvent } from '@common/utils/describe-helper';
import LoginSigninEvent from './Auth/events/login-signin.domain-event';
// import { checkReplenish } from '@/controller/loginController';

const resolveModal = createSingletonPattern(() => import('@common/helper/modal').then((e) => e.default));
const loadInvitationController = createSingletonPattern(() =>
  import('@/controller/invitationController').then((e) => e.default)
);
const loadChannelController = createSingletonPattern(() =>
  import('@/controller/channelController').then((e) => e.default)
);
const loadLoginController = createSingletonPattern(() => import('@/controller/loginController').then((e) => e.default));

EventBus.prototype.globalDispatch = function (event) {
  // publishH5Event;
  return this.dispatch(event);
};

const defaultObject = {};
const RootStore = types.compose(
  types
    .model({
      ui: UI,
      car: Car,
      collection: Collection,
      auth: Auth,
      storage: types.optional(Storage, defaultObject),
      sessionStorage: types.optional(Storage, { useSessionStorage: true }),
      sharedMemory: types.optional(SharedMemory, defaultObject),
      entryQuery: types.frozen(),

      orderList: CarOrderList,
      invitationCouponList: InvitationCouponList,

      feeList: FeeList,
      systemSetting: types.optional(SystemSetting, defaultObject),
    })
    .actions((self) => ({
      async init() {
        self._initBusinessEventHub();
        self._initDomainEventHub();
        self.entryQuery = URLParse(window.location.href, true).query;

        // 恢复登录
        safeAssign(self.auth, self.storage.get('gauth') || {});
        if (self.auth.token) {
          try {
            await self.auth.getCurrentUser({
              ignore401: true,
              ...self.auth.tokenConfig,
            });

            if (self.auth.user.id) {
              tryDispatchDomainEvent(LoginSigninEvent, null, self);
            }
          } catch (e) {}
        }

        await Promise.all([self.feeList.load().toPromise(), self.systemSetting.load().toPromise()]);

        loadChannelController().then((channelController) => channelController.getTicket());

        loadInvitationController()
          .then((invitationController) => invitationController.fetchTicketInfo())
          .then(function (res) {
            if (res && res.id) {
              loadLoginController().then((loginController) => loginController.show({ autoJump: false }));
            }
          });
      },
      runInAction(fn) {
        fn.call(self);
      },
      _initBusinessEventHub() {
        self.businessEventHub = new Emiiter();
      },

      _initDomainEventHub() {
        const { eventBus } = getEnv(self);
        if (!eventBus) return;

        // 将一些业务关注的领域事件暴露出去
        (function () {
          [].forEach(function (DomainEvent) {
            const name = DomainEvent.eventName();
            eventBus.register(function (res) {
              self.businessEventHub.emit(name, res);
            }, name);
          });
        })();

        // 登录相关
        (function () {
          // 登入（恢复登录）成功时
          eventBus.register(function () {
            // 要求完成必填信息，先做成非阻塞
            // checkReplenish();
          }, LoginSigninEvent.eventName());

          // 通过表单登录成功时
          eventBus.register(function () {
            // 先做成非阻塞
            async function runIt() {
              // 绑定优惠券
              await loadInvitationController().then((invitationController) => invitationController.bindTicket());

              // 要求完成必填信息
              // await checkReplenish();

              // 查询优惠券
              self.invitationCouponList
                .load()
                .toPromise()
                .then(() => {
                  const coupon = self.invitationCouponList.getMostFavorableCoupon(1);
                  if (coupon) {
                    return Promise.all([
                      import('@/widgets/Coupon/CouponArrivalRemind').then((e) => e.default),
                      resolveModal(),
                    ]).then(([CouponArrivalRemind, modal]) =>
                      modal.alert(
                        function ({ onChange }) {
                          return <CouponArrivalRemind coupon={coupon} onClick={() => onChange(false)} />;
                        },
                        {
                          floatHead: true,
                          dialogClass: 'coupon-arrival-remind-dialog',
                        }
                      )
                    );
                  }
                });
            }

            runIt();
          }, LoginSuccessEvent.eventName());
        })();
      },
    }))
);

export default RootStore;
