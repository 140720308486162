import * as dayjs from 'dayjs';

export function dateToReactModernCalendarDatepicker(date) {
  if (date) {
    date = {
      year: date.year(),
      month: date.month() + 1,
      day: date.date(),
      hour: date.hour(),
      minute: date.minute(),
    };

    if (date.year && date.month && date.day) {
      return date;
    }
  }

  return '';
}

export function getDateForReactModernCalendarDatepicker(value) {
  return value ? dayjs(new Date(value.year, value.month - 1, value.day, value.hour || 0, value.minute || 0)) : '';
}
