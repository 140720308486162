import { rootStore } from '@/models';
import network from '@common/libs/network';

const invitationServer = {
  bindTicket(code) {
    return network(
      '/newApi/invite/code',
      {
        email: rootStore.auth.user.email,
        code,
      },
      { method: 'post' }
    );
  },
  fetchTicketInfo(code) {
    return network(`/newApi/user/code/${code}`);
  },

  fetchInvitationConfig(id) {
    return network(`/newApi/invite/activity/${id}`);
  },
  fetchInviteList(params) {
    return network(`/newApi/invite/list`, {
      email: rootStore.auth.user.email,
      page: 1,
      size: 5,
      ...params,
    });
  },
};

export default invitationServer;
