import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useEffect, useCallback, useState, useRef } from 'react';
import { makeAutoObservable, reaction } from 'mobx';
import { useHistory, useLocation } from 'react-router';
import { observer } from 'mobx-react-lite';
import { navigatorBack } from '@common/utils';
import useRootStore from '@/models';
import useRect from '@common/hooks/useRect';
import PinBox from '@common/components/PinBox';
import Nav from '@w/Nav';
import Icon from '@w/Icon';
import Spinner from '@common/components/Spinner';
import Popper from '@common/components/Popper';
import { collectionController, loginController } from '@/controller/async';
import LogoutButton from '../LogoutButton';

import './style.less';

function isHeaderMobile(store) {
  // return store.ui.width < 1140;
  return !store.ui.matchMedia.lg;
}

const headerController = makeAutoObservable({
  left: null,
  nav: null,
  right: null,
  isMobile: false,
  theme: '',
  rect: {},

  setLeft(val) {
    this.left = val;
  },

  setNav(val) {
    this.nav = val;
  },

  setRight(val) {
    this.right = val;
  },

  setRect(val) {
    this.rect = val;
  },

  setTheme(val) {
    this.theme = val || '';
  },

  setMobileSign(val) {
    if (this.isMobile !== val) {
      this.isMobile = val;
    }
  },

  reset() {
    this.setLeft();
    this.setNav();
    this.setRight();
    this.setTheme();
  },
});

const renderDefault = (s) => s;
const updateHeaderRect = headerController.setRect.bind(headerController);
const monitorAttention = ['height'];

const Header = observer(function Header() {
  const store = useRootStore();
  const history = useHistory();
  const location = useLocation();
  const isMobile = isHeaderMobile(store);
  const hidePhoneNum = !store.ui.matchMedia.xl;
  const [menuVisible, setMenuVisible] = useState(false);
  const { PHONE_NUMBER } = store.systemSetting;

  const headerRef = useRef();
  useRect(headerRef, monitorAttention, updateHeaderRect);

  const renderLeft = headerController.left || renderDefault;
  const renderMiddler = headerController.nav || renderDefault;
  const renderRight = headerController.right || renderDefault;

  const onClickCollection = useCallback(() => {
    //console.log("show collection...");
    collectionController.show();
    // if (location.pathname !== '/collection') history.push('/collection');
  }, []);

  const userHandler = useCallback(() => {
    if (store.auth.isLogin) {
      if (location.pathname !== '/profile/hold') history.push('/profile/hold');
    } else {
      loginController.show();
    }
  }, [history, location, store.auth.isLogin]);

  useEffect(() => {
    headerController.setMobileSign(isMobile);
  }, [isMobile]);

  return (
    <PinBox pin={true}>
      <header
        ref={headerRef}
        className={classNames(
          'page-header common-page-header',
          isMobile ? 'common-page-header--mobile' : '',
          headerController.theme ? 'common-page-header--' + headerController.theme : ''
        )}
      >
        <div className="page-row flex-grid full-height">
          {renderLeft([
            isMobile && (
              <div key="menu" className="nav-menu-switch" onClick={() => setMenuVisible(true)}>
                <Icon name="menu" />
              </div>
            ),
            <Link key="logo" className="logo cursor-pointer block" to="/">
              <img className="img" src={require('@/static/logo2.png').default} alt="" />
            </Link>,
          ])}

          {renderMiddler(
            <Nav className="flex-item flex-grid" forMobile={isMobile} value={menuVisible} onChange={setMenuVisible}>
              {store.auth.isLogin ? (
                <div slot="modal-children" className="flex flex--right">
                  <LogoutButton onSuccess={() => setMenuVisible(false)} />
                </div>
              ) : null}
            </Nav>
          )}

          {renderRight(
            <div className="page-header__right flex-grid">
              <Popper
                id="carnex-phone-call-header"
                behavior="delayHover"
                delayClose={200}
                offset={[0, 8]}
                contentClassName="phone-popper-content"
                content={PHONE_NUMBER.bracket}
                disabled={!hidePhoneNum}
                extraOptions={{ strategy: 'fixed' }}
                popperStyle={{ zIndex: 102 }}
              >
                <a className="c-icon-popper flex-grid" href={PHONE_NUMBER.tel}>
                  {!hidePhoneNum && <span className="phone-num ff-heavy">{PHONE_NUMBER.bracket}</span>}
                  <Icon name="phone" />
                </a>
              </Popper>
              {collectionController.loading ? (
                <Spinner size="22" />
              ) : (
                <Icon className="cursor-pointer" name="heart--hollow" onClick={onClickCollection} />
              )}

              {loginController.loading ? (
                <Spinner size="22" />
              ) : (
                <Icon
                  className={'cursor-pointer' + (store.auth.isLogin ? ' color-theme' : '')}
                  name="user"
                  onClick={userHandler}
                />
              )}
            </div>
          )}
        </div>
      </header>
    </PinBox>
  );
});

Header.isMobile = isHeaderMobile;
Header.renderBack = () => (
  <div className="page-header__back flex-grid">
    <Icon name="left" onClick={navigatorBack} />
  </div>
);

function useHeaderMobileBack() {
  // 监听header ui变化，移动端样式时，左上角的logo换成返回按钮
  useEffect(() => {
    function updateHeader(isMobile) {
      if (isMobile) {
        headerController.setLeft(Header.renderBack);
      } else {
        headerController.setLeft();
      }
    }

    updateHeader(headerController.isMobile);
    return reaction(() => headerController.isMobile, updateHeader);
  }, []);

  return useHeaderController();
}

function useHeaderController() {
  useEffect(() => {
    return () => headerController.reset();
  }, []);

  return headerController;
}

export { headerController, useHeaderController, useHeaderMobileBack };
export default Header;
