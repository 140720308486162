import { useCallback, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import * as classNames from 'classnames';
import { lock, unlock } from 'tua-body-scroll-lock';
import { safeRender, toVueSlots } from '@common/utils/slots';
import useWatch from '@common/hooks/useWatch';
import Icon from '@/widgets/Icon';
import './style.less';

const isMobile = /Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent);

function useScrollLock(need) {
  const needRef = useRef(need);
  const ref = useRef(0);
  const lockScroll = useCallback(function () {
    if (needRef.current && !ref.current) {
      ref.current = 1;
      lock();
    }
  }, []);

  const unLockScroll = useCallback(function () {
    if (ref.current) {
      ref.current = 0;
      unlock();
    }
  }, []);

  return { lock: lockScroll, unlock: unLockScroll };
}

export default function Modal(props) {
  const {
    className,
    bgClass,
    titleClass,
    containerClass,
    dialogClass,
    dialogStyle,
    style,
    value,
    independence,
    noClose,
    closeIcon,
    detachedCloseBtn,
    backdropClose,
    title,
    floatHead,
    mobileLock,
    startAnimation,
    onChange,
    onClose,
  } = props;

  const elRef = useRef();
  const [delayValue, setDelayValue] = useState(value);
  const [show, setShow] = useState(startAnimation ? false : value);
  const close = useCallback(() => onChange(false), [onChange]);
  const bgHandler = useCallback(
    function (e) {
      if (e.target.classList.contains('dialog-bg') && backdropClose) {
        close();
      }
    },
    [close, backdropClose]
  );

  const { lock, unlock } = useScrollLock(!isMobile || mobileLock);
  const timerRef = useRef();
  const setVisible = useCallback(
    function (value) {
      timerRef.current && clearTimeout(timerRef.current);

      if (value) {
        lock();
        setDelayValue(value);
        /* eslint no-unused-expressions: "off" */
      } else {
        timerRef.current = setTimeout(() => {
          unlock();
          setDelayValue(false);
          setShow(false);
          onClose && onClose();
        }, 350);
      }

      return unlock;
    },
    [lock, unlock, onClose]
  );

  useWatch([value], function ([value]) {
    setVisible(value);
  });

  useWatch(
    [delayValue],
    function ([delayValue]) {
      if (delayValue) {
        elRef.current.clientWidth;
        setShow(true);
      }
    },
    {
      immediate: startAnimation,
    }
  );

  const baseClass = [
    className || '',
    !value || !show ? 'dialog--close' : '',
    noClose ? 'dialog--no-close' : '',
    noClose && !title ? 'dialog--no-head' : '',
  ];
  const classList = classNames(['dialog', dialogClass, independence && 'dialog-independence', ...baseClass]);

  const scope = { close };
  const $slots = toVueSlots(safeRender(props.children, scope));
  const footerContent = safeRender($slots.footer, scope);

  const dialog = (
    <div key="dialog" ref={elRef} className={classList} style={{ display: !delayValue ? 'none' : '', ...dialogStyle }}>
      <div className="dialog__inner flex flex--col">
        <div className={classNames('dialog__head flex-grid', floatHead && 'dialog__head--float')}>
          <div className={classNames('dialog__title', titleClass)}>
            {typeof title === 'undefined' || title === null ? '' : title}
          </div>
          <div className="dialog__head-close flex-grid">
            <div style={{ padding: 6 }} onClick={close}>
              {closeIcon ? <i className={closeIcon}></i> : <Icon className="cursor-pointer" name="close" />}
            </div>
          </div>
        </div>
        <div className={classNames(['dialog__body flex-item scroll-box', containerClass])}>
          {safeRender($slots.default, scope)}
        </div>
        {footerContent && <div className="dialog__footer">{footerContent}</div>}
        {detachedCloseBtn ? <i className={detachedCloseBtn} onClick={close}></i> : null}
      </div>
    </div>
  );

  const body = independence ? (
    dialog
  ) : (
    <div
      key="mix"
      className={classNames([bgClass, 'dialog-bg scroll-box', ...baseClass])}
      onClick={bgHandler}
      style={{ display: !delayValue ? 'none' : '', ...style }}
    >
      {safeRender($slots.before, scope)}
      {dialog}
      {safeRender($slots.after, scope)}
    </div>
  );

  return createPortal(body, document.body);
}

Modal.propTypes = {
  startAnimation: PropTypes.bool, // modal初始化时是否应用起始动画。仅初始化value是true时有效。因为从false到true必定应用过渡动画。
  backdropClose: PropTypes.bool, // 点击背景是否可以关闭模态框。
  className: PropTypes.string, // className。同时加在.dialog-bg和.dialog。
  bgClass: PropTypes.string, // className。加在.dialog-bg。
  style: PropTypes.object, // style。加在.dialog-bg。
  dialogClass: PropTypes.string, // className。加在.dialog。
  dialogStyle: PropTypes.object, // style。加在.dialog。
  titleClass: PropTypes.string, // className。加在模态框标题。
  containerClass: PropTypes.string, // className。加在.dialog__body。
  value: PropTypes.bool, // 控制模态框是否展示。
  mobileLock: PropTypes.bool,
  independence: PropTypes.bool, // 为true时，没有.dialog-bg包裹。
  onChange: PropTypes.func,
  noClose: PropTypes.bool, // 不使用header的关闭按钮
  closeIcon: PropTypes.string, // header的关闭按钮的className
  detachedCloseBtn: PropTypes.string, // className，传该参数时，生成一个跟.dialog__body同级的关闭按钮，用于辅助实现某些布局。
  floatHead: PropTypes.bool, // 为true时，header增加.dialog__head--float className，样式上将脱离文档流。
  title: PropTypes.node, // 模态框title
};
