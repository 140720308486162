import { NEVER, of, Subject } from 'rxjs';
import { filter, map, mergeAll, startWith, switchMap, throttleTime, withLatestFrom } from 'rxjs/operators';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import * as classNames from 'classnames';
import PropTypes from 'prop-types';
import { useStaticRef } from '@common/hooks/useStaticRef';
import useWatch from '@common/hooks/useWatch';
import { createMutationObserver } from '@common/hooks/useMutationObserver';
import { loopWith, withFn } from '@common/utils';
import is from '@common/utils/dom/is';
import { useVueCycle, useWatchObservable } from '@common/utils/vue-style';
import { useShareRef } from '@common/hooks/useShareRef';
import './style.less';

const PinBoxContext = createContext({});
const fixClassName = 'pin-box-elm--fix';
let _uid = 1;

function toRem() {}
function createSubject() {
  return new Subject();
}
function createConstant(val) {
  return () => val;
}

function resolveDomSelector(get, onlyParent) {
  if (get && typeof get === 'string') {
    if (onlyParent) {
      return loopWith(
        onlyParent,
        (node) => node.parentNode,
        (node) => is(node, get)
      );
    } else {
      return document.querySelector(get);
    }
  }

  return get ? get() : null;
}

const scroll$$ = createSubject();
window.addEventListener('scroll', () => scroll$$.next && scroll$$.next(1));

const IntersectionObserver = window.IntersectionObserver;
const instanceMap = new Set();

const baseMixins = {
  useData() {
    const uid = useState(() => _uid++);

    return { uid };
  },
  computed(props, state, computed) {
    const { disabled, useRemUnit } = props;
    return {
      isUseable: createConstant(!disabled),
      calSpace: withFn(createConstant(0)),
      calSpaceValue: withFn(() => (useRemUnit ? toRem(computed.calSpace) : (computed.calSpace || 0) + 'px')),
    };
  },
  useRender(props, stateApi, computed, renderState, refMap) {
    const { renderSign } = props;
    const { isUseable } = computed;
    const propsRef = useStaticRef(props);

    // 暴露一个主动更新接口
    const [depChange$$] = useState(createSubject);
    const [forceUpdate$$] = useState(createSubject);
    const forceUpdate = useCallback(
      function (force) {
        force ? forceUpdate$$.next(1) : depChange$$.next(1);
      },
      [depChange$$, forceUpdate$$]
    );

    useWatch([isUseable, renderSign], function () {
      forceUpdate(true);
    });

    useEffect(
      function () {
        let disconnect, subscription;
        const $el = refMap.elmRef.current;

        // 利用MutationObserver进行自动更新。默认关闭，如果一个页面很多pinbox，可能有问题。
        if (propsRef.current.useMutation) {
          const mutationUpdate$$ = createSubject();
          subscription = mutationUpdate$$.pipe(throttleTime(300)).subscribe(() => forceUpdate());
          disconnect = createMutationObserver($el, () => {
            mutationUpdate$$.next(1);
          });
        }

        return () => {
          subscription && subscription.unsubscribe();
          disconnect && disconnect();
        };
      },
      [forceUpdate, refMap.elmRef, propsRef]
    );

    return {
      depChange$$,
      forceUpdate$$,
      forceUpdate,
    };
  },
};

const adsorptionTopMixins = {
  useData(props) {
    const { useOptimize, inverse } = props;
    const isArrive = useState(false); // 是否到达吸顶位置
    const isOverflow = useState(false);
    const topForLimit = useState(0);
    const isSleep = useState(useOptimize && !inverse); // 是否处于休眠状态(位于视口外)

    return { isArrive, isOverflow, topForLimit, isSleep };
  },
  computed(props, state, computed, computedDefind) {
    const { useRemUnit, absoluteTop, baseTop } = props;
    const { topForLimit } = state;

    computedDefind.calSpace.registerFn(function (next) {
      return computed.hasAbsoluteTop ? absoluteTop : next() + baseTop;
    });

    return {
      hasAbsoluteTop: withFn(() => absoluteTop || ('absoluteTop' in props && !isNaN(Number(absoluteTop)))),
      topForLimitValue: withFn(() => (useRemUnit ? toRem(topForLimit) : (topForLimit || 0) + 'px')),
    };
  },
  useRender(props, { state, stateSetter }, computed, renderState, refMap) {
    const placeholderRef = {};
    const { pin, useOptimize, inverse, baseTop, absoluteTop, limitSpace, getLimitBox } = props;
    const { depChange$$, forceUpdate$$, forceUpdate } = renderState;
    const { isUseable } = computed;
    const propsRef = useStaticRef(props);
    const stateRef = useStaticRef(state);
    const computedRef = useStaticRef(computed);
    const { isSleep } = state;
    const {
      isArrive: setArrive,
      isOverflow: setOverflow,
      topForLimit: setTopForLimit,
      isSleep: setSleep,
    } = stateSetter;

    // 优化：使用IntersectionObserver为屏幕外时添加标记，避免计算
    const observerMapRef = useShareRef(() => ({
      placeholderObserver: null,
      limitBoxObserver: null,
      canSeeEl: !isSleep,
      canSeeLimitBox: !isSleep,
    }));

    const pin$$ = useWatchObservable([pin]);

    const delayUseOptimizeTimer = useRef(0);
    const delayUseOptimize = useStaticRef(props.delayUseOptimize);

    useWatch([baseTop, absoluteTop, limitSpace, getLimitBox, isSleep], () => forceUpdate());
    useWatch([inverse], () => forceUpdate(true));
    useWatch(
      [isUseable, useOptimize, getLimitBox],
      function ([isUseable, useOptimize, getLimitBox], oldVal) {
        if (delayUseOptimize.current) {
          setSleep(false);
          if (useOptimize && !oldVal) {
            delayUseOptimizeTimer.current = setTimeout(() => {
              setSleep(true);
            }, delayUseOptimize.current);
          }
        }

        [
          {
            el: isUseable && placeholderRef.current,
            field: 'canSeeEl',
            observer: 'placeholderObserver',
          },
          {
            el: isUseable && resolveDomSelector(getLimitBox),
            field: 'canSeeLimitBox',
            observer: 'limitBoxObserver',
          },
        ].forEach(function ({ el, field, observer }) {
          const observerMap = observerMapRef.current;
          if (useOptimize && el && IntersectionObserver) {
            if (!observerMap[observer]) {
              observerMap[observer] = new IntersectionObserver(([change]) => {
                observerMap[field] = change.isIntersecting || change.intersectionRatio > 0;
                const isSleep = !observerMap.canSeeEl && !observerMap.canSeeLimitBox;
                setSleep(isSleep);
                if (!isSleep) {
                  clearTimeout(delayUseOptimizeTimer.current);
                }
              });

              observerMap[observer].observe(el);
            }
          } else {
            setSleep(false);
            clearTimeout(delayUseOptimizeTimer.current);
            observerMap[field] = true;
            observerMap[observer] && observerMap[observer].disconnect();
            observerMap[observer] = null;
          }
        });
      },
      { immediate: true }
    );

    useEffect(
      function () {
        return function () {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          const observerMap = observerMapRef.current;
          clearTimeout(delayUseOptimizeTimer.current);
          observerMap.placeholderObserver && observerMap.placeholderObserver.disconnect();
          observerMap.limitBoxObserver && observerMap.limitBoxObserver.disconnect();
        };
      },
      [observerMapRef]
    );

    useEffect(
      function () {
        const env$ = of(window.innerHeight).pipe(map((height) => ({ windowHeight: height })));
        let arriveSubscription, overflowSubscription;

        function createMonitor() {
          return of(
            of(scroll$$, depChange$$).pipe(
              mergeAll(),
              filter(() => !stateRef.current.isSleep && !propsRef.current.pin)
            ),
            forceUpdate$$,
            pin$$,
            propsRef.current.pin || propsRef.current.updateOnInit || propsRef.current.inverse ? of(0) : NEVER
          ).pipe(mergeAll());
        }

        // 吸顶计算
        arriveSubscription = createMonitor()
          .pipe(
            withLatestFrom(env$),
            map(([, env]) => {
              if (propsRef.current.pin) return true;
              const res = refMap.elmRef.current.getBoundingClientRect();
              return propsRef.current.inverse
                ? res.bottom > env.windowHeight - computedRef.current.calSpace
                : res.top < computedRef.current.calSpace;
            })
          )
          .subscribe((val) => {
            if (stateRef.current.isArrive !== val) {
              setArrive(val);
              // vm.$emit('change', val); // @todo
            }
          });

        // limitBox相关计算
        const limitBox$ = of(1).pipe(map(() => resolveDomSelector(propsRef.current.getLimitBox)));
        overflowSubscription = createMonitor()
          .pipe(
            switchMap(() => limitBox$),
            map(($tab) => ($tab ? $tab.getBoundingClientRect() : null)),
            withLatestFrom(env$),
            map(([res, env]) => {
              const space = propsRef.current.limitSpace;
              return {
                res,
                env,
                isOverflow: res
                  ? propsRef.current.inverse
                    ? res.top > env.windowHeight - space - stateRef.current.height - computedRef.current.calSpace
                    : res.bottom < space + computedRef.current.calSpace + stateRef.current.height
                  : false,
              };
            })
          )
          .subscribe(function ({ res, isOverflow }) {
            if (stateRef.current.isOverflow !== isOverflow) {
              setOverflow(isOverflow);
              setTopForLimit(
                isOverflow
                  ? propsRef.current.inverse
                    ? refMap.elmRef.current.getBoundingClientRect().top - res.top
                    : res.bottom - refMap.elmRef.current.getBoundingClientRect().bottom
                  : 0
              );
              // vm.$emit('change-overflow', isOverflow); // @todo
            }
          });

        return () => {
          arriveSubscription.unsubscribe();
          overflowSubscription.unsubscribe();
        };
      },
      [
        computedRef,
        depChange$$,
        forceUpdate$$,
        pin$$,
        propsRef,
        refMap.elmRef,
        setArrive,
        setOverflow,
        setTopForLimit,
        stateRef,
      ]
    );
  },
};

// 处理 autoHeight, 部分置顶内容高度根据交互变化
const autoHeightMixins = {
  useData() {
    const height = useState(0);
    return { height };
  },

  useRender(props, { state, stateSetter }, computed, renderState, refMap) {
    const stateRef = useStaticRef(state);
    const setHeight = stateSetter.height;
    const { depChange$$, forceUpdate$$ } = renderState;

    const _setHeight = useCallback(() => {
      let hasChange = true;
      const offsetHeight = refMap.realBodyRef.current.offsetHeight;

      if (stateRef.current.height === offsetHeight) hasChange = false;

      if (hasChange) {
        setHeight(offsetHeight);
      }

      // if (hasChange) {
      //   this.$emit('change-height', this.height); // @todo
      // }
    }, [refMap.realBodyRef, setHeight, stateRef]);

    useEffect(
      function () {
        // 吸顶状态变更，重设高度
        const subscription = of(depChange$$, forceUpdate$$).pipe(mergeAll(), startWith(0)).subscribe(_setHeight);
        const $el = refMap.elmRef.current;

        // 监听元素变化，自动重设高度
        const disconnect = createMutationObserver($el, _setHeight);

        return () => {
          subscription.unsubscribe();
          disconnect();
        };
      },
      [depChange$$, forceUpdate$$, refMap.elmRef, _setHeight]
    );
  },
};

const globalState = { setPinboxKeyMap: null };
const updateGroupOrderMap = {};
const EMPTY_ARRAY = [];

const cbQueue = [];
// 更新某分组的队列排序，并将队列赋值给队列每个实例。
function _updateGroupOrder(groupKey, vm, cb) {
  function fn(inverse) {
    // console.log(`_updateGroupOrder(${JSON.stringify(groupKey)})`);
    const list = [];
    const pinboxKeyMap = {};
    list.splice(0);

    instanceMap.forEach(function (item) {
      const props = item.propsRef.current;

      if (props.groupKey === groupKey && props.inverse === inverse) {
        list.push(item);
      }

      if (props.pinboxKey) {
        if (!pinboxKeyMap[props.pinboxKey]) {
          pinboxKeyMap[props.pinboxKey] = [item];
        } else {
          pinboxKeyMap[props.pinboxKey].push(item);
        }
      }
    });
    globalState.setPinboxKeyMap && globalState.setPinboxKeyMap(pinboxKeyMap);

    list.sort(function (a, b) {
      return inverse
        ? b.propsRef.current.groupOrder - a.propsRef.current.groupOrder ||
            b.stateRef.current.uid - a.stateRef.current.uid
        : a.propsRef.current.groupOrder - b.propsRef.current.groupOrder ||
            a.stateRef.current.uid - b.stateRef.current.uid;
    });

    list.forEach((item) => {
      item.setGroupQueue(list);
    });

    cbQueue.splice(0).forEach((fn) => fn());
  }

  typeof cb === 'function' && cbQueue.push(cb);
  if (vm) {
    // 同分组面前有实例注册过了，不重复注册。
    if (updateGroupOrderMap[groupKey]) return;

    updateGroupOrderMap[groupKey] = fn;
    vm.$nextTick(function () {
      fn(true);
      fn(false);
      delete updateGroupOrderMap[groupKey];
    });
  } else {
    fn(true);
    fn(false);
  }
}

// pin元素分组。
const groupMixins = {
  useData() {
    const groupQueue = useState([]);
    const { pinboxKeyMap } = useContext(PinBoxContext);
    return { groupQueue, pinboxKeyMap: [pinboxKeyMap] };
  },
  computed(props, state, computed, computedDefind) {
    const { absoluteTop, baseTop } = props;
    const { groupQueue, uid, pinboxKeyMap } = state;

    computedDefind.calSpace.registerFn(function (next) {
      return computed.hasAbsoluteTop
        ? absoluteTop
        : Math.max(next() - baseTop + computed.groupQueueBaseTop + computed.groupQueueTop, computed.behindTop);
    });

    return {
      groupQueueTopInfo() {
        let top = 0;
        let _baseTop = 0;

        for (let index = 0; index < groupQueue.length; index++) {
          const element = groupQueue[index];
          const state = element.stateRef.current;
          const computed = element.computedRef.current;

          if (state.uid !== uid) {
            if (!state.isOverflow && computed.isUseable) {
              // 前面的元素处理休眠时，叫醒它。
              if (state.isSleep) {
                element.setSleep(false);
              }
              top += state.height;
              _baseTop = Math.max(baseTop, computed.groupQueueBaseTop);
            }
          } else {
            break;
          }
        }

        return { top, baseTop: _baseTop };
      },
      groupQueueTop() {
        return computed.groupQueueTopInfo.top;
      },
      groupQueueBaseTop() {
        return computed.groupQueueTopInfo.baseTop;
      },
      behindTop() {
        let { behind } = props;

        if (!behind) return 0;
        if (!(behind instanceof Array)) {
          behind = [behind];
        }

        return Math.max(
          ...behind.map(function (key) {
            return Math.max(
              ...(pinboxKeyMap[key] || EMPTY_ARRAY).map(
                (vm) => vm.computedRef.current.calSpace + vm.stateRef.current.height
              )
            );
          })
        );
      },
    };
  },

  // 这里没用Component.mounted.registerFn，因为calSpace依赖于groupQueueTop，所以尽早注册。
  // 否则vm.depChange$$的vm.$nextTick后，groupQueue还不是最新的。
  useRenderBefore(props) {
    const propsRef = useStaticRef(props);
    const updateGroupOrder = useCallback(
      // (cb) => _updateGroupOrder(propsRef.current.groupKey + '', null, /* this, */ cb), // @todo
      (cb) => {
        _updateGroupOrder(propsRef.current.groupKey + '', null, /* this, */ cb);
      },
      [propsRef]
    );
    const { groupKey, groupOrder, inverse } = props;

    useWatch(
      [groupKey, groupOrder, inverse],
      function () {
        updateGroupOrder();
      },
      {
        immediate: true,
      }
    );

    return {
      updateGroupOrder,
    };
  },

  useRender(props, { state }, computed, renderState) {
    const { depChange$$, forceUpdate$$, updateGroupOrder } = renderState;
    const { setPinboxKeyMap } = useContext(PinBoxContext);

    useWatch([computed.calSpace, state.height], function () {
      setPinboxKeyMap((state) => ({ ...state }));
    });

    useEffect(
      function () {
        of(depChange$$, forceUpdate$$)
          .pipe(mergeAll())
          .subscribe(function () {
            updateGroupOrder();
          });

        return updateGroupOrder;
      },
      [depChange$$, forceUpdate$$, updateGroupOrder]
    );
  },
};

const connectMixins = {
  useRenderBefore(props, { state, stateSetter }, computed, renderState) {
    const { isSleep: setSleep, groupQueue: setGroupQueue } = stateSetter;
    const { forceUpdate } = renderState;
    const propsRef = useStaticRef(props);
    const stateRef = useStaticRef(state);
    const computedRef = useStaticRef(computed);
    const vm = useMemo(
      function () {
        return {
          forceUpdate,
          setSleep,
          setGroupQueue,

          propsRef,
          stateRef,
          computedRef,
        };
      },
      [forceUpdate, setSleep, setGroupQueue, propsRef, stateRef, computedRef]
    );

    useEffect(
      function () {
        instanceMap.add(vm);
        return () => instanceMap.delete(vm);
      },
      [vm]
    );
  },
};

export function forceUpdate(force) {
  instanceMap.forEach((vm) => vm.forceUpdate(force));
}

export function PinBoxProvider(props) {
  const [pinboxKeyMap, setPinboxKeyMap] = useState({});
  globalState.setPinboxKeyMap = setPinboxKeyMap;

  return <PinBoxContext.Provider value={{ pinboxKeyMap, setPinboxKeyMap }}>{props.children}</PinBoxContext.Provider>;
}

export default function PinBox(props) {
  const elmRef = useRef();
  const placeholderRef = useRef();
  const realBodyRef = useRef();
  const refMap = useMemo(
    function () {
      return {
        elmRef,
        placeholderRef,
        realBodyRef,
      };
    },
    [elmRef, placeholderRef, realBodyRef]
  );

  const {
    state: { state },
    computed,
  } = useVueCycle(props, [baseMixins, adsorptionTopMixins, autoHeightMixins, groupMixins, connectMixins], refMap);
  const { inverse, fixDocumentFlow, placeholderClass, autoHeight, realBodyClass, realBodyStyle } = props;
  const { isUseable, hasAbsoluteTop, calSpaceValue, topForLimitValue } = computed;
  const { height, isOverflow, isArrive } = state;

  return (
    <div
      ref={elmRef}
      className={classNames({
        'pos-r pin-box pin-box-v2': isUseable,
        'pin-box--inverse': inverse && isUseable,
        'pin-box--not-inverse': !inverse && isUseable,
        'pin-box__auto-top': !hasAbsoluteTop && isUseable,
      })}
    >
      {isUseable && fixDocumentFlow ? (
        <div
          ref={placeholderRef}
          className={classNames('pin-box-placeholder ' + (placeholderClass || ''))}
          style={height && autoHeight ? { height: height + 'px' } : null}
        ></div>
      ) : null}
      <div
        ref={realBodyRef}
        className={classNames(
          isUseable
            ? ['pin-box-elm', realBodyClass || '', isArrive ? fixClassName : '', isOverflow ? 'pin-box-elm--limit' : '']
            : ''
        )}
        style={{
          [inverse ? 'bottom' : 'top']: isArrive ? (isOverflow ? topForLimitValue : calSpaceValue) : '',
          ...realBodyStyle,
        }}
      >
        {props.children}
      </div>
    </div>
  );
}

PinBox.propTypes = {
  /* baseMixins */
  // 渲染标记。组件初始化很多关于dom查询的逻辑，如果此时处于display:none时将很bug。
  // 对外暴露改标记，该值变化时，重启相关dom查询。
  renderSign: PropTypes.any,
  realBodyClass: PropTypes.string,
  placeholderClass: PropTypes.string,
  realBodyStyle: PropTypes.object,
  disabled: PropTypes.bool, // 有时候需要按条件应用pin-box，业务上又不能重新渲染时使用。 (例如在<video />或表单元素上使用，重选渲染导致失焦，播放进度重置)
  useMutation: PropTypes.bool, // 用MutationObserver监听$el来forceUpdate。
  useRemUnit: PropTypes.bool,
  fixDocumentFlow: PropTypes.bool, // 元素pin时，是否创建占位元素以防止文档流塌陷

  /* adsorptionTopMixins */
  inverse: PropTypes.bool, // 交互反转。默认是滚动到元素范围时，元素吸顶。inverse为true时，交互反转，变成还没滚动到元素范围时，元素固底。baseTop和absoluteTop都变成bottom值。
  pin: PropTypes.bool, // 固定pin。
  baseTop: PropTypes.number, // 基础top值。
  absoluteTop: PropTypes.number, // 固定一个top，不需要计算。
  limitSpace: PropTypes.number, // 微调getLimitBox的限制容器作用范围。
  getLimitBox: PropTypes.oneOfType([PropTypes.func, PropTypes.string]), // 用于获取限制容器。pin-box的固定范围将限制在该容器里。
  useOptimize: PropTypes.bool, // 利用IntersectionObserver获取pin-box是否在视口范围内，不在的话不进行更新。
  delayUseOptimize: PropTypes.number, // 延迟使用useOptimize。主要是vue router和浏览器对页面进行恢复位置时，直接给scrollTop赋值，有可能刚好跳过了该区域导致没有触发IntersectionObserver。
  updateOnInit: PropTypes.bool, // 初始化时强制进行一次计算。可能用放到的场景：pin-box初始化时，页面的滚动位置已经是越过它时，由于useOptimize的原因，得等到达视口范围才计算。即使:useOptimize="false"，也需要触发一下滚动才会计算。

  /* autoHeightMixins */
  autoHeight: PropTypes.bool,

  /* groupMixins */
  pinboxKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // 给pinbox标记名字，与behind一起用。
  behind: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]), // 声明自身在哪些pinbox后面与pinboxKey一起用
  groupKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // 同一个分组不会彼此覆盖，不同分组彼此覆盖。
  groupOrder: PropTypes.number, // 同一个分组下，该实例的序号，越大排越后。不传则按排在分组最后再挂载顺序决定，后挂载的排更后面。
};

PinBox.defaultProps = {
  fixDocumentFlow: true,
  useOptimize: true,
  inverse: false,
  pin: false,
  delayUseOptimize: 2000,
  baseTop: 0,
  limitSpace: 0,
  autoHeight: true,
  groupKey: 'global',
  groupOrder: 0,
};
